import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import { ToastsContainer, ToastsStore } from "react-toasts";
import ReactTooltip from "react-tooltip";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";

import msgObj from "../../const.js";
import TimeLoader from "../TimeLoader/TimeLoader.jsx";
import ModalDelete from "../ModalDelete/ModalDelete.jsx";
import ModalAlert from "../ModalAlert/ModalAlert.jsx";
import ModalAddParticipants from "../ModalAddParticipants/ModalAddParticipants.jsx";
import UploadImage from "../UpdateImage/Update.Image.jsx";
import token from "../../token.js";
import versionTooltip from "../../version.js";
import Table from "../Table/Table.jsx";
import Sidebar from "../Sidebar/Sidebar.jsx";

import "./Instrument.css";

export default class Instrument extends Component {
  constructor() {
    super();
    this.state = {
      renderInput: "Crear Instrumento o Pregunta. . .",
      name: "",
      description: "",
      subject: "",
      instructions: "",
      release: "",
      dateInit: "",
      dateEnd: "",
      instrument: "",
      dimension: "",
      subdimension: "",
      orderQuestion: "",
      question: "",
      questions: [],
      scale: "",
      effect: "",
      status: false,
      data: [],
      dataL: [],
      dataQuestions: [],
      instruments: [],
      subdimensions: [],
      respondeTypes: [],
      scales: [],
      dimensions: [],
      sidebarCollapse: true,
      image: "",
      organizations: [],
      _idDelete: "",
      _id: "",
      show: false,
      clear: false,
      loading: false,
      dataQuestionsByInstrument: [],
      showModalAlert: false,
      idInstrument: "",
      organization: "",
      collaborators: [],
      collaboratorsLength: "",
      collaboratorsLengthPrincipal: "",
      collaboratorsLengthAdmin: "",
      date: "",
      repeatEmailsColl: [],
      noRepeatColl: [],
      idAdd: null,
      showModalAddParticipants: false,
      emails: [],
      repeatEmails: [],
      listParticipants: [],
      listEmails: [],
      listEmailsRepeat: [],
      isLoadingAdd: false,
      repeatEmailsForValidation: [],
      repeatDNIForValidation: [],
    };
  }

  componentDidMount() {
    $("#menu-toggle").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });
    this.getInstruments();
    this.getDimensions();
    this.getSubdimensions();
    this.getScales();
    this.getQuestions();
    this.getLogo();
    this.getCollaborators();
  }

  refreshTokens = (id) => {
    this.setState({ loading: true });
    axios
      .post(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/enableTokens`,
        { password: id },
        {
          headers: { "x-auth-token": token },
        }
      )
      .then((res) => {
        ToastsStore.success(res.data.msg);
        this.setState({ loading: false });
      })
      .catch((err) => {
        ToastsStore.success(err.response.data.msg);
        this.setState({ loading: false });
      });
  };

  getCollaborators = () => {
    const collaborators = Object.assign([], this.state.collaborators);
    var countSendPrincipal = 0;
    var countSendAdmin = 0;
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/collaborators`,
        {
          headers: { "x-auth-token": token },
        }
      )
      .then((res) => {
        this.setState({
          collaborators: res.data.collaborators,
        });
        this.state.collaborators.map((collaborator) => {
          if (collaborator.sendMail) {
            if (collaborator.sendPrincipal) {
              countSendPrincipal++;
            } else {
              countSendAdmin++;
            }
            //collaborators.push(collaborator);
          }
        });
        this.setState({
          collaboratorsLengthPrincipal: countSendPrincipal,
          collaboratorsLengthAdmin: countSendAdmin,
          collaboratorsLength: countSendPrincipal + countSendAdmin,
          date: this.state.collaborators[0].updated,
        });
      })
      .catch((err) => {
        if (err.response) {
          ToastsStore.error(err.response.data.msg);
        }
      });
  };

  collapseSidebar = () => {
    this.setState({
      sidebarCollapse: !this.state.sidebarCollapse,
    });
  };

  getLogo = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/organizations`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        this.setState({
          organizations: res.data.organizations,
        });
        this.state.organizations.map((organization) => {
          if (organization._id == user.company.organization._id) {
            this.setState({
              image: organization.logo.imagecode,
            });
          }
        });
      });
  };

  isChecked = () => {
    this.setState({
      status: !this.state.status,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    console.log("handleChange: ", name, value);

    this.setState({
      [name]: value,
      clear: true,
    });
  };

  handleChangeDateEnd = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      clear: true,
    });

    const date = new Date(this.state.dateEnd);
  };

  onQuestion = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.getQuestionsByInstrument();
      }
    );
  };

  clearForm = () => {
    this.setState({
      name: "",
      description: "",
      subject: "",
      instructions: "",
      release: "",
      dateInit: "",
      dateEnd: "",
      instrument: "",
      dimension: "",
      subdimension: "",
      orderQuestion: "",
      question: "",
      scale: "",
      effect: "",
      _id: "",
    });
  };

  showModal = (id) => {
    this.setState({
      _idDelete: id,
      show: !this.state.show,
    });
  };

  showModalAlert = (id) => {
    this.setState({
      idInstrument: id,
      showModalAlert: !this.state.showModalAlert,
    });
  };

  showModalAddParticipants = (id) => {
    this.setState({ isLoadingAdd: true });
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/instruments/${id}`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        this.setState({
          idAdd: id,
          nameInstrument: res.data.instrument.name,
          listParticipants: res.data.instrument.listParticipantsDNI,
          listEmails: res.data.instrument.listParticipants,
        });
        let users = [];
        let repeatUsers = [];
        let repeatEmails = [];
        // let filterParticipants = this.state.collaborators.filter(
        //   (collaborator) =>
        //     collaborator.company.organization.admingroup.admingroup_id ===
        //     res.data.instrument.organization.admingroup.admingroup_id
        // );
        this.state.listParticipants.map((participant) => {
          this.state.collaborators
            .filter(
              (coll) =>
                coll.company.organization.admingroup.admingroup_id ===
                res.data.instrument.organization.admingroup.admingroup_id
            )
            .find((newP) => {
              if (newP.dni === participant) {
                // console.log("AQUI ENTRE PARA DUPLICAR");
                repeatUsers.push(participant);
              } else {
                // console.log("AQUI ENTRE PARA NUEVO");
                users.push(newP.dni);
              }
            });
        });
        this.state.listEmails.map((email) => {
          this.state.collaborators
            .filter(
              (coll) =>
                coll.company.organization.admingroup.admingroup_id ===
                res.data.instrument.organization.admingroup.admingroup_id
            )
            .find((newP) => {
              if (newP.email !== "") {
                if (newP.email === email) {
                  repeatEmails.push(email);
                }
              }
            });
        });
        let usersUnique = [...new Set(users)];
        let repeatUsersUnique = [...new Set(repeatUsers)];
        let repeatEmailsUnique = [...new Set(repeatEmails)];
        this.setState({ listEmailsRepeat: repeatEmailsUnique });
        repeatUsersUnique.map((user) => {
          if (usersUnique.indexOf(user) !== -1) {
            usersUnique.splice(usersUnique.indexOf(user), 1);
          }
        });

        axios
          .post(
            `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/collaborators/find/dni`,
            { repeat: repeatUsersUnique, noRepeat: usersUnique },
            {
              headers: { "x-auth-token": token },
            }
          )
          .then((res) => {
            const repeatEmail = [];
            const repeatDNI = [];
            const colMaps = {};
            const colMaps2 = {};
            res.data.collaboratorsRepeatNo.filter((coll, _i) => {
              let alredyExist = colMaps.hasOwnProperty(coll.dni);

              if (alredyExist) {
                repeatDNI.push(coll.dni);
              }
              return alredyExist ? false : (colMaps[coll.dni] = 1);
            });

            res.data.collaboratorsRepeatNo.filter((coll, _i) => {
              if (coll.email) {
                let alredyExist = colMaps2.hasOwnProperty(coll.email);
                if (alredyExist) repeatEmail.push(coll.email);
                return alredyExist ? false : (colMaps2[coll.email] = 1);
              } else {
                return coll;
              }
            });

            this.setState({
              repeatEmailsColl: res.data.collaboratorsRepeat,
              noRepeatColl: res.data.collaboratorsRepeatNo,
              isLoadingAdd: false,
              repeatEmailsForValidation: repeatEmail,
              repeatDNIForValidation: repeatDNI,
            });
          })
          .catch((err) => {});

        this.setState({
          emails: usersUnique,
          repeatEmails: repeatUsersUnique,
        });
      })
      .catch((err) => {});
    this.setState({
      showModalAddParticipants: !this.state.showModalAddParticipants,
    });
  };

  logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("image");
  };

  getInstruments = () => {
    this.setState(
      {
        data: [],
        dataL: [],
      },
      () => {
        const data = Object.assign([], this.state.data);
        axios
          .get(
            `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/instruments`,
            { headers: { "x-auth-token": token } }
          )
          .then((res) => {
            this.setState({
              instruments: res.data.instruments,
            });
            this.state.instruments.map((instrument) => {

              const date = new Date();
              const dateInit = instrument.dateInfo.startDate;
              const dateEnd = instrument.dateInfo.expiresDate;
              const dateEndAux = new Date(instrument.dateInfo.expiresDate);

              const dateInitDay = dateInit.slice(8, 10);
              const dateEndDay = dateEnd.slice(8, 10);
              const dateInitMonth = dateInit.slice(5, 7);
              const dateEndMonth = dateEnd.slice(5, 7);

              let status = instrument.status;
              var orgName = "";

              if (dateEndAux < date) {
                status = false;
              }

              if (instrument.organization) {
                orgName = instrument.organization.name;
              }
              
              data.push({
                _id: instrument._id,
                name: instrument.name,
                numberQuestions: instrument.nroquestions,
                orgInstrument: orgName,
                summary: instrument.summary,
                date:
                  dateInitDay +
                  "/" +
                  dateInitMonth +
                  "/" +
                  dateInit.slice(0, 4) +
                  " - " +
                  dateEndDay +
                  "/" +
                  dateEndMonth +
                  "/" +
                  dateEnd.slice(0, 4),
                status: status,
                organization: instrument.organization,
                surveyid: instrument.surveyid,
                status: status,
              });
            });
            this.setState({
              data,
            });

          });
        const dataL = Object.assign([], this.state.dataL);
        axios
          .get(
            `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/instruments`,
            { headers: { "x-auth-token": token } }
          )
          .then((res) => {
            this.setState({
              instruments: res.data.instruments,
            });
            this.state.instruments.map((instrument) => {
              const dateInit = new Date(instrument.dateInfo.startDate);
              const dateEnd = new Date(instrument.dateInfo.expiresDate);
              const dateInitMonth = dateInit.getMonth() + 1;
              const dateEndMonth = dateEnd.getMonth() + 1;
              dataL.push({
                _id: instrument._id,
                name: instrument.name,
                date:
                  dateInit.getDate() +
                  "/" +
                  dateInitMonth +
                  "/" +
                  dateInit.getFullYear() +
                  " - " +
                  dateEnd.getDate() +
                  "/" +
                  dateEndMonth +
                  "/" +
                  dateEnd.getFullYear(),
                status: instrument.status,
              });
            });

            this.setState({
              dataL,
            });
          });
      }
    );
  };

  getSubdimensions = () => {
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/subdimensions/active`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        this.setState({
          subdimensions: res.data.subdimensions,
        });
      });
  };

  getDimensions = () => {
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/dimensions/active`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        this.setState({
          dimensions: res.data.dimensions,
        });
      });
  };

  getScales = () => {
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/scales/active`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        this.setState({
          scales: res.data.scales,
        });
      });
  };

  addInstrument = (event) => {
    event.preventDefault();
    if (this.state._id) {
      this.setState({
        loading: true,
      });
      const objRequest = {
        name: this.state.name,
        description: this.state.description,
        instructions: this.state.instructions,
        emailSubject: this.state.subject,
        communique: this.state.release,
        dateInfo: {
          startDate: this.state.dateInit.substring(0, 10),
          expiresDate: this.state.dateEnd.substring(0, 10),
        },
      };
      axios
        .put(
          `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/instruments/${this.state._id}`,
          objRequest,
          { headers: { "x-auth-token": token } }
        )
        .then((res) => {
          this.setState({
            name: "",
            description: "",
            subject: "",
            instructions: "",
            release: "",
            dateInit: "",
            dateEnd: "",
            status: false,
          });
          this.setState({
            loading: false,
          });
          this.getInstruments();
          ToastsStore.success(res.data.msg);
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          ToastsStore.error(err.response.data.msg);
        });
    } else {
      this.setState({
        loading: true,
      });
      const objRequest = {
        name: this.state.name,
        description: this.state.description,
        instructions: this.state.instructions,
        emailSubject: this.state.subject,
        communique: this.state.release,
        evaluationPeriod: this.state.periods,
        status: false,
        dateInfo: {
          startDate: this.state.dateInit,
          expiresDate: this.state.dateEnd,
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/instruments`,
          objRequest,
          { headers: { "x-auth-token": token } }
        )
        .then((res) => {
          this.setState({
            name: "",
            description: "",
            subject: "",
            instructions: "",
            release: "",
            dateInit: "",
            dateEnd: "",
            status: false,
          });
          this.getInstruments();
          ToastsStore.success(res.data.msg);
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          ToastsStore.error(err.response.data.msg);
        });
    }
  };

  editInstrument = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/instruments/${id}`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        this.setState({
          _id: res.data.instrument._id,
          name: res.data.instrument.name,
          description: res.data.instrument.description,
          instructions: res.data.instrument.instructions,
          subject: res.data.instrument.emailSubject,
          release: res.data.instrument.communique,
          dateInit: res.data.instrument.dateInfo.startDate.substring(0, 10),
          dateEnd: res.data.instrument.dateInfo.expiresDate.substring(0, 10),
          organization: res.data.instrument.organization,
        })
      });
  };

  deleteInstrument = () => {
    axios
      .delete(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/instruments/${this.state._idDelete}`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        this.getInstruments();
        this.getQuestions();
        ToastsStore.success(res.data.msg);
        this.setState({
          _idDelete: "",
          show: !this.state.show,
        });
      })
      .catch((err) => {
        ToastsStore.error(err.response.data.msg);
      });
  };

  getQuestionsInstrument = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/questions/instruments/${id}`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        this.setState(
          {
            activate: res.data.exist,
          },
          () => {
            if (!this.state.activate) {
              ToastsStore.error("Instrumento no puede ser activado");
            } else {
              ToastsStore.success("Instrumento activado");
            }
          }
        );
      });
  };

  addQuestion = (event) => {
    event.preventDefault();
    if (this.state._idQuestion) {
      axios
        .put(
          `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/questions/${this.state._idQuestion}`,
          {
            instrument: {
              _id: this.state.instrument,
            },
            dimension: {
              _id: this.state.dimension,
            },
            subdimension: {
              _id: this.state.subdimension,
            },
            order: this.state.orderQuestion,
            question: this.state.question,
            scale: {
              _id: this.state.scale,
            },
            effect: this.state.effect,
          },
          { headers: { "x-auth-token": token } }
        )
        .then((res) => {
          this.setState({
            _idQuestion: "",
            dimension: "",
            subdimension: "",
            order: "",
            orderQuestion: "",
            question: "",
            scale: "",
            effect: "",
          });
          this.getQuestions();
          ToastsStore.success(res.data.msg);
        })
        .catch((err) => ToastsStore.error(err.response.data.msg));
    } else {
      axios
        .post(
          `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/questions`,
          {
            instrument: {
              _id: this.state.instrument,
            },
            dimension: {
              _id: this.state.dimension,
            },
            subdimension: {
              _id: this.state.subdimension,
            },
            order: this.state.orderQuestion,
            question: this.state.question,
            scale: {
              _id: this.state.scale,
            },
            effect: this.state.effect,
          },
          { headers: { "x-auth-token": token } }
        )
        .then((res) => {
          this.setState({
            dimension: "",
            subdimension: "",
            order: "",
            orderQuestion: "",
            question: "",
            scale: "",
            effect: "",
          });
          this.getInstruments();
          this.getQuestions();
          this.getQuestionsByInstrument();
          ToastsStore.success(res.data.msg);
        })
        .catch((err) => ToastsStore.error(err.response.data.msg));
    }
  };

  getQuestions = () => {
    this.setState({
      dataQuestions: [],
    });
    const dataQuestions = Object.assign([], this.state.dataQuestions);
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/questions`,
        {
          headers: { "x-auth-token": token },
        }
      )
      .then((res) => {
        this.setState({
          questions: res.data.questions,
        });
        this.state.questions.map((question) => {
          dataQuestions.push({
            _id: question._id,
            order: question.order,
            question: question.question,
            instrument: question.instrument.name,
            dimension: question.dimension.name,
          });
        });
        this.setState({
          dataQuestions,
        });
      });
  };

  getQuestionsByInstrument = () => {
    this.setState(
      {
        dataQuestionsByInstrument: [],
      },
      () => {
        const dataQuestionsByInstrument = Object.assign(
          [],
          this.state.dataQuestionsByInstrument
        );
        axios
          .get(
            `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/questions`,
            { headers: { "x-auth-token": token } }
          )
          .then((res) => {
            this.setState({
              questions: res.data.questions,
            });
            this.state.questions.map((question) => {
              if (question.instrument._id == this.state.instrument)
                dataQuestionsByInstrument.push({
                  _id: question._id,
                  order: question.order,
                  question: question.question,
                  instrument: question.instrument.name,
                  dimension: question.dimension.name,
                });
            });
            this.setState({
              dataQuestionsByInstrument,
            });
          });
      }
    );
  };

  editQuestion = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/questions/${id}`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        var subdimension = "";
        if (res.data.question.subdimension) {
          subdimension = res.data.question.subdimension._id;
        }
        this.setState({
          _idQuestion: res.data.question._id,
          instrument: res.data.question.instrument._id,
          dimension: res.data.question.dimension._id,
          subdimension: subdimension,
          orderQuestion: res.data.question.order,
          question: res.data.question.question,
          scale: res.data.question.scale._id,
          effect: res.data.question.effect,
        });
      });
  };

  deleteQuestion = () => {
    axios
      .delete(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/questions/${this.state._idDelete}`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        this.getQuestions();
        ToastsStore.success(res.data.msg);
        this.setState({
          _idDelete: "",
          show: !this.state.show,
        });
      })
      .catch((err) => {
        ToastsStore.error(err.response.data.msg);
      });
  };

  addParticipants = () => {
    this.setState({ loading: true });
    axios
      .post(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/instruments/${this.state.idAdd}/addParticipants`,
        { collaborators: this.state.noRepeatColl },
        {
          headers: { "x-auth-token": token },
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        ToastsStore.success(res.data.msg);
        console.log(res);
        this.getInstruments();
      })
      .catch((err) => {
        this.setState({ loading: false });
        ToastsStore.error(err.response.data.msg);
        console.log(err);
      });
    this.setState({
      showModalAddParticipants: false,
    });
  };

  activateInstrument = (id) => {
    this.setState({
      loading: true,
      showModalAlert: false,
    });
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${
          process.env.REACT_APP_BASEPATH
        }/api/v1/instruments/${
          this.state.idInstrument == "" ? id : this.state.idInstrument
        }/active`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        this.setState({
          loading: false,
        });
        ToastsStore.success(res.data.msg);
        this.getInstruments();
      })
      .catch((err) => {
        this.setState({
          loading: false,
          idInstrument: "",
        });
        ToastsStore.error(err.response.data.msg);
      });
  };

  desactivateInstrument = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/instruments/${id}/inactive`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        ToastsStore.success(res.data.msg);
        this.getInstruments();
      })
      .catch((err) => {
        ToastsStore.error(err.response.data.msg);
      });
  };

  duplicateInstrument = (id) => {
    this.setState({
      loading: true,
    });
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/instruments/${id}/duplicate`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        this.setState({
          loading: false,
        });
        ToastsStore.success(res.data.msg);
        this.setState({
          _id: res.data.instrument._id,
          name: res.data.instrument.name,
          description: res.data.instrument.description,
          instructions: res.data.instrument.instructions,
          subject: res.data.instrument.emailSubject,
          release: res.data.instrument.communique,
          dateInit: res.data.instrument.dateInfo.startDate,
          dateEnd: res.data.instrument.dateInfo.expiresDate,
        });
        this.getInstruments();
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        ToastsStore.error(err.response.data.msg);
      });
  };

  preview = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/instruments/${id}/preview`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        ToastsStore.success(res.data.msg);
      })
      .catch((err) => {
        ToastsStore.error(err.response.data.msg);
      });
  };

  render() {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    const formatWithIconInstrument = (cell, row) => {
      return (
        <div>
          {user.role.name == "Administrador" ? (
            !row.status || row.summary === undefined ? null : (
              <i
                data-tip={"Anexar participantes a instrumento"}
                data-for="addParticipants"
                className="fa fa-address-book"
                aria-hidden="true"
                onClick={() => this.showModalAddParticipants(row._id)}
              ></i>
            )
          ) : null}
          <ReactTooltip place="top" id="addParticipants" />
          &nbsp;
          {user.role.name == "Administrador" ? (
            !row.status || row.summary === undefined ? null : (
              <i
                data-tip={"Refrescar tokens de encuestas no completadas"}
                data-for="refreshTokens"
                className="fa fa-retweet"
                aria-hidden="true"
                onClick={() => this.refreshTokens(row.surveyid)}
              ></i>
            )
          ) : null}
          <ReactTooltip place="top" id="refreshTokens" />
          &nbsp;
          {user.role.name == "Administrador Empresas" ? (
            row.organization == undefined ? null : (
              <i
                data-tip={row.status ? msgObj.inactive : msgObj.active}
                data-for={row.status ? "inactive" : "active"}
                className={row.status ? "fa fa-window-close" : "fa fa-check"}
                aria-hidden="true"
                onClick={
                  row.status
                    ? () => this.desactivateInstrument(row._id)
                    : () => this.showModalAlert(row._id)
                }
              ></i>
            )
          ) : row.organization != undefined ? null : (
            <i
              data-tip={row.status ? msgObj.inactive : msgObj.active}
              data-for={row.status ? "inactive" : "active"}
              className={row.status ? "fa fa-window-close" : "fa fa-check"}
              aria-hidden="true"
              onClick={
                row.status
                  ? () => this.desactivateInstrument(row._id)
                  : () => this.activateInstrument(row._id)
              }
            ></i>
          )}
          <ReactTooltip place="top" id={row.status ? "inactive" : "active"} />
          &nbsp;
          {user.role.name == "Administrador Empresas" ? (
            row.organization == undefined ? null : (
              <i
                data-tip={msgObj.update}
                data-for="update"
                className="fas fa-edit"
                aria-hidden="true"
                onClick={() => this.editInstrument(row._id)}
              ></i>
            )
          ) : (
            <i
              data-tip={msgObj.update}
              data-for="update"
              className="fas fa-edit"
              aria-hidden="true"
              onClick={() => this.editInstrument(row._id)}
            ></i>
          )}
          <ReactTooltip place="top" id="update" />
        </div>
      );
    };

    const formatWithIconInstruments = (cell, row) => {
      return (
        <div>
          {row.organization == undefined ? (
            <i
              data-tip={msgObj.duplicate}
              data-for="duplicate"
              className="fas fa-copy"
              aria-hidden="true"
              onClick={() => this.duplicateInstrument(row._id)}
            ></i>
          ) : null}
          <ReactTooltip place="top" id="duplicate" />
          &nbsp;
          {row.organization == undefined ? (
            <a
              target="_blank"
              href={`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH_LIME}/SMART/index.php/${row.surveyid}?newtest=Y&lang=es`}
              data-tip={msgObj.visualize}
              data-for="visualize"
            >
              <i
                className="fa fa-eye"
                data-tip={msgObj.visualize}
                data-for="visualize"
                aria-hidden="true"
              ></i>
            </a>
          ) : null}
          <ReactTooltip place="top" id="visualize" />
          &nbsp;
          {user.role.name == "Administrador Empresas" &&
          row.organization == undefined ? null : (
            <i
              className="fa fa-trash"
              data-tip={msgObj.delete}
              data-for="delete"
              aria-hidden="true"
              onClick={() => this.showModal(row._id)}
            ></i>
          )}
          <ReactTooltip place="top" id="delete" />
        </div>
      );
    };

    const formatWithIconInstrumentAdmin = (cell, row) => {
      return (
        <div>
          {user.role.name == "Administrador Empresas" ? (
            row.organization == undefined ? null : (
              <i
                data-tip={row.status ? msgObj.inactive : msgObj.active}
                data-for={row.status ? "inactive" : "active"}
                className={row.status ? "fa fa-window-close" : "fa fa-check"}
                aria-hidden="true"
                onClick={
                  row.status
                    ? () => this.desactivateInstrument(row._id)
                    : () => this.showModalAlert(row._id)
                }
              ></i>
            )
          ) : row.organization != undefined ? null : (
            <i
              data-tip={row.status ? msgObj.inactive : msgObj.active}
              data-for={row.status ? "inactive" : "active"}
              className={row.status ? "fa fa-window-close" : "fa fa-check"}
              aria-hidden="true"
              onClick={
                row.status
                  ? () => this.desactivateInstrument(row._id)
                  : () => this.activateInstrument(row._id)
              }
            ></i>
          )}
          <ReactTooltip place="top" id={row.status ? "inactive" : "active"} />
          &nbsp;
          {user.role.name == "Administrador Empresas" ? (
            row.organization == undefined ? null : (
              <i
                data-tip={msgObj.update}
                data-for="update"
                className="fas fa-edit"
                aria-hidden="true"
                onClick={() => this.editInstrument(row._id)}
              ></i>
            )
          ) : (
            <i
              data-tip={msgObj.update}
              data-for="update"
              className="fas fa-edit"
              aria-hidden="true"
              onClick={() => this.editInstrument(row._id)}
            ></i>
          )}
          <ReactTooltip place="top" id={row.status ? "inactive" : "active"} />
          &nbsp;
          <ReactTooltip place="top" id="update" />
          &nbsp;
          {row.organization == undefined ? (
            <i
              data-tip={msgObj.duplicate}
              data-for="duplicate"
              className="fas fa-copy"
              aria-hidden="true"
              onClick={() => this.duplicateInstrument(row._id)}
            ></i>
          ) : null}
          <ReactTooltip place="top" id="duplicate" />
          &nbsp;
          {!row.status || row.summary === undefined ? null : (
            <i
              data-tip={"Refrescar tokens de encuestas no completadas"}
              data-for="refreshTokens"
              className="fa fa-retweet"
              aria-hidden="true"
              onClick={() => this.refreshTokens(row.surveyid)}
            ></i>
          )}
          <ReactTooltip place="top" id="refreshTokens" />
          &nbsp;
          {row.organization == undefined ? (
            <a
              target="_blank"
              href={`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH_LIME}/SMART/index.php/${row.surveyid}?newtest=Y&lang=es`}
              data-tip={msgObj.visualize}
              data-for="visualize"
            >
              <i
                className="fa fa-eye"
                data-tip={msgObj.visualize}
                data-for="visualize"
                aria-hidden="true"
              ></i>
            </a>
          ) : null}
          <ReactTooltip place="top" id="visualize" />
          &nbsp;
          {user.role.name == "Administrador Empresas" &&
          row.organization == undefined ? null : (
            <i
              className="fa fa-trash"
              data-tip={msgObj.delete}
              data-for="delete"
              aria-hidden="true"
              onClick={() => this.showModal(row._id)}
            ></i>
          )}
          <ReactTooltip place="top" id="delete" />
        </div>
      );
    };

    const formatWithIconQuestions = (cell, row) => {
      return (
        <div>
          <i
            data-tip={msgObj.update}
            data-for="update"
            className="fas fa-edit"
            aria-hidden="true"
            onClick={() => this.editQuestion(row._id)}
          ></i>
          <ReactTooltip place="top" id="update" />
          &nbsp;
          {user.role.name == "Administrador Empresas" ? null : (
            <i
              className="fa fa-trash"
              data-tip={msgObj.delete}
              data-for="delete"
              aria-hidden="true"
              onClick={() => this.showModal(row._id)}
            ></i>
          )}
          <ReactTooltip place="top" id="delete" />
        </div>
      );
    };

    const formatWithStatus = (cell, row) => {
      return (
        <div>
          {row.status ? (
            <i
              className="fas fa-circle status-active"
              aria-hidden="true"
              data-tip={msgObj.enable}
              data-for="enable"
            ></i>
          ) : (
            <i
              className="fas fa-circle status-inactive"
              aria-hidden="true"
              data-tip={msgObj.disable}
              data-for="disable"
            ></i>
          )}
          <ReactTooltip place="top" id={row.status ? "enable" : "disable"} />
        </div>
      );
    };

    const columns1 = [
      {
        dataField: "status",
        text: "",
        formatter: formatWithStatus,
      },
      {
        dataField: "surveyid",
        text: "ID",
      },
      {
        dataField: "name",
        text: "Nombre",
        sort: true,
      },
      {
        dataField: "numberQuestions",
        text: "#Preg",
      },
      {
        dataField: "orgInstrument",
        text: "Organización",
      },
      {
        dataField: "summary",
        text: "Completadas",
      },
      {
        dataField: "date",
        text: "Vigencia",
      },
      {
        dataField: "deleteAction",
        text: "",
        formatter: formatWithIconInstrument,
      },
      {
        dataField: "icons",
        text: "",
        formatter: formatWithIconInstruments,
      },
    ];

    const columns3 = [
      {
        dataField: "status",
        text: "",
        formatter: formatWithStatus,
      },
      {
        dataField: "surveyid",
        text: "ID",
      },
      {
        dataField: "name",
        text: "Nombre",
        sort: true,
      },
      {
        dataField: "numberQuestions",
        text: "#Preg",
      },
      {
        dataField: "summary",
        text: "Completadas",
      },
      {
        dataField: "date",
        text: "Vigencia",
      },
      {
        dataField: "deleteAction",
        text: "",
        formatter: formatWithIconInstrumentAdmin,
      },
    ];

    const columns2 = [
      {
        dataField: "instrument",
        text: "Instrumento",
        sort: true,
      },
      {
        dataField: "dimension",
        text: "Dimensión",
        sort: true,
      },
      {
        dataField: "order",
        text: "Orden",
      },
      {
        dataField: "question",
        text: "Pregunta",
      },
      {
        dataField: "deleteAction",
        text: "",
        formatter: formatWithIconQuestions,
      },
    ];

    if (!token) {
      return <Redirect to="/"></Redirect>;
    } else {
      return (
        <BlockUi tag="div" blocking={this.state.loading}>
          <div className="Instrumento">
            <TimeLoader redirect={this.props.history} />
            <div className="wrapper" id="wrapper">
              <Sidebar page="instrument" role={user.role} />
              <div id="page-content-wrapper">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <button
                          className="navbar-toggler"
                          type="button"
                          data-toggle="collapse"
                          data-target="#navbarNav"
                          aria-controls="navbarNav"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                        >
                          <span className="navbar-toggler-icon"></span>
                        </button>
                        <div
                          id="navbarNav"
                          className="collapse navbar-collapse"
                        >
                          <ul className="nav navbar-nav mr-auto">
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                data-tip={versionTooltip}
                                data-for="version"
                                href="#menu-toggle"
                                id="menu-toggle"
                                onClick={this.collapseSidebar}
                              >
                                <i className="fa fa-bars"></i>
                              </a>
                              <ReactTooltip place="top" id="version" />
                            </li>
                            <li
                              className="nav-item"
                              data-tip="Inicio"
                              data-for="home"
                            >
                              {!this.state.sidebarCollapse ? (
                                <Link to="Home">
                                  <img
                                    className="logoNavbar"
                                    src={this.state.image}
                                  ></img>
                                </Link>
                              ) : (
                                <Link to="/Home">
                                  <i className="fas fa-home"></i>
                                </Link>
                              )}
                            </li>
                            <ReactTooltip place="top" id="home" />
                          </ul>
                          <ul className="nav navbar-nav mx-auto"></ul>
                          <ul className="nav navbar-nav ml-auto">
                            <li className="nav-item">
                              <span>
                                {user.firstName + " " + user.lastName}
                              </span>
                              &nbsp;
                            </li>
                            {user.role.name == "Administrador Empresas" ? (
                              <li className="nav-item">
                                <UploadImage />
                              </li>
                            ) : null}
                            &nbsp;&nbsp;
                            <li className="nav-item">
                              <Link data-tip="Cerrar sesión" to="/">
                                <i
                                  className="fas fa-sign-out-alt"
                                  onClick={this.logOut}
                                ></i>
                              </Link>
                              <ReactTooltip />
                            </li>
                          </ul>
                        </div>
                      </nav>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <br />
                      <h1>Instrumento</h1>
                      <hr></hr>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <form
                        onSubmit={
                          this.state.renderInput == 1
                            ? this.addInstrument
                            : this.addQuestion
                        }
                        autocomplete="off"
                      >
                        <div className="row form-group">
                          <div className="col-md-12">
                            <select
                              name="renderInput"
                              ref="renderInput"
                              className="form-control"
                              onChange={this.handleChange}
                              value={this.state.renderInput}
                              required
                            >
                              <option
                                value="Crear Instrumento o Pregunta. . ."
                                disabled
                              >
                                Crear Instrumento o Pregunta. . .
                              </option>
                              <option value="1">Instrumento</option>
                              <option value="2">Pregunta</option>
                            </select>
                          </div>
                        </div>
                        {this.state.renderInput == 1 ? (
                          <div className="row form-group">
                            <div className="col-md-12">
                              <input
                                type="text"
                                name="name"
                                placeholder="Colocar nombre del intrumento"
                                ref="name"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.name}
                                required
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.renderInput == 1 ? (
                          <div className="row form-group">
                            <div className="col-md-12">
                              <textarea
                                name="description"
                                placeholder="Descripcion"
                                ref="description"
                                className="form-control"
                                onChange={
                                  user.role.name == "Administrador Empresas"
                                    ? null
                                    : this.handleChange
                                }
                                value={this.state.description}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.renderInput == 1 ? (
                          <div className="row form-group">
                            <div className="col-md-12">
                              <input
                                type="text"
                                name="subject"
                                placeholder="Asunto de Correo"
                                ref="subject"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.subject}
                                required
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.renderInput == 1 ? (
                          <div className="row form-group">
                            <div className="col-md-12">
                              <textarea
                                name="release"
                                placeholder="Comunicado"
                                ref="release"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.release}
                                required
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.renderInput == 1 ? (
                          <div className="row form-group">
                            <div className="col-md-12">
                              <textarea
                                name="instructions"
                                placeholder="Instrucciones"
                                ref="instructions"
                                className="form-control"
                                onChange={
                                  user.role.name == "Administrador Empresas"
                                    ? null
                                    : this.handleChange
                                }
                                value={this.state.instructions}
                                required
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.renderInput == 1 ? (
                          <div
                            className="row form-group"
                            data-tip="Día de inicio de la encuesta"
                            data-for="date"
                          >
                            <div className="col-md-12">
                              <input
                                type="date"
                                name="dateInit"
                                placeholder="Periodo de Apertura: "
                                ref="dateInit"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.dateInit}
                                required
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.renderInput == 1 ? (
                          <div className="row form-group">
                            <div className="col-md-12">
                              <input
                                type="date"
                                name="dateEnd"
                                placeholder="Periodo de Finalización: "
                                ref="dateEnd"
                                className="form-control"
                                onChange={this.handleChangeDateEnd}
                                value={this.state.dateEnd}
                                required
                                data-tip="Último día en que la encuesta estará disponible"
                                data-for="date"
                              />
                              <ReactTooltip place="top" id="date" />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.renderInput == 2 ? (
                          <div className="row form-group">
                            <div className="col-md-12">
                              <select
                                name="instrument"
                                ref="instrument"
                                className="form-control"
                                onChange={this.onQuestion}
                                value={this.state.instrument}
                                required
                                selected
                              >
                                <option value="" disabled>
                                  Instrumento
                                </option>
                                {this.state.dataL.map((instrument) => {
                                  return (
                                    <option
                                      value={instrument._id}
                                      key={instrument._id}
                                    >
                                      {instrument.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.renderInput == 2 ? (
                          <div className="row form-group">
                            <div className="col-md-12">
                              <select
                                name="dimension"
                                ref="dimension"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.dimension}
                                required
                                selected
                              >
                                <option value="" disabled>
                                  Dimensión
                                </option>
                                {this.state.dimensions.map((dimension) => {
                                  return (
                                    <option
                                      value={dimension._id}
                                      key={dimension._id}
                                    >
                                      {dimension.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.renderInput == 2 ? (
                          <div className="row form-group">
                            <div className="col-md-12">
                              <select
                                name="subdimension"
                                ref="subdimension"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.subdimension}
                                selected
                                required
                              >
                                <option value="" disabled>
                                  Subdimensión
                                </option>
                                {this.state.subdimensions.map(
                                  (subdimension) => {
                                    if (
                                      subdimension.dimension._id ==
                                      this.state.dimension
                                    ) {
                                      return (
                                        <option
                                          value={subdimension._id}
                                          key={subdimension._id}
                                        >
                                          {subdimension.name}
                                        </option>
                                      );
                                    }
                                  }
                                )}
                              </select>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.renderInput == 2 ? (
                          <div className="row form-group">
                            <div className="col-md-12">
                              <input
                                type="number"
                                name="orderQuestion"
                                placeholder="Orden de la pregunta"
                                ref="orderQuestion"
                                className="form-control"
                                value={this.state.orderQuestion}
                                onChange={this.handleChange}
                                required
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.renderInput == 2 ? (
                          <div className="row form-group">
                            <div className="col-md-12">
                              <input
                                type="text"
                                name="question"
                                placeholder="Pregunta"
                                ref="question"
                                className="form-control"
                                value={this.state.question}
                                onChange={this.handleChange}
                                required
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.renderInput == 2 ? (
                          <div className="row form-group">
                            <div className="col-md-12">
                              <select
                                name="scale"
                                ref="scale"
                                className="form-control"
                                value={this.state.scale}
                                onChange={this.handleChange}
                                required
                                selected
                              >
                                <option value="" disabled>
                                  Escala
                                </option>
                                {this.state.scales.map((scale) => {
                                  return (
                                    <option value={scale._id} key={scale._id}>
                                      {scale.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.renderInput == 2 ? (
                          <div className="row form-group">
                            <div className="col-md-12">
                              <select
                                name="effect"
                                ref="effect"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.effect}
                                selected
                                required
                              >
                                <option value="" disabled>
                                  Efecto
                                </option>
                                <option value="+">+</option>
                                <option value="-">-</option>
                              </select>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <br />
                        {this.state.renderInput !=
                        "Selecionar Instrumento o Pregunta. . ." ? (
                          <div className="col-md-12">
                            <div className="text-center">
                              {this.state._id != "" || this.state.clear ? (
                                <button
                                  type="button"
                                  className="btn btn-form btn-light"
                                  onClick={this.clearForm}
                                >
                                  Limpiar
                                </button>
                              ) : null}
                              &nbsp;
                              {user.role.name == "Administrador Empresas" ? (
                                this.state.organization ? (
                                  <button
                                    type="submit"
                                    className="btn btn-form btn-light"
                                  >
                                    Guardar
                                  </button>
                                ) : null
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-form btn-light"
                                >
                                  Guardar
                                </button>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <ToastsContainer store={ToastsStore} />
                      </form>

                      <ModalDelete
                        show={this.state.show}
                        onCancel={this.showModal}
                        delete={
                          this.state.renderInput == 1
                            ? this.deleteInstrument
                            : this.deleteQuestion
                        }
                        msgDeleteAll={
                          this.state.renderInput == 1 ? (
                            <p>
                              De continuar, se eliminarán todos los registros
                              asociados.
                            </p>
                          ) : null
                        }
                      />
                      <ModalAlert
                        show={this.state.showModalAlert}
                        onCancel={this.showModalAlert}
                        activate={this.activateInstrument}
                        collaboratorsLength={this.state.collaboratorsLength}
                        collaboratorsLengthPrincipal={
                          this.state.collaboratorsLengthPrincipal
                        }
                        collaboratorsLengthAdmin={
                          this.state.collaboratorsLengthAdmin
                        }
                        date={this.state.date}
                      />
                      <ModalAddParticipants
                        name={this.state.nameInstrument}
                        show={this.state.showModalAddParticipants}
                        onCancel={this.showModalAddParticipants}
                        addParticipants={this.addParticipants}
                        emails={this.state.emails}
                        repeatEmails={this.state.repeatEmails}
                        listParticipants={this.state.listParticipants}
                        collaborators={this.state.collaborators}
                        repeatEmailsColl={this.state.repeatEmailsColl}
                        noRepeatColl={this.state.noRepeatColl}
                        listEmailsRepeat={this.state.listEmailsRepeat}
                        isLoadingAdd={this.state.isLoadingAdd}
                        repeatEmailsForValidation={
                          this.state.repeatEmailsForValidation
                        }
                        repeatDNIForValidation={
                          this.state.repeatDNIForValidation
                        }
                      />
                    </div>
                    <div className="col-md-9">
                      {this.state.renderInput == 1 ? (
                        <Table
                          data={this.state.data}
                          columns={
                            user.role.name == "Administrador Empresas"
                              ? columns3
                              : columns1
                          }
                        />
                      ) : (
                        ""
                      )}
                      {this.state.renderInput == 2 ? (
                        <Table
                          data={
                            this.state.instrument == ""
                              ? this.state.dataQuestions
                              : this.state.dataQuestionsByInstrument
                          }
                          columns={columns2}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BlockUi>
      );
    }
  }
}
