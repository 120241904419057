import React, { Component } from 'react';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';

import ModalDelete from '../../ModalDelete/ModalDelete.jsx'
import msgObj from '../../../const.js';
import token from '../../../token.js'
import Table from '../../Table/Table';
import './Dimension.css';

export default class Dimension extends Component {

    constructor() {
        super();
        this.state = {
            _id: '',
            renderInput: 'Seleccione Dimensión o Subdimensión . . .',
            _idDelete: '',
            name: '',
            description: '',
            dimensions: '',
            periods: '',
            status: false,
            dimension: [],
            data: [],
            dataL: [],
            dataDimension: [],
            subdimension: [],
            columns: [{}],
            validate: true,
            show: false,
            error: '',
            dataByDimension: []
        }
    }

    componentDidMount() {
        this.getDimensions();
        this.getSubdimensions();
    }

    isChecked = () => {
        this.setState({
            status: !this.state.status
        })
    }

    handleChange = (event) => {
        const rules = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ_@./#&+-,\s]*$/g
        const dimensionName = this.state.name;
        const validate = rules.test(dimensionName);
        const { name, value } = event.target;
        this.setState({
            [name]: value,
            validate
        })
    }

    onDimension = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.getSubdimensionsByDimension();
        })
    }

    showModal = (id) => {
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/questions/dimensions/${id}`, { headers: { 'x-auth-token': token } })
            .then(res => {
                this.setState({
                    error: res.data.exist
                })
            })

        this.setState({
            _idDelete: id,
            show: !this.state.show,
        })
    }

    clearForm = () => {
        this.setState({
            _id: '',
            name: '',
            description: '',
            dimensions: '',
            status: false,
            periods: ''

        })
    }

    addDimension = (event) => {
        event.preventDefault();
        if (this.state._id) {
            axios.put(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/dimensions/${this.state._id}`, {
                name: this.state.name,
                description: this.state.description,
                status: this.state.status,
                evaluationPeriod: this.state.periods
            }, { headers: { 'x-auth-token': token } })
                .then(res => {
                    this.setState({
                        _id: '',
                        name: '',
                        description: '',
                        status: false,
                        periods: ''
                    })
                    this.getDimensions();
                    this.getSubdimensions();
                    ToastsStore.success(res.data.msg);
                })

        } else {
            axios.post(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/dimensions`, {
                name: this.state.name,
                description: this.state.description,
                status: this.state.status,
                evaluationPeriod: this.state.periods
            }, { headers: { 'x-auth-token': token } })
                .then(res => {
                    this.setState({
                        name: '',
                        description: '',
                        status: false,
                        periods: ''
                    })
                    this.getDimensions();
                    ToastsStore.success(res.data.msg);
                })
                .catch(err => {
                    ToastsStore.error(err.response.data.msg)
                });
        }
    }

    addSubdimension = (event) => {
        event.preventDefault();
        if (this.state._id) {
            const objRequest = {
                dimension: {
                    "_id": this.state.dimensions
                },
                name: this.state.name,
                description: this.state.description,
                status: this.state.status,
            }
            axios.put(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/subdimensions/${this.state._id}`, objRequest, { headers: { 'x-auth-token': token } })
                .then(res => {
                    this.setState({
                        dimensions: '',
                        name: '',
                        description: '',
                        status: false
                    })
                    this.getSubdimensions();
                    ToastsStore.success(res.data.msg);
                })
        } else {
            const objRequest = {
                dimension: {
                    "_id": this.state.dimensions
                },
                name: this.state.name,
                description: this.state.description,
                status: this.state.status,
            }
            axios.post(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/subdimensions`, objRequest, { headers: { 'x-auth-token': token } })
                .then(res => {
                    this.setState({
                        dimensions: '',
                        name: '',
                        description: '',
                        status: false
                    })
                    this.getSubdimensions();
                    ToastsStore.success(res.data.msg);
                })
                .catch(err => {
                    console.log(err.response)
                    ToastsStore.error(err.response.data.msg)
                });
        }

    }

    getDimensions = () => {
        this.setState({
            dataDimension: [],
            dataL: []
        })
        const dataDimension = Object.assign([], this.state.dataDimension)
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/dimensions`, { headers: { 'x-auth-token': token } })
            .then(res => {
                console.log(res.data);
                this.setState({
                    dimension: res.data.dimensions
                })
                this.state.dimension.map(dimension => {

                    dataDimension.push({
                        "_id": dimension._id,
                        "name": dimension.name,
                        "description": dimension.description,
                        "status": dimension.status
                    })


                })
                this.setState({
                    dataDimension
                })
            })
            .catch(err => {
                console.log(err);
            })

        const dataL = Object.assign([], this.state.dataL)
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/dimensions/active`, { headers: { 'x-auth-token': token } })
            .then(res => {
                this.setState({
                    dataL: res.data.dimensions
                })
                this.state.dataL.map(dimension => {
                    if (dimension.status) {
                        dataL.push({
                            "_id": dimension._id,
                            "name": dimension.name,
                            "description": dimension.description
                        })
                    }
                })
                this.setState({
                    dataL
                })
            });
    }

    editDimension = (id) => {
        this.clearForm();
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/dimensions/${id}`, { headers: { 'x-auth-token': token } })
            .then(res => this.setState({
                _id: res.data.dimension._id,
                name: res.data.dimension.name,
                description: res.data.dimension.description,
                status: res.data.dimension.status,
                periods: res.data.dimension.evaluationPeriod
            }))
    }

    deleteDimension = (id) => {

        axios.delete(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/dimensions/${this.state._idDelete}`, { headers: { 'x-auth-token': token } })
            .then(res => {
                this.getDimensions();
                this.getSubdimensions();
                ToastsStore.success(res.data.msg);
                this.setState({
                    _idDelete: '',
                    show: !this.state.show
                })
            })
            .catch(err => {
                ToastsStore.error(err.response.data.msg)
            });

    }

    getSubdimensions = () => {
        this.setState({
            data: []
        })
        const data = Object.assign([], this.state.data)
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/subdimensions`, { headers: { 'x-auth-token': token } })
            .then(res => {
                console.log(res.data)
                this.setState({
                    subdimension: res.data.subdimensions
                })

                this.state.subdimension.map(subdimension => {
                    data.push({
                        "_id": subdimension._id,
                        "name": subdimension.dimension.name,
                        "subdimensionName": subdimension.name,
                        "description": subdimension.description,
                        "status": subdimension.status
                    })
                })

                this.setState({
                    data
                })
            })
    }

    getSubdimensionsByDimension = () => {
        this.setState({
            dataByDimension: []
        }, () => {
            const dataByDimension = Object.assign([], this.state.dataByDimension)
            axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/subdimensions`, { headers: { 'x-auth-token': token } })
                .then(res => {
                    this.setState({
                        subdimension: res.data.subdimensions
                    })
                    this.state.subdimension.map(subdimension => {
                        if (subdimension.dimension._id == this.state.dimensions) {
                            dataByDimension.push({
                                "_id": subdimension._id,
                                "name": subdimension.dimension.name,
                                "subdimensionName": subdimension.name,
                                "description": subdimension.description,
                                "status": subdimension.status
                            })
                        }
                    })

                    this.setState({
                        dataByDimension
                    })
                })
        })

    }

    editSubdimension = (id) => {
        this.clearForm();
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/subdimensions/${id}`, { headers: { "x-auth-token": token } })
            .then(res => this.setState({
                _id: res.data.subdimension._id,
                dimensions: res.data.subdimension.dimension._id,
                name: res.data.subdimension.name,
                description: res.data.subdimension.description,
                status: res.data.subdimension.status
            }))
    }

    deleteSubdimension = (id) => {
        axios.delete(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/subdimensions/${this.state._idDelete}`, { headers: { 'x-auth-token': token } })
            .then(res => {
                this.getSubdimensions();
                ToastsStore.success(res.data.msg);
                this.setState({
                    _idDelete: '',
                    show: !this.state.show
                })
            })
            .catch(err => ToastsStore.error(err.response.data.msg));
    }

    render() {

        const { user } = this.props;

        const formatWithIconDimension = (cell, row) => {
            return (
                <div>
                    <i
                        data-tip={msgObj.update}
                        data-for="update"
                        className="fas fa-edit"
                        aria-hidden="true"
                        onClick={() => this.editDimension(row._id)}></i>
                    <ReactTooltip
                        place="top"
                        id="update" />
                    &nbsp;&nbsp;
                    {user.role.name == 'Administrador Empresas'
                        ? null
                        :
                        <i
                            className="fa fa-trash"
                            data-tip={msgObj.delete}
                            data-for="delete"
                            aria-hidden="true"
                            onClick={() => this.showModal(row._id)}></i>}
                    <ReactTooltip
                        place="top"
                        id="delete" />
                </div>
            )
        }

        const formatWithIconSubdimension = (cell, row) => {
            return (
                <div>
                    <i
                        data-tip={msgObj.update}
                        data-for="update"
                        className="fas fa-edit"
                        aria-hidden="true"
                        onClick={() => this.editSubdimension(row._id)}></i>
                    <ReactTooltip
                        place="top"
                        id="update" />
                    &nbsp;&nbsp;
                    {user.role.name == 'Administrador Empresas'
                        ? null
                        :
                        <i
                            className="fa fa-trash"
                            data-tip={msgObj.delete}
                            data-for="delete"
                            aria-hidden="true"
                            onClick={() => this.showModal(row._id)}></i>}
                    <ReactTooltip
                        place="top"
                        id="delete" />
                </div>
            )
        }

        const formatWithStatus = (cell, row) => {
            return (
                <div>
                    {row.status
                        ? <i
                            className="fas fa-circle status-active"
                            aria-hidden="true"
                            data-tip={msgObj.enable}
                            data-for="enable"
                        >
                        </i>
                        : <i
                            className="fas fa-circle status-inactive"
                            aria-hidden="true"
                            data-tip={msgObj.disable}
                            data-for="disable"></i>
                    }
                    <ReactTooltip
                        place="top"
                        id={row.status ? "enable" : "disable"} />
                </div>
            )
        }

        const columns1 = [
            {
                dataField: 'status',
                text: '',
                formatter: formatWithStatus
            },
            {
                dataField: 'name',
                text: 'Dimensión',
                sort: true
            },
            {
                dataField: 'description',
                text: 'Descripción'
            },
            {
                dataField: 'deleteAction',
                text: '',
                formatter: formatWithIconDimension
            }]

        const columns2 = [
            {
                dataField: 'status',
                text: '',
                formatter: formatWithStatus
            },
            {
                dataField: 'name',
                text: 'Dimensión',
                sort: true
            },
            {
                dataField: 'subdimensionName',
                text: 'Subdimensión',
                sort: true
            },
            {
                dataField: 'description',
                text: 'Descripción'
            },
            {
                dataField: 'deleteAction',
                text: '',
                formatter: formatWithIconSubdimension
            }]

        return (
            <div className="row">
                <div className="col-md-5">
                    <form onSubmit={this.state.renderInput == 1 ? this.addDimension : this.addSubdimension} autocomplete="off">
                        <div className="row form-group">
                            <div className="col-md-12">
                                <select
                                    name="renderInput"
                                    ref="renderInput"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.renderInput}
                                    required>
                                    <option value="Seleccione Dimensión o Subdimensión . . ."
                                        disabled>Seleccione Dimensión o Subdimensión . . .</option>
                                    <option value="1">Dimensión</option>
                                    <option value="2">Subdimensión</option>
                                </select>
                            </div>
                        </div>
                        {this.state.renderInput == 2 ?
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <select
                                        name="dimensions"
                                        ref="dimensions"
                                        className="form-control"
                                        onChange={this.onDimension}
                                        value={this.state.dimensions}
                                        required
                                        selected>
                                        <option value=""
                                            disabled>Dimensión</option>
                                        {this.state.dataL.map(dimension => {
                                            return (
                                                <option value={dimension._id}>
                                                    {dimension.name}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            : ''}
                        {this.state.renderInput == 1 ? <div className="row form-group">
                            {this.state.validate ? '' : <label className="errmsg">{msgObj.letterField}</label>}
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Dimensión"
                                    ref="name"
                                    className={this.state.validate ? "form-control" : " form-control invalid"}
                                    onChange={this.handleChange}
                                    value={this.state.name}
                                    required
                                />
                            </div>
                        </div>
                            : ''}
                        {this.state.renderInput == 1 ?
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <select
                                        name="periods"
                                        ref="periods"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.periods}
                                        selected>
                                        <option value=""
                                            disabled>Seleccione el período de medición. . .</option>
                                        <option value="3">3 meses</option>
                                        <option value="6">6 meses</option>
                                        <option value="9">9 meses</option>
                                        <option value="12">12 meses</option>
                                    </select>
                                </div>
                            </div>
                            : ''}
                        {this.state.renderInput == 1 ? <div className="row form-group">
                            <div className="col-md-12">
                                <textarea
                                    name="description"
                                    placeholder="Descripción"
                                    ref="description"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.description}
                                    required
                                />
                            </div>
                        </div>
                            : ''}
                        {this.state.renderInput == 2 ? <div className="row form-group">
                            {this.state.validate ? '' : <label className="errmsg">{msgObj.letterField}</label>}
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Subdimensión"
                                    ref="name"
                                    className={this.state.validate ? "form-control" : " form-control invalid"}
                                    onChange={this.handleChange}
                                    value={this.state.name}
                                    required
                                />
                            </div>
                        </div>
                            : ''}
                        {this.state.renderInput == 2 ? <div className="row form-group">
                            <div className="col-md-12">
                                <textarea
                                    name="description"
                                    placeholder="Descripción"
                                    ref="description"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.description}
                                    required
                                />
                            </div>
                        </div>
                            : ''}
                        {user.role.name == 'Administrador Empresas'
                            ? null
                            :
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <div className="checkbox">
                                        <label>
                                            Activar
                                        <input
                                                type="checkbox"
                                                name="switch"
                                                onClick={this.isChecked}
                                                checked={this.state.status}
                                                ref="switch"
                                                className="switch"
                                            />
                                            <div>
                                                <div></div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>}
                        <br />
                        {this.state.renderInput != 'Seleccione Dimensión o Subdimensión . . .' ? <div className="col-md-12">
                            <div className="text-center">
                                {this.state._id != '' ?
                                    <button
                                        type="button"
                                        className="btn btn-form btn-light"
                                        onClick={this.clearForm}
                                    >Limpiar</button> : null}
                                &nbsp;
                                    {user.role.name == 'Administrador Empresas'
                                    ? null
                                    :
                                    this.state.validate ? (
                                        <button type="submit" className="btn btn-form btn-light">
                                            Guardar
                                        </button>
                                    ) : (
                                            <button
                                                type="button"
                                                className="btn btn-form btn-light"
                                                disabled
                                            >
                                                Guardar
                                        </button>
                                        )}
                            </div>
                        </div> : ''}
                        <ModalDelete
                            show={this.state.show}
                            onCancel={this.showModal}
                            delete={this.state.renderInput == 1 ? this.deleteDimension : this.deleteSubdimension}
                            msgDeleteAll={this.state.renderInput == 1 ?
                                <p>De continuar, se eliminarán todos los registros asociados.</p>
                                : null}
                            msgError={this.state.error ?
                                <div>
                                    <p className="msgErrorModal">El registro se encuentra asociado a una pregunta o instrumento. El mismo no puede ser eliminado.</p>
                                </div>
                                : null}
                        />
                    </form>
                </div>
                {this.state.renderInput == 1 ?
                    <div className="col-md-7">
                        <Table
                            data={this.state.dataDimension}
                            columns={columns1} />
                    </div> : ''}
                {this.state.renderInput == 2 ?
                    <div className="col-md-7">
                        <Table
                            data={this.state.dimensions == "" ? this.state.data : this.state.dataByDimension}
                            columns={columns2} />
                    </div> : ''}
                <ToastsContainer store={ToastsStore} />
            </div>
        )
    }
}