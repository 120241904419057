import React, { Component } from "react";
import axios from "axios";
import { ToastsContainer, ToastsStore } from "react-toasts";
import ReactTooltip from 'react-tooltip';

import ModalDelete from "../../ModalDelete/ModalDelete.jsx";
import msgObj from '../../../const.js';
import token from '../../../token.js'
import Table from "../../Table/Table.jsx";

export default class Privileges extends Component {
  constructor() {
    super();
    this.state = {
      _id: "",
      _idDelete: "",
      action: "Seleccione Acciones...",
      actions: [],
      privileges: [],
      privilege: "",
      isReport: false,
      validate: true,
      duplicate: "",
      show: false,
      data: []
    };
  }

  componentDidMount() {
    this.getPrivileges();
  }

  isChecked = () => {
    this.setState({
      isReport: !this.state.isReport
    });
  };

  clearForm = () => {
    this.setState({
      _id: "",
      action: "Seleccione Acciones...",
      actions: [],
      privilege: "",
      isReport: false
    });
  };

  showModal = id => {
    this.setState({
      _idDelete: id,
      show: !this.state.show
    });
  };

  handleChange = event => {
    const rules = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/g;
    const privilegeName = this.refs.privilege.value;
    const validate = rules.test(privilegeName);
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      validate
    });
  };

  handleBadge = event => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value
      },
      () => {
        this.addActions();
      }
    );
  };

  addActions = () => {
    if (this.state.action != "Seleccione Acciones...") {
      const actions = Object.assign([], this.state.actions);
      const validate = actions.indexOf(this.state.action);
      actions.push(this.state.action);
      if (validate != -1) {
        ToastsStore.info("No puede crear acciones duplicadas");
      } else {
        this.setState({
          actions
        });
      }
    } else {
      ToastsStore.info("Por favor seleccione una acción");
    }
    console.log(this.state.validate);
  };

  removeActions = event => {
    const actions = Object.assign([], this.state.actions);
    const index = actions.indexOf(event.target.id);
    actions.splice(index, 1);
    this.setState({
      actions
    });
  };

  getPrivileges = () => {
    this.setState({
      data: []
    });
    const data = Object.assign([], this.state.data);
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/privileges`,
        { headers: { 'x-auth-token': token } })
      .then(res => {
        this.setState({
          privileges: res.data.privileges
        });
        this.state.privileges.map(privilege => {
          if (privilege.isReport) {
            privilege.isReport = "Sí";
          } else {
            privilege.isReport = "No";
          }
          data.push({
            _id: privilege._id,
            name: privilege.name,
            isReport: privilege.isReport
          });
        });
        this.setState({
          data
        });
      });
  };

  addPrivileges = event => {
    event.preventDefault();
    if (this.state._id) {
      axios
        .put(
          `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/privileges/${this.state._id}`,
          {
            name: this.state.privilege,
            isReport: this.state.isReport,
            actions: this.state.actions
          },
          {
            headers: { 'x-auth-token': token }
          }
        )
        .then(res => {
          this.setState({
            _id: "",
            privilege: "",
            isReport: false,
            actions: [],
            action: "Seleccione Acciones..."
          });
          this.getPrivileges();
          ToastsStore.success(res.data.msg);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/privileges`,
          {
            name: this.state.privilege,
            isReport: this.state.isReport,
            actions: this.state.actions
          },
          {
            headers: { 'x-auth-token': token }
          }
        )
        .then(res => {
          this.setState({
            privilege: "",
            isReport: false,
            actions: [],
            action: "Seleccione Acciones..."
          });
          this.getPrivileges();
          ToastsStore.success(res.data.msg);
        })
        .catch(err => {
          ToastsStore.error(err.response.data.msg);
        });
    }
  };

  editPrivilege = id => {
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/privileges/${id}`,
        {
          headers: { 'x-auth-token': token }
        }
      )
      .then(res =>
        this.setState({
          _id: res.data.privilege._id,
          privilege: res.data.privilege.name,
          isReport: res.data.privilege.isReport,
          actions: res.data.privilege.actions
        })
      );
  };

  deletePrivilege = id => {
    axios
      .delete(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/privileges/${this.state._idDelete}`,
        {
          headers: { 'x-auth-token': token }
        }
      )
      .then(res => {
        this.getPrivileges();
        ToastsStore.success(res.data.msg);
        this.setState({
          _idDelete: "",
          show: !this.state.show
        });
      })
      .catch(err => {
        ToastsStore.error(err.response.data.msg)
      });
  };

  render() {
    const { user } = this.props
    const formatWithIcon = (cell, row) => {
      return (
        <div>
          <i
            data-tip={msgObj.update}
            data-for="update"
            className="fas fa-edit"
            aria-hidden="true"
            onClick={() => this.editPrivilege(row._id)}></i>
          <ReactTooltip
            place="top"
            id="update" />
          &nbsp;&nbsp;
          {user.role.name == 'Administrador'
            ? null
            :
            <i
              className="fa fa-trash"
              data-tip={msgObj.delete}
              data-for="delete"
              aria-hidden="true"
              onClick={() => this.showModal(row._id)}></i>}
          <ReactTooltip
            place="top"
            id="delete" />
        </div>
      );
    };

    const columns = [
      {
        dataField: "name",
        text: "Privilegio",
        sort: true
      },
      {
        dataField: "isReport",
        text: "Reporte",
        sort: true
      },
      {
        dataField: "deleteAction",
        text: "",
        formatter: formatWithIcon
      }
    ];

    return (
      <div className="row">
        <div className="col-md-4">
          <form onSubmit={this.addPrivileges} autocomplete="off">
            <div className="row form-group">
              {this.state.validate ? (
                ""
              ) : (
                  <label className="errmsg">
                    {msgObj.letterField}
                  </label>
                )}
              <div className="col-md-12">
                <input
                  type="text"
                  name="privilege"
                  placeholder="Privilegios"
                  ref="privilege"
                  className={
                    this.state.validate
                      ? "form-control"
                      : " form-control invalid"
                  }
                  onChange={this.handleChange}
                  value={this.state.privilege}
                  required
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-12">
                <select
                  name="action"
                  ref="action"
                  className="form-control"
                  onChange={this.handleBadge}
                  value={this.state.action}
                  required
                >
                  <option value="Seleccione Acciones..." disabled>
                    Seleccione Acciones...
                  </option>
                  <option value="Crear">Crear</option>
                  <option value="Eliminar">Eliminar</option>
                  <option value="Actualizar">Actualizar</option>
                  <option value="Consultar">Consultar</option>
                </select>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                {this.state.actions.map(action => {
                  return (
                    <span
                      className="badge badge-pill badge-secondary badge-outlined"
                      onClick={this.removeActions}
                      id={action}
                    >
                      {action} X
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-12">
                <div className="checkbox">
                  <label>
                    Reporte?
                    <input
                      type="checkbox"
                      name="switch"
                      onClick={this.isChecked}
                      checked={this.state.isReport}
                      ref="switch"
                      className="switch"
                    />
                    <div>
                      <div></div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <br />
            <div className="col-md-12">
              <div className="text-center">
                {this.state._id != "" ? (
                  <button
                    type="button"
                    className="btn btn-form btn-light"
                    onClick={this.clearForm}
                  >
                    Limpiar
                  </button>
                ) : null}
                &nbsp;
                {user.role.name == 'Administrador' || user.role.name == 'Administrador Empresas'
                  ? null
                  :
                  this.state.validate ? (
                    <button type="submit" className="btn btn-form btn-light">
                      Guardar
                  </button>
                  ) : (
                      <button
                        type="button"
                        className="btn btn-form btn-light"
                        disabled
                      >
                        Guardar
                  </button>
                    )}
              </div>
            </div>
            <ToastsContainer store={ToastsStore} />
            <ModalDelete
              show={this.state.show}
              onCancel={this.showModal}
              delete={this.deletePrivilege}
            />
          </form>
        </div>
        <div className="col-md-8">
          <Table data={this.state.data} columns={columns} />
        </div>
      </div>
    );
  }
}
