import React, {Component} from 'react';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom'
import {ToastsContainer, ToastsStore} from 'react-toasts';

import logo from '../../LogoIntelix.png'
import './RecoverPassword.css';

export default class RecoverPassword extends Component {

    constructor(){
        super();
        this.state = {
            email:''
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]:value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        console.log(this.state);
        axios.post(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/recoverPassword`, this.state)
        .then(res => {
            ToastsStore.info('Contraseña enviada al correo.');
            this.props.history.push({
                pathname:'/',
                state:{msg : 'Contraseña enviada al correo.'}
            });
        })
        .catch(err => {
            ToastsStore.error(err.response.data.msg)
            
        })
    }

    render(){
        return(
            <div className="RecoverPassword">
                <div className="container">
                    <div className="row justify-content-center align-items-center" id="center">
                        <div className="col-md-5">
                            <div className="card">
                                <div className="card-header text-center">
                                    <img src = {logo}></img>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group row">
                                            <div className="col-md-8 input">
                                                <input 
                                                type="email"
                                                name='email' 
                                                className="form-control" 
                                                ref="email"
                                                id="email"
                                                placeholder="Correo"
                                                onChange={this.handleChange} 
                                                value={this.state.email}
                                                required/>
                                            </div>
                                        </div>                                            
                                        <div className="text-center">
                                             <button
                                             type="submit"
                                             className="btn btn-light">Enviar</button>

                                        </div>
                                        <div className="form-group text-left">
                                            <Link to="/">
                                            <i className="fas fa-arrow-left"></i>
                                            </Link>
                                        </div>
                                        <ToastsContainer store={ToastsStore}/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}