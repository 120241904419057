import React, { Component } from "react";
import axios from "axios";
import token from "../../../token.js";
import { ToastsContainer, ToastsStore } from "react-toasts";
import ReactTooltip from "react-tooltip";
import ModalDelete from "../../ModalDelete/ModalDelete.jsx";
// import BASEPATH from "../../../const.js";
import msgObj from "../../../const.js";
import Table from "../../Table/Table";
import { CompactPicker } from "react-color";

class Baremo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      renderInput: "Seleccione una Opción . . .",
      _idDelete: "",
      name: "",
      description: "",
      level: "",
      status: false,
      baremo_status: "",
      baremo: [],
      data: [],
      dataL: [],
      dataBaremo: [],
      columns: [{}],
      rangeMin: "",
      rangeMax: "",
      color: "#FFFFFF",
      baremoList: "",
      validate: true,
      show: false,
      error: "",
    };
    this.handleRangeMinChange = this.handleRangeMinChange.bind(this);
    this.handleRangeMaxChange = this.handleRangeMaxChange.bind(this);
  }

  componentDidMount() {
    this.getBaremos();
    this.getStructureBaremos();
  }

  handleRangeMinChange = (e) => {
    this.setState({ rangeMin: e.target.value });
  };

  handleRangeMaxChange = (e) => {
    this.setState({ rangeMax: e.target.value });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleChangeComplete = (color) => {
    this.setState({ color: color.hex });
  };

  isChecked = () => {
    this.setState({
      status: !this.state.status,
    });
  };

  showModal = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/baremos/${id}`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        console.log(res);
        this.setState({
          error: res.data.exist,
        });
      });

    this.setState({
      _idDelete: id,
      show: !this.state.show,
    });
  };

  clearForm = () => {
    this.setState({
      _id: "",
      name: "",
      description: "",
      level: "",
      DData: "",
      status: false,
      baremoList: "",
      rangeMin: "",
      rangeMax: "",
      color: "#000000",
      disabledBtn: false,
    });
  };

  getBaremos = () => {
    this.setState({
      dataBaremo: [],
      dataL: [],
    });
    const dataBaremo = Object.assign([], this.state.dataBaremo);
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/baremos`,
        {
          headers: { "x-auth-token": token },
        }
      )
      .then((res) => {
        this.setState({
          baremo: res.data.baremos,
        });
        this.state.baremo.map((baremo) => {
          dataBaremo.push({
            _id: baremo._id,
            name: baremo.name,
            description: baremo.description,
            level: baremo.level,
            status: baremo.status,
          });
        });
        this.setState({
          dataBaremo,
        });
      });
    const dataL = Object.assign([], this.state.dataL);
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/baremos/active`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        this.setState({
          dataL: res.data.baremos,
        });
        this.state.dataL.map((baremo) => {
          if (baremo.status) {
            dataL.push({
              _id: baremo._id,
              name: baremo.name,
              description: baremo.description,
            });
          }
        });
        this.setState({
          dataL,
        });
      });
  };

  addBaremo = (event) => {
    event.preventDefault();
    if (this.state._id) {
      axios
        .put(
          `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/baremos/${this.state._id}`,
          {
            name: this.state.name,
            description: this.state.description,
            level: this.state.level,
            status: this.state.status,
          },
          { headers: { "x-auth-token": token } }
        )
        .then((res) => {
          this.setState({
            _id: "",
            name: "",
            description: "",
            status: false,
          });
          this.getBaremos();
          this.getStructureBaremos();
          ToastsStore.success(res.data.msg);
        })
        .catch((err) => {
          console.log(err.response.data.msg);
          ToastsStore.error(err.response.data.msg);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/baremos`,
          {
            name: this.state.name,
            description: this.state.description,
            level: this.state.level,
            status: this.state.status,
          },
          { headers: { "x-auth-token": token } }
        )
        .then((res) => {
          this.setState({
            name: "",
            description: "",
            level: "",
            status: false,
          });
          this.getBaremos();
          ToastsStore.success(res.data.msg);
        })
        .catch((err) => {
          ToastsStore.error(err.response.data.msg);
          console.log(err);
        });
    }
  };

  editBaremo = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/baremos/${id}`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) =>
        this.setState({
          _id: res.data.baremo._id,
          name: res.data.baremo.name,
          description: res.data.baremo.description,
          level: res.data.baremo.level,
          status: res.data.baremo.status,
        })
      );
  };

  deleteBaremo = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/baremos/${this.state._idDelete}`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        this.getBaremos();
        this.getStructureBaremos();
        ToastsStore.success(res.data.msg);
        this.setState({
          _idDelete: "",
          show: !this.state.show,
        });
        console.log("Error res: " + res);
      })
      .catch((err) => {
        console.log("Error Delete: " + err);
        // ToastsStore.error(err.response.data.msg);
      });
  };

  getStructureBaremos = () => {
    this.setState({
      data: [],
    });
    const data = [];
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/structureBaremos`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        console.log(res.data);
        this.setState({
          structureBaremo: res.data.structureBaremos,
        });
        if (this.state.structureBaremo != null) {
          this.state.structureBaremo.map((structureBaremo) => {
            if (structureBaremo.status) {
              structureBaremo.status = "Activo";
            } else {
              structureBaremo.status = "Inactivo";
            }
            if (structureBaremo.baremo.status) {
              console.log(`Activos hay aqui`);
              data.push({
                _id: structureBaremo._id,
                name: structureBaremo.baremo.name,
                description: structureBaremo.description,
                rangeMin: structureBaremo.rangeMin.toFixed(2),
                rangeMax: structureBaremo.rangeMax.toFixed(2),
                color: structureBaremo.color,
              });
            }
          });
          this.setState({
            data,
          });
        }
      });
  };

  addStructureBaremo = (event) => {
    event.preventDefault();
    if (this.state._id) {
      const objRequest = {
        baremo: {
          _id: this.state.baremoList,
        },
        description: this.state.description,
        rangeMin: this.state.rangeMin,
        rangeMax: this.state.rangeMax,
        color: this.state.color,
      };
      axios
        .put(
          `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/structureBaremos/${this.state._id}`,
          objRequest,
          { headers: { "x-auth-token": token } }
        )
        .then((res) => {
          this.setState({
            baremoList: "",
            name: "",
            description: "",
            rangeMin: "",
            rangeMax: "",
            color: "#000000",
          });
          this.getStructureBaremos();
          ToastsStore.success(res.data.msg);
        })
        .catch((err) => {
          console.log(err.response.data.msg);
          ToastsStore.error(err.response.data.msg);
        });
    } else {
      const objRequest = {
        baremo: {
          _id: this.state.baremoList,
        },
        description: this.state.description,
        rangeMin: this.state.rangeMin,
        rangeMax: this.state.rangeMax,
        color: this.state.color,
      };
      axios
        .post(
          `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/structureBaremos`,
          objRequest,
          { headers: { "x-auth-token": token } }
        )
        .then((res) => {
          this.setState({
            baremoList: "",
            name: "",
            description: "",
            rangeMin: "",
            rangeMax: "",
            color: "#000000",
          });
          this.getStructureBaremos();
          ToastsStore.success(res.data.msg);
        })
        .catch((err) => {
          ToastsStore.error(err.response.data.msg);
        });
    }
  };

  editStructureBaremo = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/structureBaremos/${id}`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) =>
        this.setState({
          _id: res.data.structureBaremo._id,
          baremoList: res.data.structureBaremo.baremo._id,
          description: res.data.structureBaremo.description,
          rangeMin: res.data.structureBaremo.rangeMin,
          rangeMax: res.data.structureBaremo.rangeMax,
          color: res.data.structureBaremo.color,
        })
      );
  };

  deleteStructureBaremo = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/structureBaremos/${this.state._idDelete}`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        this.getStructureBaremos();
        ToastsStore.success(res.data.msg);
        this.setState({
          _idDelete: "",
          show: !this.state.show,
        });
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { user } = this.props;
    const formatWithIconBaremo = (cell, row) => {
      return (
        <div>
          <i
            data-tip={msgObj.update}
            data-for="update"
            className="fas fa-edit"
            aria-hidden="true"
            onClick={() => this.editBaremo(row._id)}
          ></i>
          <ReactTooltip place="top" id="update" />
          &nbsp;&nbsp;
          <i
            className="fa fa-trash"
            data-tip={msgObj.delete}
            data-for="delete"
            aria-hidden="true"
            onClick={() => this.showModal(row._id)}
          ></i>{" "}
          <ReactTooltip place="top" id="delete" />
        </div>
      );
    };

    const formatWithIconStructureBaremo = (cell, row) => {
      return (
        <div>
          <i
            data-tip={msgObj.update}
            data-for="update"
            className="fas fa-edit"
            aria-hidden="true"
            onClick={() => this.editStructureBaremo(row._id)}
          ></i>
          <ReactTooltip place="top" id="update" />
          &nbsp;&nbsp;
          <i
            className="fa fa-trash"
            data-tip={msgObj.delete}
            data-for="delete"
            aria-hidden="true"
            onClick={() => this.showModal(row._id)}
          ></i>
          <ReactTooltip place="top" id="delete" />
        </div>
      );
    };

    const formatWithStatus = (cell, row) => {
      return (
        <div>
          {row.status ? (
            <i
              className="fas fa-circle status-active"
              aria-hidden="true"
              data-tip={msgObj.enable}
              data-for="enable"
            ></i>
          ) : (
            <i
              className="fas fa-circle status-inactive"
              aria-hidden="true"
              data-tip={msgObj.disable}
              data-for="disable"
            ></i>
          )}
          <ReactTooltip place="top" id={row.status ? "enable" : "disable"} />
        </div>
      );
    };

    const columns1 = [
      {
        dataField: "name",
        formatter: formatWithStatus,
      },
      {
        dataField: "description",
        text: "Descripción",
        sort: true,
      },
      {
        dataField: "level",
        text: "Nivel",
      },
      {
        dataField: "deleteAction",
        text: "",
        formatter: formatWithIconBaremo,
      },
    ];

    const columns2 = [
      {
        dataField: "name",
        text: "Nombre",
        sort: true,
      },
      {
        dataField: "rangeMin",
        text: "Rango Mínimo",
      },
      {
        dataField: "rangeMax",
        text: "Rango Máximo",
      },
      {
        dataField: "",
        text: "Color",
        style: (cell, row, rowIndex, colIndex) => {
          return { backgroundColor: this.state.data[rowIndex].color };
        },
      },
      {
        dataField: "deleteAction",
        text: "",
        formatter: formatWithIconStructureBaremo,
      },
    ];

    return (
      <div className="row">
        {(row, rowIndex) => {
          return this.props.color;
        }}
        <div className="col-md-5">
          <form
            onSubmit={
              this.state.renderInput == 1
                ? this.addBaremo
                : this.addStructureBaremo
            }
            autocomplete="off"
          >
            <div className="row form-group">
              <div className="col-md-12">
                <select
                  name="renderInput"
                  ref="renderInput"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.renderInput}
                  required
                >
                  <option value="Seleccione una Opción . . ." disabled>
                    Seleccione una Opción . . .
                  </option>
                  <option value="1">Crear Baremo</option>
                  <option value="2">Valores de Baremo</option>
                </select>
              </div>
            </div>
            {this.state.renderInput == 2 ? (
              <div className="row form-group">
                <div className="col-md-12">
                  <select
                    name="baremoList"
                    ref="baremoList"
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.baremoList}
                    required
                    selected
                  >
                    <option value="" disabled>
                      Baremo
                    </option>
                    {this.state.dataL.map((baremo) => {
                      return <option value={baremo._id}>{baremo.name}</option>;
                    })}
                  </select>
                </div>
              </div>
            ) : (
              ""
            )}
            {this.state.renderInput == 1 ? (
              <div className="row form-group">
                <div className="col-md-12">
                  <input
                    type="text"
                    name="name"
                    placeholder="Nombre"
                    ref="name"
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.name}
                    required
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {this.state.renderInput == 2 ? (
              <div className="row form-group">
                <div className="col-md-12">
                  <textarea
                    type="text"
                    name="description"
                    placeholder="Descripción"
                    ref="description"
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.description}
                    required
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {this.state.renderInput == 1 ? (
              <div className="row form-group">
                <div className="col-md-12">
                  <textarea
                    name="description"
                    placeholder="Descripción"
                    ref="description"
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.description}
                    required
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {this.state.renderInput == 2 ? (
              <div className="row form-group">
                <div className="col-md-12">
                  <input
                    type="text"
                    name="rangeMin"
                    placeholder="Rango Mínimo"
                    ref="rangeMin"
                    className="form-control"
                    maxLength="120"
                    onChange={this.handleRangeMinChange.bind(this)}
                    value={this.state.rangeMin}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {this.state.renderInput == 2 ? (
              <div className="row form-group">
                <div className="col-md-12">
                  <input
                    type="text"
                    name="rangeMax"
                    placeholder="Rango Máximo"
                    ref="rangeMax"
                    className="form-control"
                    maxLength="120"
                    onChange={this.handleRangeMaxChange}
                    value={this.state.rangeMax}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {this.state.renderInput == 1 ? (
              <div className="row form-group">
                <div className="col-md-12">
                  <input
                    type="number"
                    name="level"
                    placeholder="Nivel"
                    ref="level"
                    className="form-control"
                    max="10"
                    min="0"
                    onChange={this.handleChange}
                    value={this.state.level}
                    required
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {this.state.renderInput == 2 ? (
              <div className="row form-group justify-content-md-center">
                <div className="col-md-7">
                  <CompactPicker
                    color={this.state.color}
                    onChangeComplete={this.handleChangeComplete}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {this.state.renderInput == 1 && user.role.name != "Reporte" ? (
              <div className="row form-group">
                <div className="col-md-12">
                  <div className="checkbox">
                    <label>
                      Activar
                      <input
                        type="checkbox"
                        name="switch"
                        onClick={this.isChecked}
                        checked={this.state.status}
                        ref="switch"
                        className="switch"
                      />
                      <div>
                        <div></div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <br />
            {this.state.renderInput != "Seleccione una Opción . . ." ? (
              <div className="col-md-12">
                <div className="text-center">
                  {this.state._id != "" ? (
                    <button
                      type="button"
                      className="btn btn-form btn-light"
                      onClick={this.clearForm}
                    >
                      Limpiar
                    </button>
                  ) : null}
                  &nbsp;
                  {user.role.name == "Reporte" ? null : (
                    <button type="submit" className="btn btn-form btn-light">
                      Guardar
                    </button>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            <ModalDelete
              show={this.state.show}
              onCancel={this.showModal}
              delete={
                this.state.renderInput == 1
                  ? this.deleteBaremo
                  : this.deleteStructureBaremo
              }
              msgDeleteAll={
                this.state.renderInput == 1 ? (
                  <p>
                    De continuar, se eliminarán todos los registros asociados.
                  </p>
                ) : null
              }
              msgError={
                this.state.error ? (
                  <div>
                    <p className="msgErrorModal">
                      El registro se encuentra asociado a un baremo. El mismo no
                      puede ser eliminado.
                    </p>
                  </div>
                ) : null
              }
            />
          </form>
        </div>
        {this.state.renderInput == 1 ? (
          <div className="col-md-7">
            <Table data={this.state.dataBaremo} columns={columns1} />
          </div>
        ) : (
          ""
        )}
        {this.state.renderInput == 2 ? (
          <div className="col-md-7">
            <Table data={this.state.data} columns={columns2} />
          </div>
        ) : (
          ""
        )}
        <ToastsContainer store={ToastsStore} />
      </div>
    );
  }
}

export default Baremo;
