import React, { Component } from "react";
import $ from "jquery";
import { Redirect, Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import axios from 'axios';
import msgObj from '../../const.js';

import TimeLoader from '../TimeLoader/TimeLoader.jsx';
import logo from "../../LogoBe&co.png";
import versionTooltip from '../../version.js'
import UploadImage from "../UpdateImage/Update.Image.jsx";
import Sidebar from "../Sidebar/Sidebar.jsx";
import Brands from "./Brands/Brands.jsx";
import Imports from "./Imports/Imports.jsx";
import AssignBrands from "./AssignBrands/AssignBrands.jsx";

import "./DataManagement.css";

export default class DataManagement extends Component {
  constructor() {
    super();
    this.state = {
      page: "import",
      sidebarCollapse: true,
      image: '',
      organizations: []
    };
  }

  changedPage = event => {
    this.setState({
      page: event.target.name
    });
  };

  collapseSidebar = () => {
    this.setState({
      sidebarCollapse: !this.state.sidebarCollapse
    });
  };

  logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("image");
  };

  collapseSidebar = () => {
    this.setState({
      sidebarCollapse: !this.state.sidebarCollapse
    });
  };

  getLogo = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/organizations`, { headers: { 'x-auth-token': token } })
      .then(res => {
        this.setState({
          organizations: res.data.organizations
        });
        this.state.organizations.map(organization => {
          if (organization._id == user.company.organization._id) {

            this.setState({
              image: organization.logo.imagecode
            });
          }
        });

      });

  };

  componentDidMount() {
    $("#menu-toggle").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });
    this.getLogo();
  }

  render() {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    if (!token) {
      return <Redirect to="/"></Redirect>;
    } else {
      return (
        <div className="ActualizacionDatos">
          <TimeLoader redirect={this.props.history} />
          <div className="wrapper" id="wrapper">
            <Sidebar page="update" role={user.role} />
            <div id="page-content-wrapper">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <div id="navbarNav" className="collapse navbar-collapse">
                        <ul className="nav navbar-nav mr-auto">
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-tip={versionTooltip}
                              data-for="version"
                              href="#menu-toggle"
                              id="menu-toggle"
                              onClick={this.collapseSidebar}
                            >
                              <i className="fa fa-bars"></i>
                            </a>
                            <ReactTooltip place="top" id="version" />
                          </li>
                          <li
                            className="nav-item"
                            data-tip={msgObj.home}
                            data-for="home">
                            {!this.state.sidebarCollapse ?
                              <Link to="Home">
                                <img className="logoNavbar" src={this.state.image}></img>
                              </Link>
                              :
                              <Link to="/Home">
                                <i className='fas fa-home'></i></Link>
                            }
                          </li>
                          <ReactTooltip
                            place="top"
                            id="home" />
                        </ul>
                        <ul className="nav navbar-nav mx-auto">
                          {/* <li className="nav-item item">
                            <a
                              href="#"
                              name="import"
                              onClick={this.changedPage}
                              className={
                                this.state.page === "import"
                                  ? "active nav-link"
                                  : "nav-link"
                              }
                            >
                              Importación de Datos
                            </a>
                          </li>
                          <li className="nav-item item">
                            <a
                              href="#"
                              name="brandManagment"
                              onClick={this.changedPage}
                              className={
                                this.state.page === "brandManagment"
                                  ? "active nav-link"
                                  : "nav-link"
                              }
                            >
                              Creación de Marcas
                            </a>
                          </li>
                          <li className="nav-item item">
                            <a
                              href="#"
                              name="assignBrands"
                              onClick={this.changedPage}
                              className={
                                this.state.page === "assignBrands"
                                  ? "active nav-link"
                                  : "nav-link"
                              }
                            >
                              Asignación de Marcas
                            </a>
                          </li> */}
                        </ul>
                        <ul className="nav navbar-nav ml-auto">
                          <li className="nav-item">
                            <span>{user.firstName + " " + user.lastName}</span>
                            &nbsp;
                        </li>
                          {
                            user.role.name == 'Administrador Empresas'
                              ?
                              <li className="nav-item">
                                <UploadImage />
                              </li>
                              :
                              null
                          }
                          &nbsp;&nbsp;
                          <li className="nav-item">
                            <Link data-tip="Cerrar sesión" to="/">
                              <i
                                className="fas fa-sign-out-alt"
                                onClick={this.logOut}
                              ></i>
                            </Link>
                            <ReactTooltip />
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <br />
                    <h1>Gestión de Datos</h1>
                    <hr></hr>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {this.state.page === "brandManagment" ? <Brands /> : ""}
                    {this.state.page === "assignBrands" ? <AssignBrands /> : ""}
                    {this.state.page === "import" ? <Imports /> : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

  }
}
