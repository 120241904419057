import React, { Component } from "react";
import axios from "axios";
import { ToastsContainer, ToastsStore } from "react-toasts";

import ModalDelete from "../../ModalDelete/ModalDelete.jsx";
import msgObj from '../../../const.js'
import token from '../../../token.js'
import Table from "../../Table/Table";
import "./responseType.css";

export default class ResponseType extends Component {
  constructor() {
    super();
    this.state = {
      _id: "",
      _idDelete: "",
      responseTypeInput: "",
      name: "",
      description: "",
      columns: [{}],
      status: false,
      responseType: [],
      responseLevel: [],
      data: [],
      show: false,
      validate: true
    };
  }

  componentDidMount() {
    this.getResponseType();
    this.getResponseLevel();
  }

  isChecked = () => {
    this.setState({
      status: !this.state.status
    });
  };

  clearForm = () => {
    this.setState({
      _id: "",
      responseTypeInput: "",
      name: "",
      description: "",
      status: false
    });
  };

  handleChange = event => {
    const rules = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ,.\s]*$/g;
    const responseLevel = this.refs.name.value;
    const validate = rules.test(responseLevel);
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      validate
    });
  };

  showModal = id => {
    this.setState({
      _idDelete: id,
      show: !this.state.show
    });
  };

  getResponseType = () => {
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/responseTypes`,
        { headers: { 'x-auth-token': token } })
      .then(res =>
        this.setState({
          responseType: res.data.responseTypes
        })
      );
  };

  addResponseLevel = event => {
    event.preventDefault();
    if (this.state._id) {
      axios
        .put(
          `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/responseLevels/${this.state._id}`,
          {
            name: this.state.name,
            description: this.state.description,
            responseType: {
              _id: this.state.responseTypeInput
            },
            status: this.state.status
          },
          { headers: { 'x-auth-token': token } }
        )
        .then(res => {
          this.setState({
            _id: "",
            name: "",
            description: "",
            status: false
          })
          this.getResponseLevel();
          ToastsStore.success(res.data.msg);
        })

    } else {
      axios
        .post(
          `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/responseLevels`,
          {
            name: this.state.name,
            description: this.state.description,
            responseType: {
              _id: this.state.responseTypeInput
            },
            status: this.state.status
          },
          { headers: { 'x-auth-token': token } }
        )
        .then(res => {
          this.setState({
            name: "",
            description: "",
            status: false
          });
          this.getResponseLevel();
          ToastsStore.success(res.data.msg);
        })
        .catch(err => ToastsStore.error(err.response.data.msg));
    }
  };

  getResponseLevel = () => {
    this.setState({
      data: []
    });
    const data = Object.assign([], this.state.data);
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/responseLevels`,
        { headers: { 'x-auth-token': token } }
      )
      .then(res =>
        this.setState({
          responseLevel: res.data.responseLevels
        })
      )
      .then(res => {
        this.state.responseLevel.map(response => {
          data.push({
            _id: response._id,
            name: response.name,
            responseType: response.responseType.name
          });
        });
      })
      .then(res => {
        this.setState({
          data
        });
      });
  };

  editResponseLevel = id => {
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/responseLevels/${id}`,
        { headers: { 'x-auth-token': token } }
      )
      .then(res =>
        this.setState({
          responseTypeInput: res.data.responseLevel.responseType._id,
          _id: res.data.responseLevel._id,
          name: res.data.responseLevel.name,
          description: res.data.responseLevel.description,
          status: res.data.responseLevel.status
        })
      );
  };

  deleteResponseLevel = id => {
    axios
      .delete(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/responseLevels/${this.state._idDelete}`,
        { headers: { 'x-auth-token': token } }
      )
      .then(res => {
        this.getResponseLevel();
        ToastsStore.success(res.data.msg);
        this.setState({
          _idDelete: "",
          show: !this.state.show
        });
      })
      .catch(err => { ToastsStore.error(err.response.data.msg) });
  };

  render() {
    const { user } = this.props;
    const formatWithIconResponseLevel = (cell, row) => {
      return (
        <div>
          <i
            className="fas fa-edit"
            aria-hidden="true"
            onClick={() => this.editResponseLevel(row._id)}
          ></i>
          &nbsp;&nbsp;
          {user.role.name == 'Administrador Empresas'
            ? null
            :
            <i
              className="fa fa-trash"
              aria-hidden="true"
              onClick={() => this.showModal(row._id)}
            ></i>}
        </div>
      );
    };

    const columns = [
      {
        dataField: "name",
        text: "Nivel de Respuesta",
        sort: true
      },
      {
        dataField: "responseType",
        text: "Tipo de Respuesta",
        sort: true
      },
      {
        dataField: "deleteAction",
        text: "",
        formatter: formatWithIconResponseLevel
      }
    ];
    console.log(this.state.responseType);
    return (
      <div className="row">
        <div className="col-md-5">
          <form onSubmit={this.addResponseLevel} autocomplete="off">
            <div className="row form-group">
              <div className="col-md-12">
                <select
                  name="responseTypeInput"
                  ref="responseTypeInput"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.responseTypeInput}
                  required
                  selected
                >
                  <option value="" disabled>
                    Tipo de Respuesta
                  </option>
                  {this.state.responseType.map(response => {
                    return (
                      <option value={response._id} key={response._id}>
                        {response.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row form-group">
              {this.state.validate ? (
                ""
              ) : (
                  <label className="errmsg">
                    {msgObj.letterField}
                  </label>
                )}
              <div className="col-md-12">
                <input
                  type="text"
                  name="name"
                  placeholder="Nivel de Respuesta"
                  ref="name"
                  className={
                    this.state.validate
                      ? "form-control"
                      : " form-control invalid"
                  }
                  onChange={this.handleChange}
                  value={this.state.name}
                  required
                />
              </div>
            </div>

            <div className="row form-group">
              <div className="col-md-12">
                <input
                  type="text"
                  name="description"
                  placeholder="Descripción"
                  ref="description"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.description}
                />
              </div>
            </div>
            {user.role.name == 'Administrador Empresas'
              ? null
              :
              <div className="row form-group">
                <div className="col-md-12">
                  <div className="checkbox">
                    <label>
                      Activar
                    <input
                        type="checkbox"
                        name="switch"
                        onClick={this.isChecked}
                        checked={this.state.status}
                        ref="switch"
                        className="switch"
                      />
                      <div>
                        <div></div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>}
            <br />
            {this.state.renderInput !=
              "Seleccione Tipo o Nivel de Respuesta . . ." ? (
                <div className="col-md-12">
                  <div className="text-center">
                    {this.state._id != "" ? (
                      <button
                        type="button"
                        className="btn btn-form btn-light"
                        onClick={this.clearForm}
                      >
                        Limpiar
                    </button>
                    ) : null}
                    &nbsp;
                  {user.role.name == 'Administrador Empresas'
                      ? null
                      :
                      this.state.validate ? (
                        <button type="submit" className="btn btn-form btn-light">
                          Guardar
                  </button>
                      ) : (
                          <button
                            type="button"
                            className="btn btn-form btn-light"
                            disabled
                          >
                            Guardar
                  </button>
                        )}
                  </div>
                </div>
              ) : (
                ""
              )}
            <ToastsContainer store={ToastsStore} />
          </form>
          <ModalDelete
            show={this.state.show}
            onCancel={this.showModal}
            delete={this.deleteResponseLevel}
          />
        </div>

        <div className="col-md-7">
          <Table data={this.state.data} columns={columns} />
        </div>
      </div>
    );
  }
}
