import React, { Component } from "react";
import Modal from "react-bootstrap4-modal";
import { ToastsContainer, ToastsStore } from "react-toasts";

// import BASEPATH from '../../const.js'
import "./ModalDelete.css";
import axios from "axios";

export default class ModalDelete extends Component {
  render() {
    const { id, msgDeleteAll, msgError } = this.props;
    if (msgError == null) {
      return (
        <div className="Modal">
          <Modal
            visible={this.props.show}
            onClickBackdrop={this.props.onCancel}
          >
            <div className="modal-header">
              <h5 className="modal-title">Confirmación</h5>
            </div>
            <div className="modal-body">
              <p>¿Seguro desea eliminar el registro?</p>
              {msgDeleteAll ? msgDeleteAll : null}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn" onClick={this.props.delete}>
                Aceptar
              </button>
              <button
                type="button"
                className="btn"
                onClick={this.props.onCancel}
              >
                Cancelar
              </button>
            </div>
            <ToastsContainer store={ToastsStore} />
          </Modal>
        </div>
      );
    } else {
      return (
        <div className="Modal">
          <Modal
            visible={this.props.show}
            onClickBackdrop={this.props.onCancel}
          >
            <div className="modal-header">
              <h5 className="modal-title">Error!</h5>
            </div>
            <div className="modal-body">{msgError}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn"
                onClick={this.props.onCancel}
              >
                Aceptar
              </button>
            </div>
          </Modal>
        </div>
      );
    }
  }
}
