import React, { Component } from "react";
import axios from "axios";
import { ToastsContainer, ToastsStore } from "react-toasts";
import ReactTooltip from 'react-tooltip';

import ModalDelete from "../../ModalDelete/ModalDelete.jsx";
import msgObj from '../../../const.js';
import token from '../../../token.js';
import Table from "../../Table/Table.jsx";

export default class Role extends Component {
  constructor() {
    super();
    this.state = {
      _id: "",
      _idDelete: "",
      name: "",
      description: "",
      level: "",
      _idPrivilege: "",
      _idPrivileges: [],
      privilege: "Seleccione privilegio...",
      privileges: [],
      privilegesBadget: [],
      roles: [],
      validate: true,
      show: false
    };
  }

  handleChange = event => {
    const rules = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/g;
    const roleName = this.refs.name.value;
    const validate = rules.test(roleName);
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      validate
    });
  };

  clearForm = () => {
    this.setState({
      _id: "",
      name: "",
      description: "",
      _idPrivilege: "",
      _idPrivileges: [],
      privilege: "Seleccione privilegio...",
      privileges: [],
      privilegesBadget: []
    });
  };

  showModal = id => {
    this.setState({
      _idDelete: id,
      show: !this.state.show
    });
  };

  getOptions = event => {
    const selectedIndex = event.target.options.selectedIndex;
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value,
        _idPrivilege: event.target.options[selectedIndex].getAttribute(
          "data-key"
        )
      },
      () => {
        this.addPrivilege();
      }
    );
  };

  componentDidMount() {
    this.getPrivileges();
    this.getRoles();
  }

  getPrivileges = () => {
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/privileges`, { headers: { 'x-auth-token': token } })
      .then(res =>
        this.setState({
          privileges: res.data.privileges
        })
      );
  };

  getRoles = () => {
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/roles`, { headers: { 'x-auth-token': token } })
      .then(res =>
        this.setState({
          roles: res.data.roles
        })
      );
  };

  addPrivilege = (index, e) => {
    if (this.state.privilege != "Seleccione privilegio...") {
      const privileges = Object.assign([], this.state.privilegesBadget);
      const _idPrivileges = Object.assign([], this.state._idPrivileges);
      const validatePrivilege = privileges.indexOf(this.state.privilege);

      if (validatePrivilege != -1) {
        ToastsStore.info("No puede crear privilegios duplicados");
      } else {
        privileges.push(this.state.privilege);
        _idPrivileges.push({
          _id: this.state._idPrivilege
        });
        this.setState({
          privilegesBadget: privileges,
          _idPrivileges
        });
      }
    } else {
      ToastsStore.info("Seleccione un privilegio");
    }
  };

  removePrivilege = event => {
    const privileges = Object.assign([], this.state.privilegesBadget);
    const _idPrivileges = Object.assign([], this.state._idPrivileges);
    const index = privileges.indexOf(event.target.id);
    const indexId = _idPrivileges.indexOf(event.target.id);
    privileges.splice(index, 1);
    _idPrivileges.splice(indexId, 1);
    this.setState({
      privilegesBadget: privileges,
      _idPrivileges
    });
  };

  addRole = event => {
    event.preventDefault();
    if (this.state._id) {
      axios
        .put(
          `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/roles/${this.state._id}`,
          {
            name: this.state.name,
            description: this.state.description,
            level: this.state.level,
            privileges: this.state._idPrivileges
          },
          { headers: { 'x-auth-token': token } }
        )
        .then(res => {
          this.setState({
            _id: "",
            name: "",
            description: "",
            level: "",
            privilege: "Seleccione privilegio...",
            _idPrivileges: [],
            privilegesBadget: []
          })

          this.getRoles();
          ToastsStore.success(res.data.msg);
        })

    } else {
      axios
        .post(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/roles`, {
          name: this.state.name,
          description: this.state.description,
          level: this.state.level,
          privileges: this.state._idPrivileges
        },
          { headers: { 'x-auth-token': token } })
        .then(res => {
          this.setState({
            name: "",
            description: "",
            level: "",
            privilege: "Seleccione privilegio...",
            _idPrivileges: [],
            privilegesBadget: []
          });
          this.getRoles();
          ToastsStore.success(res.data.msg);
        })
        .catch(err => {
          console.log(err);
          ToastsStore.error(err.response.data.msg);
        });
    }
  };

  editRole = id => {
    this.setState({
      privilegesBadget: [],
      _idPrivileges: []
    });

    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/roles/${id}`, { headers: { 'x-auth-token': token } })
      .then(res => {
        const privileges = Object.assign([], this.state.privilegesBadget);
        const _idPrivileges = Object.assign([], this.state._idPrivileges);
        console.log(res.data.role.privileges);
        res.data.role.privileges.map(privilege => {
          privileges.push(privilege.name);
          _idPrivileges.push({
            _id: privilege._id
          });
        });

        this.setState({
          _id: res.data.role._id,
          name: res.data.role.name,
          description: res.data.role.description,
          level: res.data.role.level,
          privilegesBadget: privileges,
          _idPrivileges
        });
      });
  };

  deleteRole = id => {
    axios
      .delete(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/roles/${this.state._idDelete},`, { headers: { 'x-auth-token': token } }
      )
      .then(res => {
        this.getRoles();
        ToastsStore.success(res.data.msg);
        this.setState({
          _idDelete: "",
          show: !this.state.show
        });
      })
      .catch(err => {
        ToastsStore.error(err.response.data.msg);
      });
  };

  render() {
    const { user } = this.props;
    console.log(user);
    const formatWithIcon = (cell, row) => {
      return (
        <div>
          <i
            data-tip={msgObj.update}
            data-for="update"
            className="fas fa-edit"
            aria-hidden="true"
            onClick={() => this.editRole(row._id)}></i>
          <ReactTooltip
            place="top"
            id="update" />
          &nbsp;&nbsp;
          {user.role.name == 'Administrador'
            ? null
            :
            <i
              className="fa fa-trash"
              data-tip={msgObj.delete}
              data-for="delete"
              aria-hidden="true"
              onClick={() => this.showModal(row._id)}></i>}
          <ReactTooltip
            place="top"
            id="delete" />
        </div>
      );
    };

    const columns = [
      {
        dataField: "name",
        text: "Rol",
        sort: true
      },
      {
        dataField: "description",
        text: "Descripción",
        sort: true
      },
      {
        dataField: "deleteAction",
        text: "",
        formatter: formatWithIcon
      }
    ];
    console.log(this.state.roles);
    return (
      <div className="row">
        <div className="col-md-4">
          <form onSubmit={this.addRole} autocomplete="off">
            <div className="row form-group">
              {this.state.validate ? (
                ""
              ) : (
                  <label className="errmsg">
                    {msgObj.letterField}
                  </label>
                )}
              <div className="col-md-12">
                <input
                  type="text"
                  name="name"
                  placeholder="Rol"
                  ref="name"
                  className={
                    this.state.validate
                      ? "form-control"
                      : " form-control invalid"
                  }
                  onChange={this.handleChange}
                  value={this.state.name}
                  required
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-12">
                <textarea
                  name="description"
                  placeholder="Descripción"
                  ref="description"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.description}
                />
              </div>
            </div>

            <div className="row form-group">
              <div className="col-md-12">
                <select
                  name="level"
                  ref="level"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.level}
                  required
                  selected
                >
                  <option value="" disabled>
                    Seleccione nivel...
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
              </div>
            </div>

            <div className="row form-group">
              <div className="col-md-12">
                <select
                  name="privilege"
                  ref="privilege"
                  className="form-control"
                  onChange={this.getOptions}
                  value={this.state.privilege}
                  required
                >
                  <option value="Seleccione privilegio..." disabled>
                    Seleccione privilegio...
                  </option>
                  {this.state.privileges.map(privilege => {
                    return (
                      <option
                        key={privilege._id}
                        value={privilege.name}
                        data-key={privilege._id}
                      >
                        {privilege.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                {this.state.privilegesBadget.map(privilege => {
                  return (
                    <span
                      className="badge badge-pill badge-secondary badge-outlined"
                      onClick={this.removePrivilege}
                      id={privilege}
                    >
                      {privilege} X
                    </span>
                  );
                })}
              </div>
            </div>
            <br />
            <div className="col-md-12">
              <div className="text-center">
                {this.state._id != "" ? (
                  <button
                    type="button"
                    className="btn btn-form btn-light"
                    onClick={this.clearForm}
                  >
                    Limpiar
                  </button>
                ) : null}
                &nbsp;
                {(user.role.name == 'Administrador' || user.role.name == 'Administrador Empresas')
                  ? null
                  :
                  <button
                    type="submit" className="btn btn-form btn-light"
                    className="btn btn-form btn-light"

                  >
                    Guardar
                  </button>
                }
              </div>
            </div>
            <ToastsContainer store={ToastsStore} />
          </form>
          <ModalDelete
            show={this.state.show}
            onCancel={this.showModal}
            delete={this.deleteRole}
          />
        </div>
        <div className="col-md-8">
          <Table data={this.state.roles} columns={columns} />
        </div>
      </div>
    );
  }
}
