const msgObj = {
    numberField: "Debe ingresar solo caracteres númericos.",
    letterField: "Debe ingresar solo caracteres alfabéticos.",
    alphanumericField: "Debe ingresar solo caraceres alfanuméricos.",
    emailField: "Debe ingresar el formato de correo válido.",
    passwordField: "Longitud de 6 a 12 caracteres alfanuméricos con al menos una mayúscula y un número.",
    update: "Editar",
    delete: "Eliminar",
    home: "Inicio",
    updatePrivileges: "Actualizar Privilegios",
    duplicate: "Duplicar",
    active: "Activar",
    inactive: "Desactivar",
    enable: "Activo",
    disable: "Inactivo",
    visualize: "Visualizar"

}
module.exports = msgObj;