import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { ToastsContainer, ToastsStore } from "react-toasts";
import BlockUi from "react-block-ui";
import axios from "axios";


import logo from "../../SMART.png";
// import BASEPATH from '../../const.js';
import "./LoginEpa.css";

export default class LoginEpa extends Component {

  constructor() {
    super();
    

    this.state = {
      password: "",
      msg: "",
      loading: false,
      success: false,
      uri: "",
      id: "",
    };
  }

  componentDidMount() {}

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = () => {


    this.setState({
      loading: true,
    });
    axios
      .post(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/loginInstrument`,
        {
          password: this.state.password,
        }
      )
      .then((res) => {
        console.log(res);
        this.setState({
          loading: false,
          password: "",
        });

        //removeCookie('PHPSESSID');
        //console.log("alg: ", document.cookie); // Pacman
        //document.cookie.split(";").forEach(function(c) {
         // document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        //});

        //var newtab = window.open(res.data.uri, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
        //var newtab = window.open(res.data.uri, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
        var newUrl = res.data.uri.concat('&newtest=Y');

        //console.log("newUrl: ", newUrl);

        var newtab  = window.open(newUrl);

        //window.open(res.data.uri);
        //newtab.location.reload(true);

        //this.cookies.set('PHPSESSID', '');

        //window.location.href = window.location.href;
        // or
        //window.location.replace('');

        setTimeout(() => {
          //newtab.location.close();
          //window.open(res.data.uri);
          //newtab.location.reload();

        }, 5000);
          
        /*axios.getUri(res.data.uri)
          .then(res => {
            console.log(res);
          })
          .catch(
            error => {

            }
          );*/

        //console.log("newTab: ", newtab);
        //newtab.opener.location.reload();
        //window.open(res.data.uri);
       
      })
      .catch((err) => {
        this.setState({
          loading: false,
          msg: err.response.data.msg,
          error: true,
          password: "",
          msg: "",
          uri: "",
          success: false,
        });
        ToastsStore.error(err.response.data.msg);
      });
  };

  render() {
    return (
      <BlockUi tag="div" blocking={this.state.loading}>
        <div className="Login">
          <div className="container">
            <div
              className="row justify-content-center align-items-center"
              id="center"
            >
              <div className="col-sm-5">
                <div className="card">
                  <div className="card-header text-center">
                    <img src={logo}></img>
                  </div>
                  <div className="card-body">
                    {/* <form className="form"> */}
                    <div className="form-group row">
                      <div className="col-sm-8 text-center input">
                        <input
                          type="text"
                          className="form-control"
                          ref="password"
                          name="password"
                          value={this.state.password}
                          onChange={this.handleChange}
                          placeholder="Contraseña"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-8 input">
                        {this.state.success ? (
                          <p
                            style={{ color: "#75757B" }}
                          >{`Instrumento: ${this.state.msg}`}</p>
                        ) : null}

                        {/* <input
                          type="msg"
                          className="form-control"
                          ref="msg"
                          name="msg"
                          value={this.state.msg}
                          placeholder="Bienvenido a la encuesta:"
                        />*/}
                      </div>
                    </div>
                    <div className="form-group text-center">
                      {/* <Link to="/recoverPassword">Recuperar contraseña</Link> */}
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-light"
                        disabled={this.state.password === "" ? true : false}
                        onClick={this.handleSubmit}
                      >
                        Entrar
                      </button>
                    </div>
                    <ToastsContainer store={ToastsStore} />
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BlockUi>
    );
  }
}
