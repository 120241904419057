import React, { Component } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

export default class Table extends Component {

  render() {
    const { SearchBar } = Search;
    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      showTotal: false,
      sizePerPageList: !this.props.page ?
        [
          {
            text: '5',
            value: 5
          },
          {
            text: '10',
            value: 10
          },
          {
            text: '15',
            value: 15
          }
        ] :
        null
    }
    if (this.props.page == 'import') {
      let styles = {
        color: "#757875"
      }
      return (
        <ToolkitProvider
          keyField="id"
          data={this.props.data}
          columns={this.props.columns}
          search
        >
          {
            props => (
              <div>
                <SearchBar {...props.searchProps} />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;
                <strong className="title" style={styles}>Seleccionar/Deseleccionar todos</strong>
                &nbsp;
                <i
                  className="fas fa-circle request-active "
                  aria-hidden="true"
                  onClick={() => {
                    this.props.changed();
                  }}
                ></i>
                <BootstrapTable
                  {...props.baseProps}
                  bordered={false}
                  hover
                  pagination={paginationFactory(options)}
                />
              </div>
            )
          }
        </ToolkitProvider>
      )
    } else {
      return (
        <BootstrapTable
          keyField='id'
          data={this.props.data}
          columns={this.props.columns}
          bordered={false}
          hover
          pagination={paginationFactory(options)}
        />
      )
    }

  }
}
