import React, {Component} from 'react';
import axios from 'axios';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import ReactTooltip from 'react-tooltip';

import ModalDelete from '../../ModalDelete/ModalDelete.jsx'
import msgObj from '../../../const.js';
import token from '../../../token.js'
import Table from '../../Table/Table';
import './MeasurementScale.css';

export default class MeasumerentScale extends Component {

    constructor(){
        super();
        this.state = {
            _id:'',
            _idScaleValue:'',
            _idDelete:'',
            renderInput:'Seleccione Escala o Valor...',
            name:'',
            description:'',
            scale:'',
            responseType:'',
            responseLevel : "",
            effect:'',
            value:'',
            status:false,
            columns:[{}],
            scales:[],
            scaleValues:[],
            responseTypes:[],
            responseLevels:[],
            data:[],
            dataL:[],
            dataScales:[],
            validate:true,
            show:false,
            idCompare:''
        }
    }

    componentDidMount(){
        this.getScales();
        this.getScalesValues();
        this.getResponsTypes();
        this.getResponsLevels();
    }

    isChecked = () => {
        this.setState({
            status:!this.state.status
        })
    }

    showModal = (id) => {
        this.setState({
            _idDelete:id,
            show:!this.state.show,
        })
    }

    clearForm = () => {
        this.setState({
            _idScaleValue:'',
            _id:'',
            name:'',
            description:'',
            scale:'',
            responseType:'',
            responseLevel : "",
            effect:'',
            value:'',
            status:false,
        })
    }

   
    handleChange = (event) => {
        const rules = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ_@./#&+-,\s]*$/g
        const scaleName = this.state.name;
        const validate = rules.test(scaleName);
        const {name, value} = event.target;
        this.setState({
            [name]:value,
            validate
        })
        
    }

    handleConsult = (event) => {
        const {name, value} = event.target;
        this.setState({
            [name]:value
        }, () => {
            axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/scales/${this.state.scale}`,
            {headers: {'x-auth-token': token}})
            .then(res => {
                this.setState({
                    idCompare:res.data.scale.responseType._id
                })
            })
        })
    }

    addScale = (event) => {
        event.preventDefault();
        if(this.state._id){
            axios.put(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/scales/${this.state._id}`, {
                name:this.state.name,
                responseType:{
                    "_id":this.state.responseType,
                },
                description:this.state.description,
                status:this.state.status,
            },{headers: {'x-auth-token': token}})
            .then(res => {
                this.setState({
                    _id:'',
                    name:'',
                    responseType:'',
                    description:'',
                    status:false
                })
                this.getScales();
                ToastsStore.success(res.data.msg);
            })
            .catch(err => {
                ToastsStore.error(err.response.data.msg)
            })
            
                
        }else{
            axios.post(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/scales`, {
            name:this.state.name,
            responseType:{
                "_id":this.state.responseType,
            },
            description:this.state.description,
            status:this.state.status,
        },{headers: {'x-auth-token': token}})
        .then(res => {
            this.setState({
                name:'',
                responseType:'',
                description:'',
                status:false
            })
            this.getScales();
            ToastsStore.success(res.data.msg);
        })
        .catch(err => ToastsStore.error(err.response.data.msg));
        }
        
    }
    
    addScaleValue = (event) => {
        event.preventDefault();
        if(this.state._idScaleValue){
            const objRequest = {
                scale: {
                    "_id":this.state.scale
                },
                responseLevel:{
                    "_id":this.state.responseLevel
                },
                effect:this.state.effect,
                name:this.state.value,
                status:this.state.status,
            }
            axios.put(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/scaleValues/${this.state._idScaleValue}`, objRequest,{headers: {'x-auth-token': token}})
            .then(res => {
                this.setState({
                    _idScaleValue:'',
                    scale:'',
                    responseLevel:'',
                    effect:'',
                    name:'',
                    value:'',
                    status:false
                })
                this.getScalesValues();
                ToastsStore.success(res.data.msg)
            })
        }else{
            const objRequest = {
                scale: {
                    "_id":this.state.scale
                },
                responseLevel:{
                    "_id":this.state.responseLevel
                },
                effect:this.state.effect,
                name:this.state.value,
                status:this.state.status,
            }
            axios.post(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/scaleValues`, objRequest,{headers: {'x-auth-token': token}})
            .then(res => {
                this.setState({
                    scale:'',
                    responseLevel:'',
                    effect:'',
                    name:'',
                    value:'',
                    status:false
                })
                this.getScalesValues();
                ToastsStore.success(res.data.msg);
            })
        .catch(err => {
            ToastsStore.error(err.response.data.msg)
        });
        }
        
    }

    getScales = () => {
        this.setState({
            dataScales:[],
            dataL:[]
        })
        const dataScales = Object.assign([], this.state.dataScales)
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/scales`,{headers: {'x-auth-token': token}})
        .then(res => {
            this.setState({
                scales:res.data.scales
            })
            this.state.scales.map(scale => {
                dataScales.push({
                    "_id":scale._id,
                    "name":scale.name,
                    "respondeType":scale.responseType.name,
                    "status":scale.status
                })
            })
            this.setState({
                dataScales
            })
        });
        
        const dataL = Object.assign([], this.state.dataL)
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/scales/active`,{headers: {'x-auth-token': token}})
        .then(res => {
            this.setState({
                dataL:res.data.scales
            })
            this.state.dataL.map(scale => {
                if(scale.status){
                    dataL.push({
                        "_id":scale._id,
                        "name":scale.name,
                        "respondeType":scale.responseType.name
                    })
                }
            })
            this.setState({
                dataL
            })
        });
    }

    getScalesValues = () => {
        this.setState({
            data:[]
        })
        const data = Object.assign([], this.state.data)
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/scaleValues`,{headers: {'x-auth-token': token}})
        .then(res => this.setState({
            scaleValues:res.data.scaleValues
        }))
        .then(res => this.state.scaleValues.map(scaleValue => {
            data.push({
                "_id":scaleValue._id,
                "name" : scaleValue.name,
                "responseLevel":scaleValue.responseLevel.name,
                "effect":scaleValue.effect,
                "status":scaleValue.status
            })
        })
        )
        .then(res => this.setState({
            data
        }))
    }

    getResponsTypes = () => {
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/responseTypes`,{headers: {'x-auth-token': token}})
        .then(res => this.setState({
            responseTypes:res.data.responseTypes
        }));
    }

    getResponsLevels = () => {
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/responseLevels/active`,{headers: {'x-auth-token': token}})
        .then(res => this.setState({
            responseLevels:res.data.responseLevels
        }));
    }

    editScale = (id) => {
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/scales/${id}`,{headers: {'x-auth-token': token}})
        .then(res => this.setState({
            _id:res.data.scale._id,
            responseType:res.data.scale.responseType._id,
            name:res.data.scale.name,
            description:res.data.scale.description,
            status:res.data.scale.status
        }))
    }

    deleteScale = (id) => {
        axios.delete(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/scales/${this.state._idDelete}`,{headers: {'x-auth-token': token}})
        .then(res => {
            this.getScales();
            this.getScalesValues();
            ToastsStore.success(res.data.msg);
            this.setState({
                _idDelete:'',
                show:!this.state.show
            })
        })
        .catch(err => {
            ToastsStore.error(err.response.data.msg)
        });
    }

    editScaleValue = (id) => {
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/scaleValues/${id}`,{headers: {'x-auth-token': token}})
        .then(res => this.setState({
            _idScaleValue:res.data.scaleValue._id,
            scale:res.data.scaleValue.scale._id,
            responseLevel:res.data.scaleValue.responseLevel._id,
            effect:res.data.scaleValue.effect,
            name:res.data.scaleValue.name,
            value:res.data.scaleValue.name,
            status:res.data.scaleValue.status
        }))
    }

    deleteScaleValue = (id) => {
        axios.delete(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/scaleValues/${this.state._idDelete}`,{headers: {'x-auth-token': token}})
        .then(res => {
            this.getScalesValues();
            ToastsStore.success(res.data.msg);
            this.setState({
                _idDelete:'',
                show:!this.state.show
            })
        })
        .catch(err => {
            ToastsStore.error(err.response.data.msg)
        });
    }

    render(){
        const {user} = this.props;
        const formatWithIconScale = (cell,row) => {
            return(
                <div>
                    <i  
                        data-tip={msgObj.update}
                        data-for="update" 
                        className="fas fa-edit" 
                        aria-hidden="true" 
                        onClick = { () => this.editScale(row._id)}></i>
                        <ReactTooltip 
                            place="top"
                            id="update"/>
                    &nbsp;&nbsp;
                    {user.role.name == 'Administrador Empresas'
                        ?   null
                        : 
                        <i 
                            className="fa fa-trash"
                            data-tip={msgObj.delete}
                            data-for="delete"   
                            aria-hidden="true" 
                            onClick = { () => this.showModal(row._id)}></i> }
                            <ReactTooltip 
                                place="top"
                                id="delete"/>
                </div>
            )
        }

        const formatWithIconScaleValue = (cell,row) => {
            return(
                <div>
                    <i  
                        data-tip={msgObj.update}
                        data-for="update" 
                        className="fas fa-edit" 
                        aria-hidden="true" 
                        onClick = { () => this.editScaleValue(row._id)}></i>
                        <ReactTooltip 
                            place="top"
                            id="update"/>
                    &nbsp;&nbsp;
                    {user.role.name == 'Administrador Empresas'
                        ?   null
                        : 
                            <i 
                            className="fa fa-trash"
                            data-tip={msgObj.delete}
                            data-for="delete"   
                            aria-hidden="true" 
                            onClick = { () => this.showModal(row._id)}></i> }
                            <ReactTooltip 
                                place="top"
                                id="delete"/>
                </div>
            )
        }

        const formatWithStatus = (cell,row) => {
            return(
                <div>
                    {row.status 
                    ? <i 
                        className="fas fa-circle status-active" 
                        aria-hidden="true"
                        data-tip={msgObj.enable}
                        data-for="enable"
                        >
                        </i> 
                    : <i 
                        className="fas fa-circle status-inactive" 
                        aria-hidden="true"
                        data-tip={msgObj.disable}
                        data-for="disable"></i> 
                    }
                    <ReactTooltip 
                        place="top"
                        id={row.status ? "enable" : "disable"}/>
                </div>
            )
        }

        const columns1 = [
            {
            dataField: 'status',
            text: '',
            formatter: formatWithStatus
            },
            {
            dataField: 'name',
            text: 'Escala de Medición',
            sort:true
            }, 
            {
            dataField: 'respondeType',
            text: 'Tipo de Respuesta'
            },
            {
            dataField:'deleteAction',
            text:'',
            formatter: formatWithIconScale
        }]

        const columns2 = [
            {
            dataField: 'status',
            text: '',
            formatter: formatWithStatus
            }, 
            {
            dataField: 'name',
            text: 'Valor de Escala',
            sort:true
            }, 
            {
            dataField: 'responseLevel',
            text: 'Nivel de Respuesta',
            sort:true
            },
            {
            dataField: 'effect',
            text: 'Efecto'
            },
            {
            dataField:'deleteAction',
            text:'',
            formatter: formatWithIconScaleValue
            }]
        
        return(
            <div className="row">
                <div className="col-md-5">
                    <form onSubmit={this.state.renderInput == 1 ? this.addScale : this.addScaleValue} autocomplete="off">
                        <div className="row form-group">
                            <div className="col-md-12">
                            <select
                                name = "renderInput"
                                ref = "renderInput"
                                className = "form-control"
                                onChange={this.handleChange}
                                value = {this.state.renderInput}
                                required>
                                    <option value="Seleccione Escala o Valor..."
                                     disabled>Seleccione Escala o Valor...</option>
                                    <option value="1">Escala de Medición</option>
                                    <option value="2">Valor de la escala</option>
                                </select>
                            </div>
                        </div>

                        {this.state.renderInput == 1 ? 
                        <div className="row form-group">
                             <div className="col-md-12">
                                 <select
                                 name = "responseType"
                                 ref = "responseType"
                                 className = "form-control"
                                 onChange={this.handleChange}
                                 value = {this.state.responseType}
                                 required
                                 selected>
                                    <option value=""
                                     disabled>Tipo de Respuesta</option>
                                    {this.state.responseTypes.map(responseType => {
                                        return(
                                            <option value={responseType._id}>
                                                {responseType.name}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                         </div> : ''}

                       {this.state.renderInput == 1 ? 
                       <div className="row form-group">
                           {this.state.validate ?  '' : <label className="errmsg">{msgObj.letterField}</label>}
                            <div className="col-md-12">
                                <input 
                                type = "text"
                                name = "name"
                                placeholder = "Escala"
                                ref = "name"
                                className = {this.state.validate ? "form-control"  : " form-control invalid"}
                                onChange={this.handleChange}
                                value={this.state.name}
                                required
                                />
                            </div>
                        </div> : ''}
                        {this.state.renderInput == 1 ? 
                        <div className="row form-group">
                            <div className="col-md-12">
                                    <textarea 
                                type = "text"
                                name = "description"
                                placeholder = "Descripción"
                                ref = "description"
                                className = "form-control"
                                onChange={this.handleChange}
                                value={this.state.description}
                                />
                            </div>
                        </div> : ''}

                        {this.state.renderInput == 2 ? 
                        <div className="row form-group">
                            <div className="col-md-12">
                                <select
                                name = "scale"
                                ref = "scale"
                                className = "form-control"
                                onChange={this.state._idScaleValue == false ? this.handleConsult : null}
                                value = {this.state.scale}
                                selected
                                required
                                >
                                     <option value=""
                                     disabled>Escala</option>
                                    {this.state.dataL.map(scale => {
                                        return(
                                            <option value={scale._id}
                                            key={scale._id}>
                                                {scale.name}
                                            </option>
                                        )
                                    })}
                                </select>
                             </div>
                        </div> : ''}

                        {this.state.renderInput == 2 ? 
                        <div className="row form-group">
                            <div className="col-md-12">
                                <select
                                name = "responseLevel"
                                ref = "responseLevel"
                                className = "form-control"
                                onChange={this.handleChange}
                                value = {this.state.responseLevel}
                                selected
                                required>
                                     <option value=""
                                     disabled>Nivel de Respuesta</option>
                                    {this.state.responseLevels.map(response => {
                                        if(response.responseType._id == this.state.idCompare){
                                            return(
                                                <option value={response._id}
                                                key={response._id}>
                                                    {response.name}
                                                </option>
                                            )
                                        }
                                    })}
                                </select>
                             </div>
                        </div> : ''}
                        
                    {this.state.renderInput == 2 ? 
                        <div className="row form-group">
                            <div className="col-md-12">
                                <select
                                name = "effect"
                                ref = "effect"
                                className = "form-control"
                                onChange={this.state._idScaleValue == false ? this.handleChange : null}
                                value = {this.state.effect}
                                selected
                                required
                                >
                                   <option value=""
                                   disabled>Efecto</option>
                                   <option value="+">+</option>
                                   <option value="-">-</option>
                                </select>
                            </div>
                        </div> : ''}

                    {this.state.renderInput == 2 ? 
                        <div className="row form-group">
                            <div className="col-md-12">
                                <input 
                                type='number'
                                name = "value"
                                placeholder = "Valor"
                                ref = "value"
                                className = "form-control"
                                value={this.state.value}
                                onChange={this.handleChange}
                                required
                                max="10"
                                min="0"
                                />
                            </div>
                        </div> : ''}
                        {user.role.name == 'Administrador Empresas'
                        ? null 
                        :
                        <div className="row form-group">
                            <div className="col-md-12">
                                <div className="checkbox">
                                    <label>
                                        Activar
                                        <input 
                                        type="checkbox"
                                        name="switch"
                                        onClick={this.isChecked}
                                        checked={this.state.status}
                                        ref="switch"
                                        className="switch"
                                         />
                                         <div>
                                            <div></div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>}
                    <br />
                    {this.state.renderInput == 1 ? <div className="col-md-12">
                            <div className="text-center">
                                {this.state._id != '' ? 
                                    <button
                                    type="button"
                                    className="btn btn-form btn-light"
                                    onClick = {this.clearForm}
                                    >Limpiar</button> : null}
                                    &nbsp;
                                    {user.role.name == 'Administrador Empresas'
                                    ? null 
                                    :
                                    this.state.validate ? (
                                    <button type="submit" className="btn btn-form btn-light">
                                        Guardar
                                    </button>
                                    ) : (
                                    <button
                                        type="button"
                                        className="btn btn-form btn-light"
                                        disabled
                                    >
                                        Guardar
                                    </button>
                                    )}
                                </div>
                            </div> : ''}

                        {this.state.renderInput == 2 ? <div className="col-md-12">
                            <div className="text-center">
                                {this.state._idScaleValue != '' ? 
                                    <button
                                    type="button"
                                    className="btn btn-form btn-light"
                                    onClick = {this.clearForm}
                                    >Limpiar</button> : null}
                                    &nbsp;
                                
                                    {user.role.name == 'Administrador Empresas'
                                        ? null 
                                        :
                                            <button type="submit" className="btn btn-form btn-light">
                                                Guardar
                                            </button>
                                        }
                                    </div>
                                </div> : ''}
                        <ToastsContainer store={ToastsStore}/>
                </form>
                <ModalDelete show={this.state.show}
                 onCancel={this.showModal} 
                 delete={this.state.renderInput == 1 ? this.deleteScale : this.deleteScaleValue} 
                 msgDeleteAll={this.state.renderInput == 1 ? 
                 <p>De continuar, se eliminarán todos los registros asociados.</p>
                 : null}/>
            </div>
                {this.state.renderInput == 1 ? 
                    <div className="col-md-7">
                        <Table 
                        data={this.state.dataScales}
                        columns={columns1}/>
                    </div>: ''}
                    {this.state.renderInput == 2 ? 
                    <div className="col-md-7">
                        <Table 
                        data={this.state.data}
                        columns={columns2}/>
                    </div>: ''}
            </div>
        )
    }
}