
import React, { Component } from "react";
import axios from "axios";
import { ToastsContainer, ToastsStore } from "react-toasts";
import Table from "../../Table/Table";
import BlockUi from 'react-block-ui';
import ReactTooltip from 'react-tooltip';
import ReactFileReader from 'react-file-reader';

import token from "../../../token.js";
import './Imports.css'

export default class Imports extends Component {
  constructor() {
    super();
    this.state = {
      administratorGroup: "Grupo Administrador",
      organization: "Organización",
      UOCompany: "Departamento",
      nameFile: "",
      adminGroups: [],
      organizations: [],
      collaborators: [],
      data: [],
      loading: false,
      lengthSend: '',
      lengthChanged: '',
      Allstate: true,
      uploadFile: false,
      image: '',
      filecode: ''

    };

  }

  componentDidMount() {
    this.getAdminGroup();
    this.getOrganizations();
    this.getCollaborators();
    this.getLogo();
  }

  handleFiles = (files) => {

    var user = JSON.parse(localStorage.getItem('user'));

    console.log(files);
    console.log(files.fileList[0].name);
    this.setState({
      nameFile: files.fileList[0].name,
      filecode: files.base64
    });
  }

  logOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem("image");
  }

  handleChanged = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
    if (name == 'organization') {
      axios
        .get(
          `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/organizations/${value}`,
          { headers: { "x-auth-token": token } }
        )
        .then(res => {
          if (!res.data.organization.isSIP) {

            console.log(res.data.organization.name + ' ' + res.data.organization.isSIP);
            this.setState({
              uploadFile: true
            });
          } else {
            this.setState({
              uploadFile: false
            });
          }

          console.log(this.state.uploadFile)
        });
    }
  }

  getLogo = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/organizations`, { headers: { 'x-auth-token': token } })
      .then(res => {
        this.setState({
          organizations: res.data.organizations
        });
        this.state.organizations.map(organization => {
          if (organization._id == user.company.organization._id) {
            this.setState({
              image: organization.logo.imagecode
            });
          }
        });
      });
  };

  getOrganizations = () => {
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/organizations`,
        { headers: { "x-auth-token": token } }
      )
      .then(res => {
        console.log(res.data);
        this.setState({
          organizations: res.data.organizations
        });
      });
  };

  getAdminGroup = () => {
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/admingroups`, {
        headers: { "x-auth-token": token }
      })
      .then(res => {
        console.log(res.data);
        this.setState({ adminGroups: res.data.adminGroups });
      });
  };

  importData = event => {
    event.preventDefault();
    this.setState({
      loading: true
    });
    if (this.state.uploadFile) {
      axios.post(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/uploadCollaborators`, {
        name: this.state.nameFile,
        excelCode: this.state.filecode,
        _id: this.state.organization
      })
        .then(res => {
          var nameFileUpload = res.data.nameFileUpload;
          console.log(nameFileUpload);
          axios
            .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/import/collaborators/${this.state.organization}/${this.state.administratorGroup}/file/${nameFileUpload}`)
            .then(res => {
              console.log(res)
              console.log('*********************Respondio********************');
              this.setState({
                loading: false,
                administratorGroup: "Grupo Administrador",
                organization: "Organización",
                nameFile: "",
                uploadFile: false,
                filecode: ''
              })
              this.getCollaborators();
              ToastsStore.success("El archivo ha sido cargado con éxito.");

              //window.open(res.config.url);
            })
            .catch(err => {
              this.setState({
                loading: false,
                administratorGroup: "Grupo Administrador",
                organization: "Organización",
                nameFile: "",
                uploadFile: false,
                filecode: ''
              })
              ToastsStore.error(err.response.data.msg);
            });
        })
        .catch(err => {
          this.setState({
            loading: false,
            administratorGroup: "Grupo Administrador",
            organization: "Organización",
            nameFile: "",
            uploadFile: false,
            filecode: ''
          })
          ToastsStore.error(err.response.data.msg);
        });
    } else {
      axios
        .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/import/collaborators/${this.state.organization}/${this.state.administratorGroup}`)
        .then(res => {
          this.setState({
            loading: false,
            administratorGroup: "Grupo Administrador",
            organization: "Organización",
            nameFile: "",
            uploadFile: false,
            filecode: ''
          })
          //ToastsStore.success("El archivo ha sido descargado con éxito.");
          this.getCollaborators();
          ToastsStore.success("Los colaboradores han sido importados con éxito.");
          // window.open(res.config.url);
        })
        .catch(err => {
          this.setState({
            loading: false,
            administratorGroup: "Grupo Administrador",
            organization: "Organización",
            nameFile: "",
            uploadFile: false,
            filecode: ''
          })
          ToastsStore.error(err.response.data.msg);
        });
    }


  }

  getCollaborators = () => {
    const collSendArray = [];
    const collSendChanged = []
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/all/collaborators`, {
        headers: { "x-auth-token": token }
      })
      .then(res => {
        this.setState({
          collaborators: res.data.coll
        })
        this.state.collaborators.map(coll => {
          if (coll.sendMail) {
            collSendArray.push(coll);
          }
          if (coll.sendPrincipal) {
            collSendChanged.push(coll);
          }
        })
        this.setState({
          lengthSend: collSendArray.length,
          lengthChanged: collSendChanged.length
        })
      })
      .catch(err => {
        ToastsStore.error(err.response.data.msg);
      });
  }

  changedStatus = id => {
    axios.put(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/collaborators/${id}/sendMail`, {}, {
      headers: { "x-auth-token": token }
    })
      .then(res => {
        this.getCollaborators()
      }
      )
      .catch(err => console.log(err))
  }

  changedEmail = id => {
    axios.put(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/collaborators/${id}/sendPrincipal`, {}, {
      headers: { "x-auth-token": token }
    })
      .then(res => this.getCollaborators())
      .catch(err => ToastsStore.error(err.response.data.msg))
  }

  changedAllStatus = () => {
    axios.put(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/collaborators/sendAll/${this.state.Allstate}`, {}, {
      headers: { "x-auth-token": token }
    })
      .then(res => {
        this.setState({
          Allstate: !this.state.Allstate
        }, () => {
          this.getCollaborators();
        })

      })
      .catch(err => ToastsStore.error(err.response.data.msg))
  }


  render() {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');
    const formatWithStatus = (cell, row) => {
      return (
        <div>
          {row.sendMail
            ? <i
              className="fas fa-circle request-active "
              aria-hidden="true"
              data-tip=""
              data-for="enable"
              onClick={() => {
                this.changedStatus(row._id);
              }}
            >
            </i>
            : <i
              className="fas fa-circle request-inactive "
              aria-hidden="true"
              data-tip=""
              data-for="disable"
              onClick={() => {
                this.changedStatus(row._id);
              }}
            ></i>
          }
          <ReactTooltip
            place="top"
            id={row.sendMail ? "enable" : "disable"} />
        </div>
      )
    }

    const formatWithEmail = (cell, row) => {
      return (
        <div>
          {row.sendPrincipal
            ? <i
              className="fas fa-circle request-active "
              aria-hidden="true"
              data-tip=""
              data-for="enable"
              onClick={() => {
                this.changedEmail(row._id);
              }}
            >
            </i>
            : <i
              className="fas fa-circle request-inactive "
              aria-hidden="true"
              data-tip=""
              data-for="disable"
              onClick={() => {
                this.changedEmail(row._id);
              }}></i>
          }
          <ReactTooltip
            place="top"
            id={row.sendPrincipal ? "enable" : "disable"} />
        </div>
      )
    }

    const columns1 = [
      {
        dataField: 'organization',
        text: 'Organización',
      },
      {
        dataField: 'unidad',
        text: 'Área a la que reporta',
        sort: true
      },
      {
        dataField: 'name',
        text: 'Nombre'
      },
      {
        dataField: 'email',
        text: 'Correo Principal',
      },
      {
        dataField: 'sendMail',
        text: 'Enviar Encuesta',
        formatter: formatWithStatus,
        sort: true
      },
      {
        dataField: 'sendPrincipal',
        text: 'Enviar a correo principal',
        formatter: formatWithEmail,
        sort: true
      }]
    return (
      <BlockUi tag="div" blocking={this.state.loading}>
        <div className="row">
          <div className="col-md-3">
            {user.role.name == "Administrador" || user.role.name == "Sistemas"
              ?
              <form onSubmit={this.importData}>
                <div className="row form-group">
                  <div className="col-md-12">
                    <select
                      name="administratorGroup"
                      ref="administratorGroup"
                      className="form-control"
                      onChange={this.handleChanged}
                      value={this.state.administratorGroup}
                      required
                    >
                      <option value="Grupo Administrador" disabled>
                        Grupo Administrador
                  </option>
                      {this.state.adminGroups.map(adminGroup => {
                        return (
                          <option key={adminGroup._id} value={adminGroup._id}>
                            {adminGroup.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                    <select
                      name="organization"
                      ref="organization"
                      className="form-control"
                      onChange={this.handleChanged}
                      value={this.state.organization}
                      required
                    >
                      <option value="Organización" disabled>
                        Organización
                  </option>
                      {this.state.organizations.map(organization => {
                        if (organization.admingroup._id == this.state.administratorGroup) {
                          return (
                            <option key={organization._id} value={organization._id}>
                              {organization.name}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                </div>
                {this.state.uploadFile == true ?
                  <div className="row form-group">
                    <div className="col-md-12">

                      <ReactFileReader handleFiles={this.handleFiles} fileTypes={[".xlsx", ".xls"]} base64={true}>
                        <input
                          type="text2"
                          name="nameFile"
                          placeholder="Archivo"
                          ref="nameFile"
                          onChange={this.handleChange}
                          value={this.state.nameFile}
                          required
                          disabled
                        />&nbsp;&nbsp;
                    <label htmlFor="file-input">
                          <i
                            className="fas fa-arrow-up"
                            data-tip="Carga tu Archivo!"
                            data-for="upload-img"></i>
                          <ReactTooltip place="top"
                            id="upload-img" />
                        </label>
                      </ReactFileReader>
                    </div>
                  </div>
                  : ''}
                <br />
                <div className="col-md-12">
                  <div className="text-center">
                    <button type="submit" className="btn btn-form btn-light">
                      Importar
                </button>
                  </div>
                </div>
                <ToastsContainer store={ToastsStore} />
              </form>
              : null}
            <br />
            <br />
            <div className="col-md-12 length" >
              <div>
                <strong>Total Colaboradores:</strong> {this.state.collaborators.length}
              </div>
            </div>
            <div className="col-md-12 length">
              <div >
                <strong>Encuestas a enviar:</strong> {this.state.lengthSend}
              </div>
            </div>
            <div className="col-md-12 length">
              <div >
                <strong>Enviar a Principal: </strong>{this.state.lengthChanged}
              </div>
            </div>
            <div className="col-md-12 length">
              <div >
                <strong>Enviar al Administrador:</strong> {this.state.lengthSend - this.state.lengthChanged}
              </div>
              <br />
              <br />
              <div>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <Table
              data={this.state.collaborators}
              columns={columns1}
              page={"import"}
              changed={this.changedAllStatus}
            />
          </div>
        </div>
      </BlockUi>
    );
  }
}
