import React, {Component} from 'react';
import BootstrapTable from 'react-bootstrap-table-next'
import Modale from '../ModalForm/ModalForm.jsx'
import ReactTooltip from 'react-tooltip';
import paginationFactory from 'react-bootstrap-table2-paginator';
import "./Form.css"

export default class Form extends Component {
    
    constructor(){
        super();

        const formatWithIcon = (cell,row) => {
            return(
                <div>
                {this.props.page === 'userManagment' ? 
                    <i 
                    className="fas fa-user-lock" 
                    onClick={this.showModal} 
                    data-tip="Privilegios" 
                    data-for="privileges"> <ReactTooltip place="top" id="privileges"/></i>
                    :''
                }
                &nbsp;&nbsp;
                <i className="fas fa-edit" aria-hidden="true"></i>
                &nbsp;&nbsp;
                {this.props.page === 'instrument' ? 
                    <i 
                    className="fas fa-copy" 
                    data-tip="Clonar" 
                    data-for="clone"> <ReactTooltip place="top" id="clone"/></i>
                    :''
                }
                <i onClick={this.removeRow} className="fa fa-trash" aria-hidden="true"></i>
                </div>
            )
        }

        const data = [{
          
        }];
        
        const columns = [{
           
        }];

        this.state = {
            data,
            columns,
            page:-1,
            show:false,
            brand:"",
            action:"",
            brands:[],
            actions:[],
            privilege:'',
            privileges:[],
        };
    }

    handleChange = (event) => {
        const {name , value} = event.target;
        this.setState({
            page:value,
            [name] : value
        });

        console.log(this.state);
    }

    showModal = () => {
        this.setState({
            show:!this.state.show
        })
    }

    brandChange = () => {
        const brand = this.refs.business.value;
        this.setState({
            brand
        })
    }

    addBrand = () => {
        const brands = Object.assign([], this.state.brands);
        brands.push(this.state.brand);
        this.setState({
            brands:brands
        });
    }

    actionsChange = () => {
        const action = this.refs.actionsPrivileges.value;
        this.setState({
            action
        })
    }

    addActions = () => {
        const actions = Object.assign([], this.state.actions);
        actions.push(this.state.action);
        this.setState({
            actions:actions
        });
    }

    render(){


        
        const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            showTotal: false,
            sizePerPageList: [{
              text: '5', value: 5
            }, {
              text: '10', value: 10
            },{
                text:'15', value: 15
            }] // A numeric array is also available. the purpose of above example is custom the text
          };

        const {data, page} = this.props;
        return(
            <div className="Form">
                <div className="row">
                    <div className="col-md-5">
                    <form>
                    {
                        data.map(input => {
                            if(input.type === 'text'){
                                if(this.state.page == input.state || (input.state <1 && this.state.page > 0)){
                                    return(
                                            <div className="row form-group">
                                                <div className="col-md-12">
                                                    <input
                                                        type = {input.type}
                                                        name = {input.name}
                                                        ref = {input.ref}
                                                        className = {input.className}
                                                        placeholder = {input.placeholder}
                                                        onChange={this.handleChange}
                                                        required/>
                                                </div>
                                            </div>
                                        )
                                }
                            }
                            if(input.type === 'number'){
                                if(this.state.page == input.state || (input.state <1 && this.state.page > 0)){
                                    return(
                                            <div className="row form-group">
                                                <div className="col-md-12">
                                                    <input
                                                        type = {input.type}
                                                        name = {input.name}
                                                        ref = {input.ref}
                                                        className = {input.className}
                                                        placeholder = {input.placeholder}
                                                        required/>
                                                </div>
                                            </div>
                                        )
                                    }
                            }
                            if(input.type === 'date'){
                                if(this.state.page == input.state || (input.state <1 && this.state.page > 0)){
                                return(
                                            <div className="row form-group">
                                                    <div className="col-md-12">
                                                    <input
                                                        type = {input.type}
                                                        name = {input.name}
                                                        ref = {input.ref}
                                                        className = {input.className}
                                                        placeholder = {input.placeholder}
                                                        required/> 
                                                    </div>
                                                    
                                            </div>
                                        )
                                    }
                            }
                            if(input.type === 'password'){
                                if(this.state.page == input.state || (input.state <1 && this.state.page > 0)){
                                    return(
                                            <div className="row form-group">
                                                <div className="col-md-12">
                                                    <input
                                                        type = {input.type}
                                                        name = {input.name}
                                                        ref = {input.ref}
                                                        className = {input.className}
                                                        placeholder = {input.placeholder}
                                                        required/>
                                                </div>
                                            </div>
                                        )
                                }
                            }
                            if(input.type === 'select'){
                                if(this.state.page == input.state || (input.state <1 && this.state.page > 0) || input.state < 0){
                                   if(input.name === 'business'){
                                    return(
                                        <div>
                                        <div className="row form-group">
                                                <div className="col-md-11">
                                                    <select
                                                            name = {input.name}
                                                            ref = {input.ref}
                                                            className = {input.className}
                                                            id={input.name}
                                                            onChange={this.brandChange}
                                                            required>
                                                                <option value="" disabled selected>{input.label}</option>
                                                                {input.companies.map(input2 => {
                                                                    return( <option value={input2.value}>{input2.label}</option>
                                                                )})}
                                                    </select>
                                                </div>
                                                <div className="col-md-1">
                                                <i 
                                                className="fas fa-plus add"
                                                onClick={this.addBrand}></i>
                                                </div>
                                        </div>
                                        <div className="row from-group">
                                            <div className="col-md-12">
                                            
                                                {this.state.brands.map(brand => {
                                                    return(
                                                        <span class="badge badge-pill badge-secondary badge-outlined">{brand}</span>
                                                        )
                                                })
                                                }
                                            </div>
                                        </div>
                                        </div>
                                    ) 
                                   }
                                   if(input.name === 'privilegesRole'){
                                    return(
                                        <div>
                                        <div className="row form-group">
                                                <div className="col-md-11">
                                                    <select
                                                            name = {input.name}
                                                            ref = {input.ref}
                                                            className = {input.className}
                                                            id={input.name}
                                                            required>
                                                                <option value="" disabled selected>{input.label}</option>
                                                                {input.companies.map(input2 => {
                                                                    return( <option value={input2.value}>{input2.label}</option>
                                                                )})}
                                                    </select>
                                                </div>
                                                <div className="col-md-1">
                                                <i 
                                                className="fas fa-plus add"
                                                onClick={this.addBrand}></i>
                                                </div>
                                        </div>
                                        <div className="row from-group">
                                            <div className="col-md-12">
                                            
                                                        <span class="badge badge-pill badge-secondary badge-outlined">Crear</span>
                                                        <span class="badge badge-pill badge-secondary badge-outlined">Modificar</span>
                                                        <span class="badge badge-pill badge-secondary badge-outlined">Eliminar</span>
                                                                                            </div>
                                        </div>
                                        </div>
                                    ) 
                                   }
                                   if(input.name === 'actions'){
                                    return(
                                        <div>
                                        <div className="row form-group">
                                                <div className="col-md-11">
                                                    <select
                                                            name = {input.name}
                                                            ref = {input.ref}
                                                            className = {input.className}
                                                            id={input.name}
                                                            onChange={this.actionsChange}
                                                            required>
                                                                <option value="" disabled selected>{input.label}</option>
                                                                {input.companies.map(input2 => {
                                                                    return( <option value={input2.value}>{input2.label}</option>
                                                                )})}
                                                    </select>
                                                </div>
                                                <div className="col-md-1">
                                                <i 
                                                className="fas fa-plus add"
                                                onClick={this.addActions}></i>
                                                </div>
                                        </div>
                                        <div className="row from-group">
                                            <div className="col-md-12">
                                            
                                                {this.state.actions.map(brand => {
                                                    return(
                                                        <span class="badge badge-pill badge-secondary badge-outlined">{brand}</span>
                                                        )
                                                })
                                                }
                                            </div>
                                        </div>
                                        </div>
                                    ) 
                                   }else{
                                    return(
                                        <div className="row form-group">
                                            <div className="col-md-12">
                                                <select
                                                        name = {input.name}
                                                        ref = {input.ref}
                                                        className = {input.className}
                                                        id={input.name}
                                                        onChange={
                                                            (input.name === 'reponseType' || 
                                                            input.name === 'scaleType' || 
                                                            input.name === 'dimensionType' ||
                                                            input.name === 'typeDD')
                                                            ? this.handleChange : ''
                                                            }
                                                        required>
                                                            <option value="" disabled selected>{input.label}</option>
                                                                {input.companies.map(input2 => {
                                                                return( <option value={input2.value}>{input2.label}</option>
                                                            )})}
                                                </select>
                                            </div>
                                        </div>
                                    )
                                   }
                                    
                                }
                            }
                            
                            if(input.type === 'checkbox'){
                                if(this.state.page == input.state || (input.state <1 && this.state.page > 0)){                               
                                    return(
                                        <div className="row form-group">
                                            <div className="col-md-12">
                                                <div className="checkbox">
                                                    <label>
                                                    {input.label}
                                                    &nbsp;&nbsp;
                                                        <input
                                                            type = {input.type}
                                                            name = {input.name}
                                                            ref = {input.ref}
                                                            checked = {input.checked}
                                                            onClick ={ input.onClick } 
                                                            className = {input.className}
                                                            required/>
                                                            <div>
                                                                <div></div>
                                                            </div>
                                                    </label>
                                                </div>
                                                
                                             </div>
                                        </div>
                                    )
                                }
                            }

                            if(input.type === 'textarea'){
                                if(this.state.page == input.state || (input.state.page > -1 && input.state === 0)){
                                    return(
                                            <div className="row form-group">
                                                <div className="col-md-12">
                                                    <textarea
                                                        name = {input.name}
                                                        ref = {input.ref}
                                                        className = {input.className}
                                                        placeholder = {input.placeholder}
                                                        required/>
                                                </div>
                                            </div>
                                        )
                                }
                            }

                        })
                    }
                    <br/>
                    <div className="col-md-12">
                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-form btn-light">Guardar</button>
                        </div>
                    </div>
                </form>

                    </div>
 					<div className="col-md-7">
                        {(this.state.page == 1 && page === 'demographicData') ? 
                        <BootstrapTable 
                        keyField='id' 
                        data={ this.state.data } 
                        columns={ this.state.columns } 
                        bordered={ false }
                        hover
                        pagination={paginationFactory(options)}/> : ''} 
                        {(this.state.page == 2 && page === 'demographicData') ? 
                        <BootstrapTable 
                        keyField='id' 
                        data={ this.state.data } 
                        columns={ this.state.columns } 
                        bordered={ false }
                        hover
                        pagination={paginationFactory(options)}/> : ''}
                        {(this.state.page == 1 && page === 'dimensions') ? 
                        <BootstrapTable 
                        keyField='id' 
                        data={ this.state.data } 
                        columns={ this.state.columns } 
                        bordered={ false }
                        hover
                        pagination={paginationFactory(options)}/> : ''} 
                        {(this.state.page == 2 && page === 'dimensions') ? 
                        <BootstrapTable 
                        keyField='name' 
                        data={ this.state.data } 
                        columns={ this.state.columns } 
                        bordered={ false }
                        hover
                        pagination={paginationFactory(options)}/> : ''}
                        {(this.state.page == 1 && page === 'responseType') ? 
                        <BootstrapTable 
                        keyField='id' 
                        data={ this.state.data } 
                        columns={ this.state.columns } 
                        bordered={ false }
                        hover
                        pagination={paginationFactory(options)}
                        /> : ''} 
                        {(this.state.page == 2 && page === 'responseType') ? 
                        <BootstrapTable 
                        keyField='id' 
                        data={ this.state.data } 
                        columns={ this.state.columns } 
                        bordered={ false }
                        hover
                        pagination={paginationFactory(options)}/> : ''} 
                        {(this.state.page == 1 && page === 'measurementScale') ? 
                        <BootstrapTable 
                        keyField='id' 
                        data={ this.state.data } 
                        columns={ this.state.columns } 
                        bordered={ false }
                        hover
                        pagination={paginationFactory(options)}/> : ''}  
                        {(this.state.page == 2 && page === 'measurementScale') ? 
                        <BootstrapTable 
                        keyField='id' 
                        data={ this.state.data } 
                        columns={ this.state.columns } 
                        bordered={ false }
                        hover
                        pagination={paginationFactory(options)}/> : ''}
                        {(this.state.page == 1 && page === 'instrument') ? 
                        <BootstrapTable 
                        keyField='id' 
                        data={ this.state.data } 
                        columns={ this.state.columns } 
                        bordered={ false }
                        hover
                        pagination={paginationFactory(options)}/> : ''} 
                        {(this.state.page == 2 && page === 'instrument') ? 
                        <BootstrapTable 
                        keyField='id' 
                        data={ this.state.data } 
                        columns={ this.state.columns } 
                        bordered={ false }
                        hover
                        pagination={paginationFactory(options)}/> : ''}
                        {(page === 'assignBrand') ? 
                        <BootstrapTable 
                        keyField='id' 
                        data={ this.state.data } 
                        columns={ this.state.columns } 
                        bordered={ false }
                        hover
                        pagination={paginationFactory(options)}/> : ''} 
                        {(page === 'userManagment') ? 
                        <BootstrapTable 
                        keyField='id' 
                        data={ this.state.data } 
                        columns={ this.state.columns } 
                        bordered={ false }
                        hover
                        pagination={paginationFactory(options)}/> : ''}
                        {(page === 'roles') ? 
                        <BootstrapTable 
                        keyField='id' 
                        data={ this.state.data } 
                        columns={ this.state.columns } 
                        bordered={ false }
                        hover
                        pagination={paginationFactory(options)}/> : ''}
                        {(page === 'privileges') ? 
                        <BootstrapTable 
                        keyField='id' 
                        data={ this.state.data } 
                        columns={ this.state.columns } 
                        bordered={ false }
                        hover
                        pagination={paginationFactory(options)}/> : ''}
                        {(page === 'brandManagment') ? 
                        <BootstrapTable 
                        keyField='id' 
                        data={ this.state.data } 
                        columns={ this.state.columns } 
                        bordered={ false }
                        hover
                        pagination={paginationFactory(options)}/> : ''}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Modale
                        show={this.state.show}
                        hide={this.showModal}
                        />
                        </div>
                    </div>
            </div>
        )
    }
}