import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import ReactTooltip from 'react-tooltip';
import logo from '../../LogoBe&co.png';

import versionTooltip from '../../version.js'
import UploadImage from '../UpdateImage/Update.Image.jsx';

import './Navbar.css';

export default class Navbar extends Component {

    constructor(){
        super();
        this.state = {
            sidebarCollapse:true
        }
    }



    logOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem("image");
    }
    
    collapseSidebar = () => {
        this.setState({
            sidebarCollapse:!this.state.sidebarCollapse
        })

    }

    render(){
        const user = JSON.parse(localStorage.getItem("user"));
        console.log(user);
        const {page, image, role} = this.props;
        console.log(role);
        return(
            <div className="Navbar">
                
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <button 
                    className="navbar-toggler" 
                    type="button" 
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                <div 
                id="navbarNav"
                className="collapse navbar-collapse">    
                <ul className="nav navbar-nav mr-auto">
                    <li className="nav-item">
                        <a data-tip={versionTooltip}
                         data-for="version" 
                         className="nav-link" 
                         href="#menu-toggle" 
                         id="menu-toggle"
                         onClick={this.collapseSidebar}>
                            <i className="fa fa-bars">
                            </i>
                        </a>
                        <ReactTooltip place="top"
                        id="version"/>
                    </li>
                    <li className="nav-item">
                        <Link to="/Home"><i className={page === 'home' ? 'fas fa-home active-home' : 'fas fa-home'}></i></Link>
                    </li>
                    {!this.props.items === false ? 
                        this.props.items.map(item => {
                            return <li className="nav-item item">
                                        <a className="nav-link" 
                                        key={item}
                                        href="#"
                                        name={item}
                                        key={item}>{item}</a>
                                    </li>
                    }) : <li></li>}
                    
                </ul>
                {!this.state.sidebarCollapse ? 
                    <ul className="nav navbar-nav mx-auto">
                        <li className="nav-item">
                            <img className="logoNavbar" src={image}></img>
                        </li>
                    </ul>
                : null}
                <ul className="nav navbar-nav ml-auto">
                    <li className="nav-item">
                        <span>{user.firstName + " "+ user.lastName}</span>
                        &nbsp;
                    </li>
                {
                    user.role.name == 'Administrador Empresas'
                    ? 
                    <li className="nav-item">
                        <UploadImage />
                    </li>
                    :
                    null
                }
                    &nbsp;&nbsp;
                    <Link to='/'>
                    <li className="nav-item"
                         data-tip="Cerrar sesión"
                         data-for="log-out"
                         to="/"><i 
                        className="fas fa-sign-out-alt"
                        onClick={this.logOut}></i>
                        <ReactTooltip place="top"
                        id="log-out"/>
                    </li>
                    </Link>
                </ul>
                </div>
                </nav>
            </div>
        )
    }
}
