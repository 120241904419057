import React, { Component } from "react";
import axios from "axios";
import token from "../../../token.js";
import { CSVLink } from "react-csv";
import { ToastsContainer, ToastsStore } from "react-toasts";

class DataExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminGroup: "Grupo Administrador",
      organization: "Organización",
      company: "Empresa / Departamento",
      instrument: "Instrumento",
      adminGroups: [],
      organizations: [],
      companies: [],
      baremo: [],
      data: [],
      results: [],
      dataResults: [],
      dataResultsBySelection: [],
      instruments: []
    };
  }
  componentDidMount() {
    this.getAdminGroup();
    this.getOrganizations();
    this.getCompanies();
    this.getInstruments();
    this.getResults();
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
    // console.log(
    //   `Grupo: ${this.state.adminGroup} Organizacion: ${this.state.organization} Compañia: ${this.state.company}`
    // );
  };

  handleChangeAdmGro = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      organization: "Organización",
      company: "Empresa / Departamento"
    });
    // console.log(
    //   `Grupo: ${this.state.adminGroup} Organizacion: ${this.state.organization} Compañia: ${this.state.company}`
    // );
  };


  getOrganizations = () => {
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/organizations`,
        { headers: { "x-auth-token": token } }
      )
      .then(res => {
        console.log(res.data);
        this.setState({
          organizations: res.data.organizations
        });
      });
  };

  getAdminGroup = () => {
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/admingroups`, {
        headers: { "x-auth-token": token }
      })
      .then(res => {
        console.log(res.data);
        this.setState({ adminGroups: res.data.adminGroups });
      });
  };

  getCompanies = () => {
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/companies`, {
        headers: { "x-auth-token": token }
      })
      .then(res => {
        console.log(res.data);
        this.setState({
          companies: res.data.companies
        });
      });
  };

  getInstruments = () => {
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/instruments`, {
        headers: { "x-auth-token": token }
      })
      .then(res => {
        console.log(res.data);
        this.setState({
          instruments: res.data.instruments
        });
      });
  };

  onSelectInput = event => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value
      },
      () => {
        this.getResultsBySelection();
        console.log(
          `Grupo: ${this.state.adminGroup} Organizacion: ${this.state.organization} Compañia: ${this.state.company}`
        );
      }
    );
  };

  clearInputs = () => {
    this.setState({
      adminGroup: "Grupo Administrador",
      organization: "Organización",
      company: "Empresa / Departamento",
      instrument: "Instrumento"
    });
  };

  exportData = props => {
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/exportData/instruments/${this.props.user._id}/${this.state.instrument}/${this.state.organization}/${this.state.adminGroup}`,
      {
        headers: { "x-auth-token": token }
      })
      .then(res => {
        window.open(res.config.url);
        this.setState({
          adminGroup: "Grupo Administrador",
          organization: "Organización",
          company: "Empresa / Departamento",
          instrument: "Instrumento",
        })
        ToastsStore.success("El archivo ha sido descargado con exito.");
      })
      .catch(err => {
        ToastsStore.error(err.response.data.msg);
      });
  }

  getResults = () => {
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/results/filter`, {
        headers: { "x-auth-token": token }
      })
      .then(res => {
        this.setState({
          results: res.data.results
        });
        console.log(this.state.results);
      })
      .catch(err => {
        console.log(err);
      });
  };

  getResultsBySelection = () => {
    this.setState(
      {
        dataResultsBySelection: []
      },
      () => {
        const dataResultsBySelection = Object.assign(
          [],
          this.state.dataResultsBySelection
        );
        axios
          .get(
            `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/results/${
            this.state.instrument == "Instrumento"
              ? this.state.organization
              : this.state.instrument
            }`,
            { headers: { "x-auth-token": token } }
          )
          .then(res => {
            this.setState({
              dataResultsBySelection: res.data.results
            });
            // console.log(this.state.dataResultsBySelection);
            this.state.dataResultsBySelection.map(data => {
              data.responses.map(response => {
                data.demographicDataValues.map(demographicDataValue => {
                  if (
                    data.instrument._id == this.state.instrument ||
                    data.company.organization._id == this.state.organization
                  ) {
                    if (
                      response.subdimension === undefined ||
                      response.subdimension === null
                    ) {
                      dataResultsBySelection.push({
                        Instrumento: data.instrument.name,
                        Dimension: response.dimension.name,
                        // responsessubdimensionname: response.subdimension.name,
                        Preguntas: response.question.question,
                        Efecto: response.question.effect,
                        Respuestas: response.response.name,
                        "Valor de Respuestas": response.response.value,
                        "Valor de Dato Demografico": demographicDataValue.name
                      });
                    } else {
                      dataResultsBySelection.push({
                        Instrumento: data.instrument.name,
                        Dimension: response.dimension.name,
                        Subdimension: response.subdimension.name,
                        Preguntas: response.question.question,
                        Efecto: response.question.effect,
                        Respuestas: response.response.name,
                        "Valor de Respuestas": response.response.value,
                        "Valor de Dato Demografico": demographicDataValue.name
                      });
                    }
                  }
                });
              });
            });

            this.setState({
              dataResultsBySelection
            });
            console.log(dataResultsBySelection);
          })
          .catch(err => {
            console.log(err.response);
            ToastsStore.error(err.response.data.msg);
          });
      }
    );
  };

  render() {
    const { user } = this.props;
    return (
      <div className="row">
        <div className="col-md-5">
          <form onSubmit={this.getResults}>
            <div className="row form-group">
              <div className="col-md-12">
                <select
                  name="adminGroup"
                  ref="administratorGroup"
                  className="form-control"
                  onChange={this.handleChangeAdmGro}
                  value={this.state.adminGroup}
                  required
                >
                  <option value="Grupo Administrador" disabled>
                    Grupo Administrador
                  </option>
                  {this.state.adminGroups.map(adminGroup => {
                    return (
                      <option key={adminGroup._id} value={adminGroup._id}>
                        {adminGroup.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="row form-group">
              <div className="col-md-12">
                <select
                  name="organization"
                  ref="organization"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.organization}
                  required
                >
                  <option value="Organización" disabled>
                    Organización
                  </option>
                  {this.state.organizations.map(organization => {
                    if (organization.admingroup._id == this.state.adminGroup) {
                      return (
                        <option key={organization._id} value={organization._id}>
                          {organization.name}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
            </div>

            {/* <div className="row form-group">
              <div className="col-md-12">
                <select
                  name="company"
                  ref="company"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.company}
                  required
                >
                  <option value="Empresa / Departamento" disabled>
                    Empresa / Departamento
                  </option>
                  {this.state.companies.map(company => {
                    if (company.organization._id == this.state.organization) {
                      return (
                        <option key={company._id} value={company._id}>
                          {company.name}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
            </div> */}
            <div className="row form-group">
              <div className="col-md-12">
                <select
                  name="instrument"
                  ref="instrument"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.instrument}
                >
                  <option value="Instrumento" disabled>
                    Instrumento
                  </option>
                  {this.state.results.map(result => {
                    console.log(result);
                    // if (result.organization == undefined) {
                    //   // console.log("pase por undefined");
                    // } else
                    console.log(result._id.org_id);
                    if (
                      result._id.org_id == this.state.organization
                    ) {
                      return (
                        <option
                          key={result._id._id}
                          value={result._id._id}
                        >
                          {result._id.name}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
            </div>
            <br />
            {user.role.name == "Reporte" ? null : (
              <div className="col-md-12">
                <div className="text-center">
                  <button type="button" className="btn btn-form btn-light mr-2"
                    onClick={this.exportData}>
                    Exportar
                  </button>
                  {/* <CSVLink
                    onClick={() => {
                      this.clearInputs();
                    }}
                    data={this.state.dataResultsBySelection}
                    className="btn btn-form btn-light"
                  >
                    Exportar
                  </CSVLink> */}
                </div>
              </div>
            )}
            <ToastsContainer store={ToastsStore} />
          </form>
        </div>
        <div className="col-md-7"></div>
      </div>
    );
  }
}

export default DataExport;
