import React, { Component } from "react";
import Modal from "react-bootstrap4-modal";

// import BASEPATH from '../../const.js'
import "./ModalForm.css";
import axios from "axios";

export default class ModalForm extends Component {
  constructor() {
    super();
    this.state = {
      privilege: "Privilegios",
      create: true,
      delete: true,
      update: true,
      consult: true
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  isCheckedCrear = () => {
    this.setState({
      create: !this.state.create
    });
  };

  isCheckedEliminar = () => {
    this.setState({
      delete: !this.state.delete
    });
  };

  isCheckedActualizar = () => {
    this.setState({
      update: !this.state.update
    });
  };

  isCheckedConsultar = () => {
    this.setState({
      consult: !this.state.consult
    });
  };

  render() {
    if (this.props.user) {
      return (
        <div className="Modal">
          <Modal
            visible={this.props.show}
            onClickBackdrop={this.props.onCancel}
          >
            <div className="modal-header">
              <h5 className="modal-title">Panel de privilegios</h5>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <h6>
                      {this.props.user.firstName +
                        " " +
                        this.props.user.lastName}
                    </h6>
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                    <select
                      name="privilege"
                      ref="privilege"
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.privilege}
                      required
                    >
                      <option value="Privilegios" disabled>
                        Privilegios
                      </option>
                      {this.props.user.role.privileges.map(privilege => {
                        return (
                          <option key={privilege.name} value={privilege.name}>
                            {privilege.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-4">
                    {this.props.user.role.privileges.map(privilege => {
                      if (privilege.name == this.state.privilege) {
                        return privilege.actions.map(action => {
                          return (
                            <div class="form-check-inline">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name={action}
                                  value={action}
                                  defaultChecked={this.state.action}
                                />
                                {action}
                              </label>
                            </div>
                          );
                        });
                      }
                    })}
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn" onClick={this.props.onOk}>
                Guardar
              </button>
              <button
                type="button"
                className="btn"
                onClick={this.props.onCancel}
              >
                Cancelar
              </button>
            </div>
          </Modal>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
