import React from 'react';
import {
    PDFDownloadLink,
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Font,
  } from "@react-pdf/renderer";

export const  DemographicsForQuestion = ({ dimension, widthDimS, array }) => {

    const valor = 50 / array.length;
    const widthDimesion = valor + "%";
    const styles = StyleSheet.create({
        body: {
          paddingTop: 35,
          paddingBottom: 30,
          paddingHorizontal: 20,
        },
        section: {
          margin: 15,
        },
        table: {
          display: "table",
          width: "auto",
          borderStyle: "solid",
          borderColor: "#FFFFFF",
          borderWidth: 1,
          borderRightWidth: 0,
          borderBottomWidth: 0,
          marginBottom: 10,
          marginLeft: 10,
          marginRight: 10,
          //marginTop: 1,
        },
        table3: {
          display: "table",
          width: "auto",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderWidth: 1,
          borderRightWidth: 0,
          borderBottomWidth: 0,
          margin: "auto",
        },
        tableNps: {
          display: "table",
          width: "325px",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderWidth: 1,
          borderRightWidth: 0,
          borderBottomWidth: 0,
          margin: 10,
        },
        tableBaremo: {
          display: "table",
          width: "300px",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderWidth: 1,
          borderRightWidth: 0,
          borderBottomWidth: 0,
          margin: 10,
        },
        tableInfo: {
          display: "table",
          width: "300px",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderWidth: 3,
          borderRightWidth: 3,
          borderBottomWidth: 3,
          margin: 10,
        },
        tableRow: {
          margin: "auto",
          flexDirection: "row",
        },
        tableColHeader: {
          width: "25%",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderBottomColor: "#000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 0,
        },
        tableColHeader1: {
          width: "64%",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderBottomColor: "#000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 0,
        },
        tableColHeader2: {
          width: "12%",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderBottomColor: "#000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 0,
        },
        tableColHeaderBaremo: {
          width: "25%",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderBottomColor: "#000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 0,
        },
        tableColHeaderTwo: {
          width: "20%",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderBottomColor: "#000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 0,
        },
        tableColHeaderThree: {
          width: "13%",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderBottomColor: "#000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 0,
        },
        tableColHeaderFour: {
          width: "13%",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderBottomColor: "#000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 0,
        },
        tableColHeaderFive: {
          //width: "12%",
          width: widthDimesion,          //flexBasis: "auto",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderBottomColor: "#000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 0,
        },
        tableCellTwo: {
          margin: "auto",
          margin: 5,
          fontSize: 9,
          fontWeight: 500,
        },
        tableCellTwoDim: {
          fontFamily: "Helvetica-Bold",
          margin: "auto",
          margin: 5,
          fontSize: 10,
          fontWeight: 700,
        },
        tableCellThree: {
          margin: "auto",
          margin: 5,
          fontSize: 7,
          fontWeight: 500,
          textAlign: "center",
        },
        tableCellFour: {
          margin: "auto",
          margin: 5,
          fontSize: 7,
          fontWeight: 500,
          textAlign: "center",
        },
        tableCellFive: {
          margin: "auto",
          margin: 2,
          fontSize: 9,
          fontWeight: 500,
          textAlign: "center",
        },
        tableColHeaderInfo: {
          width: "50%",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderBottomColor: "#000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 0,
        },
        tableColHeaderInfo2: {
          width: "25%",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderBottomColor: "#000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 0,
        },
        tableColHeaderNPS: {
          width: "33%",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderBottomColor: "#000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 0,
        },
        tableCol: {
          width: "25%",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 0,
        },
  
        tableCol1: {
          width: "64%",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        },
  
        tableCol2: {
          width: "12%",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        },
  
        tableColInfo: {
          width: "50%",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 0,
        },
        tableColInfo2: {
          width: "25%",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 0,
        },
        tableColNps: {
          width: "33%",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 0,
        },
  
        tableColNps: {
          width: "33%",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 0,
        },
        tableColTwo: {
          width: "50%",
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 0,
        },
        tableColTwoDim: {
          width: "20%",
          borderColor: "#bfbfbf",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        },
        tableColTwoDim2: {
          width: "13%",
          borderColor: "#FFFFFF",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 0,
        },
        tableColFive: {
          width: widthDimesion,
          borderStyle: "solid",
          borderColor: "#bfbfbf",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 0,
        },
        tableCellHeader: {
          margin: "auto",
          margin: 5,
          fontSize: 10,
          fontWeight: 500,
        },
        tableCellHeaderCent: {
          margin: "auto",
          margin: 5,
          fontSize: 9,
          fontWeight: 500,
          textAlign: "center",
        },
        tableCellHeaderBaremo: {
          margin: "auto",
          margin: 5,
          fontSize: 9,
          fontWeight: 500,
          textAlign: "center",
        },
        tableCellInfo: {
          margin: "auto",
          margin: 5,
          fontSize: 10,
          fontWeight: 500,
          textAlign: "center",
        },
        tableCellNps: {
          margin: "auto",
          margin: 5,
          fontSize: 9,
          fontWeight: 500,
          textAlign: "center",
        },
        tableCell: {
          margin: "auto",
          margin: 5,
          fontSize: 9,
        },
        tableCellCent: {
          margin: "auto",
          margin: 5,
          fontSize: 9,
          textAlign: "center",
        },
        tableCellBold: {
          margin: "auto",
          margin: 5,
          fontSize: 9,
          fontWeight: 700,
        },
        tableCellSub: {
          margin: "auto",
          margin: 5,
          fontSize: 9,
          fontWeight: 700,
        },
        breakable: { width: "100%", height: 400 },
        emphasisDimension: {
          fontFamily: "Helvetica-Bold",
          margin: "auto",
          margin: 5,
          fontSize: 10,
          fontWeight: 700,
        },
        emphasisSubdimension: {
          fontFamily: "Helvetica-Bold",
          margin: "auto",
          margin: 5,
          fontSize: 9,
          fontWeight: 700,
        },
        emphasisSubdimensionCent: {
          fontFamily: "Helvetica-Bold",
          margin: "auto",
          margin: 5,
          fontSize: 9,
          fontWeight: 700,
          textAlign: "center",
        },
        emphasisDimensionCent: {
          fontFamily: "Helvetica-Bold",
          margin: "auto",
          margin: 5,
          fontSize: 9,
          fontWeight: 700,
          textAlign: "center",
        },
        emphasisDimensionCent3: {
          fontFamily: "Helvetica-Bold",
          margin: "auto",
          margin: 5,
          fontSize: 10,
          fontWeight: 700,
        },
        emphasisSubdimensionCent3: {
          fontFamily: "Helvetica-Bold",
          margin: "auto",
          margin: 5,
          fontSize: 10,
          fontWeight: 700,
          textAlign: "center",
        },
        pageNumber: {
          position: "relative",
          fontSize: 9,
          top: 5,
          left: 0,
          right: 0,
          textAlign: "right",
        },
        tableCellFiveBold: {
          fontFamily: "Helvetica-Bold",
          margin: "auto",
          margin: 5,
          fontSize: 9,
          fontWeight: 500,
          textAlign: "center",
        },
        tableRowBottom: {
            borderStyle: "solid",
            borderColor: "#bfbfbf",
            borderBottomColor: "#000",
            borderWidth: 1,
          },
      });
      
    
    return (
        <View style={styles.table}>
            <View style={styles.tableRow, styles.tableRowBottom} wrap={false}>
                <View style={[styles.tableColTwoDim, { backgroundColor: "#DADBDD" },{ width: "100%" }]}>
                  <Text style={styles.tableCellTwoDim}>
                      {dimension.name}
                  </Text>
                </View>
            </View>
            {dimension.subdimensions.map((subdimension) => {
                return (
                <View>
                  <View style={styles.tableRow, { width: "100%", display: "flex", flexDirection: "row"}} wrap={false}>
                    <View style={styles.tableColTwo}>
                        <Text style={styles.emphasisDimensionCent3}>
                        {subdimension.name}
                        </Text>
                    </View>

                    {array.map((value) => {
                        return (
                        <View
                            style={[
                            styles.tableColHeaderFive,
                            { backgroundColor: "#DADBDD" },
                            ]}
                        >
                            <Text style={styles.tableCellFiveBold}>
                            {value}
                            </Text>
                        </View>
                        );
                    })}
                    {/* {array.map(column => {
                                                return (
                                                    <View style={[styles.tableColFive, { backgroundColor: '#DADBDD' }]}>
                                                        <Text style={styles.tableCellFive} >{""}</Text>
                                                    </View>
                                                )
                                            })} */}
                    </View>
                    {subdimension.questions.map((question) => {
                    return (
                        <View>
                        <View style={styles.tableRow} wrap={false}>
                            <View style={styles.tableColTwo}>
                            <Text style={styles.tableCellTwo}>
                                {question.text}
                            </Text>
                            </View>

                            {question.demographics[0].values.map(
                            (value) => {
                                return (
                                <View
                                    style={[
                                    styles.tableColFive,
                                    { backgroundColor: value.color },
                                    ]}
                                >
                                    <Text style={styles.tableCellFive}>
                                    {value.average.toFixed(2)}
                                    </Text>
                                    <Text style={styles.tableCellFive}>
                                    {value.tag}
                                    </Text>
                                </View>
                                );
                            }
                            )}
                        </View>
                        </View>
                    );
                    })}
                </View>
                );
            })}
            </View>

    );
}