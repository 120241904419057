import React, { Component } from "react";
import axios from "axios";
import token from "../../../token.js";

import tips from "../tips";
import { sortAs } from "react-pivottable/Utilities";
import TableRenderers from "react-pivottable/TableRenderers";
import createPlotlyComponent from "react-plotly.js/factory";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import PivotTableUI from "react-pivottable/PivotTableUI";
import Dropzone from "react-dropzone";
import Papa from "papaparse";
import "react-pivottable/pivottable.css";
import Table from "../../Table/Table";

// create Plotly React component via dependency injection
const Plot = createPlotlyComponent(window.Plotly);

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);
class PivotTableUISmartWrapper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { pivotState: props };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ pivotState: nextProps });
  }

  render() {
    return (
      <PivotTableUI
        renderers={Object.assign(
          {},
          TableRenderers,
          createPlotlyRenderers(Plot)
        )}
        {...this.state.pivotState}
        onChange={(s) => this.setState({ pivotState: s })}
        unusedOrientationCutoff={Infinity}
      />
    );
  }
}

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataResults: [],
      data: [],
      final: [],
    };
  }

  componentDidMount() {
    this.getResults();
    this.getStructureBaremos();
    this.getResultsByInstrument();
  }

  getStructureBaremos = () => {
    this.setState({
      data: [],
      structureBaremo: [],
    });
    const data = [];
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/structureBaremos`,
        { headers: { "x-auth-token": token } }
      )
      .then((res) => {
        this.setState({
          structureBaremo: res.data.structureBaremos.filter(
            (structure) => structure.baremo.status
          ),
        });

        if (this.state.structureBaremo != null) {
          this.state.structureBaremo.map((structureBaremo, index) => {
            if (structureBaremo.status) {
              structureBaremo.status = "Activo";
            } else {
              structureBaremo.status = "Inactivo";
            }
            // if (structureBaremo.baremo.status) {
            data.push({
              _id: structureBaremo._id,
              name: structureBaremo.baremo.name,
              description: structureBaremo.description,
              rangeMin: structureBaremo.rangeMin.toFixed(2),
              rangeMax:
                index !== this.state.structureBaremo.length - 1
                  ? `< ${structureBaremo.rangeMax.toFixed(2)}`
                  : structureBaremo.rangeMax.toFixed(2),
              color: structureBaremo.color,
            });
            // }
          });
          this.setState({
            data,
          });
        }
      });
  };

  getResults = () => {
    this.setState({
      results: [],
    });
    const dataResults = Object.assign([], this.state.dataResults);
    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/results`,
        {
          headers: { "x-auth-token": token },
        }
      )
      .then((res) => {
        console.log(res.headers);
        this.setState({
          results: res.data.results,
        });

        this.state.results.map((data) => {

          // Almacena la información asociada a un instrumento asociado a un demographics
          dataResults.push({ "instrument.name": data.instrument.name });
          data.responses.map((response) => {

            // Obtiene las respuestas del usuario sobre una dimensión y subdimensión
            dataResults.push({
              "responses.dimension.name": response.dimension.name,
              "responses.subdimension.name": response.subdimension.name,
              "responses.question.name": response.question.name,
              "responses.question.effect": response.question.effect,
              "responses.response.name": response.response.name,
              "responses.response.value": response.response.value,
            });
          });
          data.demographicDataValues.map((demographicDataValue) => {
            dataResults.push({
              "demograpihicDataValues.name": demographicDataValue.name,
            });
          });
        });

        console.log(dataResults);
        this.setState({
          dataResults: dataResults,
        });

        console.log(this.state.dataResults);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getResultsByInstrument = () => {
    this.setState({
      results: [],
    });

    axios
      .get(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/results`,
        {
          headers: { "x-auth-token": token },
        }
      )
      .then((res) => {
        const results = res.data.results;

        let data = [];
        let dataResults = [];
        var i = 0;

        results.forEach((result) => {
          const orgName = result.company.organization.name;
          const companyName = result.company.name;
          const instrumentName = result.instrument.name;

          result.demographicDataValues.forEach((value, index) => {
            const demographicDataValueName = value.name;
            const demographicDataName = value.demographicData.name;
            dataResults = result.responses.map((response) => {
              
              let newResult;
              
              if (
                response.subdimension === undefined ||
                response.subdimension === null
              ) {
                newResult = {
                  Organización: orgName,
                  "Área a la que reporta": companyName,
                  Instrumento: instrumentName,
                  "Nombre de la dimension": response.dimension.name,
                  Preguntas: response.question.question,
                  Efectos: response.question.effect,
                  Respuestas: response.response.name,
                  Valor: response.response.value,
                  "Datos demograficos": demographicDataName,
                  "Valores demograficos": demographicDataValueName,
                };
              } else {
                newResult = {
                  Organización: orgName,
                  "Área a la que reporta": companyName,
                  Instrumento: instrumentName,
                  "Nombre de la dimension": response.dimension.name,
                  "Nombre de la subdimension": response.subdimension.name,
                  Preguntas: response.question.question,
                  Efectos: response.question.effect,
                  Respuestas: response.response.name,
                  Valor: response.response.value,
                  "Datos demograficos": demographicDataName,
                  "Valores demograficos": demographicDataValueName,
                };
              }

              i++;
              return newResult;
            });

            data.push(...dataResults);
          });
        });

        console.log(" DATAAAA: ", data);

        this.setState({
          mode: "demo",
          filename: "Sample Dataset: Tips",
          pivotState: {
            data,
            aggregatorName: "Count",
            rendererName: "Table",
            sorters: {
              Meal: sortAs(["Lunch", "Dinner"]),
              "Day of Week": sortAs([
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ]),
            },
            plotlyOptions: { width: 900, height: 500 },
            plotlyConfig: {},
            tableOptions: {
              clickCallback: function (e, value, filters, pivotData) {
                var names = [];
                pivotData.forEachMatchingRecord(filters, function (record) {
                  names.push(record.Meal);
                });
                alert(names.join("\n"));
              },
            },
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentWillMount() {
    this.setState({
      mode: "demo",
      filename: "Sample Dataset: Tips",
      pivotState: {
        data: tips,
        aggregatorName: "Count",
        vals: ["Total Bill"],
        rendererName: "Table",
        sorters: {},
        plotlyOptions: { width: 900, height: 500 },
        plotlyConfig: {},
        tableOptions: {
          clickCallback: function (e, value, filters, pivotData) {
            this.setState({
              mode: "demo",
              filename: "Sample Dataset: Tips",
              pivotState: {
                data: tips,
                rows: ["Payer Gender"],
                cols: ["Party Size"],
                aggregatorName: "Sum over Sum",
                vals: ["Tip", "Total Bill"],
                rendererName: "Grouped Column Chart",
                sorters: {
                  Meal: sortAs(["Lunch", "Dinner"]),
                  "Day of Week": sortAs([
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ]),
                },
                plotlyOptions: { width: 900, height: 500 },
                plotlyConfig: {},
                tableOptions: {
                  clickCallback: function (e, value, filters, pivotData) {
                    var names = [];
                    pivotData.forEachMatchingRecord(filters, function (record) {
                      names.push(record.Meal);
                    });
                    alert(names.join("\n"));
                  },
                },
              },
            });
            var names = [];
            pivotData.forEachMatchingRecord(filters, function (record) {
              names.push(record.Meal);
            });
            alert(names.join("\n"));
          },
        },
      },
    });
    console.log("Dentro de will: " + this.state.dataResults);
  }

  onDrop(files) {
    this.setState(
      {
        mode: "thinking",
        filename: "(Parsing CSV...)",
        textarea: "",
        pivotState: { data: [] },
      },
      () =>
        Papa.parse(files[0], {
          skipEmptyLines: true,
          error: (e) => alert(e),
          complete: (parsed) =>
            this.setState({
              mode: "file",
              filename: files[0].name,
              pivotState: { data: parsed.data },
            }),
        })
    );
  }

  onType(event) {
    Papa.parse(event.target.value, {
      skipEmptyLines: true,
      error: (e) => alert(e),
      complete: (parsed) =>
        this.setState({
          mode: "text",
          filename: "Data from <textarea>",
          textarea: event.target.value,
          pivotState: { data: parsed.data },
        }),
    });
  }

  render() {
    const columns2 = [
      {
        dataField: "rangeMin",
        text: "Rango Mínimo",
      },
      {
        dataField: "rangeMax",
        text: "Rango Máximo",
      },
      {
        dataField: "",
        text: "Color",
        style: (cell, row, rowIndex, colIndex) => {
          return { backgroundColor: row.color };
        },
      },
    ];

    return (
      <div className="data">
        <div className="row justify-content-center mt-4">
          <div className="d-flex">
            {/* <div className="col-7">
              <Dropzone
                onDrop={this.onDrop.bind(this)}
                accept="text/csv"
                className="dropzone"
                activeClassName="dropzoneActive"
                rejectClassName="dropzoneReject"
              >
                <p className="text-center mt-4">
                  Drop a CSV file here, or click to choose a file from your
                  computer.
                </p>
              </Dropzone>
            </div> */}
            <div className="col-12">
              <Table data={this.state.data} columns={columns2} page={true} />
            </div>
            <div className="d-flex"></div>
            {/* <div className="col-md-3 text-center">
            <p>...or paste some data:</p>
            <textarea
              value={this.state.textarea}
              onChange={this.onType.bind(this)}
              placeholder="Paste from a spreadsheet or CSV-like file"
            />
          </div> */}
          </div>
        </div>
        <div className="col-12 mx-auto">
          <div className="data">
            {/* <div className="row justify-content-center mt-4">
              <div className="col-4 mx-auto">
                <h2 className="text-center">{this.state.filename}</h2>
              </div>
            </div> */}
            <br />
            <div className="row marginLeft">
              <div className="col-12">
                <PivotTableUISmartWrapper {...this.state.pivotState} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Results;
