import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Login from "./components/Login/Login.jsx";
import RecoverPassword from "./components/RecoverPassword/RecoverPassword.jsx";
import Instrument from "./components/Instrument/Instrument.jsx";
import Parametrization from "./components/Parametrization/Parametrization.jsx";
import Roles from "./components/Roles/Roles.jsx";
import DataManagement from "./components/DataManagement/DataManagement.jsx";
import Reporteador from "./components/Reporteador/Reporteador.jsx";
import Home from "./components/Home/Home.jsx";
import UserManagement from "./components/UserManagement/UserManagement.jsx";
import LoginEpa from "./components/LoginEpa/LoginEpa.jsx";

const browserHistory = createBrowserHistory();

export const renderRoutes = () => (
  <Router history={browserHistory}>
    <Switch>
      <Route exact={true} path="/" component={Login} />
      <Route path="/Home" component={Home} />
      <Route path="/userManagement" component={UserManagement} />
      <Route path="/recoverPassword" component={RecoverPassword} />
      <Route path="/instrument" component={Instrument} />
      <Route path="/parametrization" component={Parametrization} />
      <Route path="/roles" component={Roles} />
      <Route path="/dataUpdate" component={DataManagement} />
      <Route path="/reporter" component={Reporteador} />
      <Route path="/login" component={LoginEpa} />
    </Switch>
  </Router>
);
