import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import token from '../../token.js';
//import logo from '../../LogoBe&co.png';

// import BASEPATH from "../../const.js";
import "./Sidebar.css";

export default class Sidebar extends Component {
  constructor() {
    super();
    this.state = {
      image: "",
      organizations: []
    };
  }

  componentWillMount() {
    if (localStorage.getItem('image') == null) {
      console.log("Epa")
      this.getLogo();
    } else {

    }

  }

  getLogo = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/organizations`, { headers: { 'x-auth-token': token } })
      .then(res => {
        this.setState({
          organizations: res.data.organizations
        });
        this.state.organizations.map(organization => {
          if (organization._id == user.company.organization._id) {
            localStorage.setItem('image', organization.logo.imagecode);
          }
        });
      });
  };

  render() {
    const { page, role } = this.props;
    return (
      <div id="sidebar-wrapper">
        <ul className="sidebar-nav">
          <li className="sidebar-brand">
            <a href="#">
              <img className="imgSidebar" src={localStorage.getItem('image')}></img>
            </a>
          </li>
          <br />
          <br />
          {role.name != "Reporte" ? (
            <li
              className={
                page === "gestion"
                  ? "active-sidebar nav-item-sidebar"
                  : "nav-item-sidebar"
              }
            >
              <Link to="/userManagement">Gestión de Usuario</Link>
            </li>
          ) : null}
          {role.name != "Reporte" ? (
            <li
              className={
                page === "update"
                  ? "active-sidebar nav-item-sidebar"
                  : "nav-item-sidebar"
              }
            >
              <Link to="/dataUpdate">Gestión de Datos</Link>
            </li>
          ) : null}

          {role.name == "Administrador" || role.name == "Sistemas" ? (
            <li
              className={
                page === "roles"
                  ? "active-sidebar nav-item-sidebar"
                  : "nav-item-sidebar"
              }
            >
              <Link to="/roles">Roles y Privilegios</Link>
            </li>
          ) : null}

          {role.name != "Reporte" ? (
            <li
              className={
                page === "params"
                  ? "active-sidebar nav-item-sidebar"
                  : "nav-item-sidebar"
              }
            >
              <Link to="/parametrization">Parametrización</Link>
            </li>
          ) : null}

          {role.name != "Reporte" ? (
            <li
              className={
                page === "instrument"
                  ? "active-sidebar nav-item-sidebar"
                  : "nav-item-sidebar"
              }
            >
              <Link to="/instrument">Instrumento</Link>
            </li>
          ) : null}

          <li
            className={
              page === "reporter"
                ? "active-sidebar nav-item-sidebar"
                : "nav-item-sidebar"
            }
          >
            <Link to="/reporter">Reporteador</Link>
          </li>
        </ul>
      </div>
    );
  }
}
