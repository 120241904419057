import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { ToastsContainer, ToastsStore } from "react-toasts";
import BlockUi from "react-block-ui";
import axios from "axios";

import logo from "../../SMART.png";
// import BASEPATH from '../../const.js';
import "./Login.css";

export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      user: [],
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      ToastsStore.info("La contraseña ha sido enviada al correo");
      const state = this.props.location.state;
      delete state.msg;
      this.props.history.replace({ ...this.props.location, state });
    } else {
      ////
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    axios
      .post(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/login`,
        {
          email: this.state.email,
          password: this.state.password,
        }
      )
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.userToken));
        localStorage.setItem("imagecode", res.data.imagecode);
        localStorage.setItem("reload", true);
        this.setState({
          loading: false,
        });
        this.props.history.push("/Home");
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        ToastsStore.error(err.response.data.msg);
      });
  };

  render() {
    const token = localStorage.getItem("token");
    if (token) {
      return <Redirect to="/Home"></Redirect>;
    } else {
      return (
        <BlockUi tag="div" blocking={this.state.loading}>
          <div className="Login">
            <div className="container">
              <div
                className="row justify-content-center align-items-center"
                id="center"
              >
                <div className="col-sm-5">
                  <div className="card">
                    <div className="card-header text-center">
                      <img src={logo}></img>
                    </div>
                    <div className="card-body">
                      <form onSubmit={this.handleSubmit} className="form">
                        <div className="form-group row">
                          <div className="col-sm-8 text-center input">
                            <input
                              type="email"
                              className="form-control"
                              ref="email"
                              name="email"
                              onChange={this.handleChange}
                              placeholder="Correo"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-8 input">
                            <input
                              type="password"
                              className="form-control"
                              ref="password"
                              name="password"
                              onChange={this.handleChange}
                              placeholder="Contraseña"
                              maxLength="12"
                              minLength="6"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group text-center">
                          <Link to="/recoverPassword">
                            Recuperar contraseña
                          </Link>
                        </div>
                        <div className="text-center">
                          <button type="submit" className="btn btn-light">
                            Ingresar
                          </button>
                        </div>
                        <ToastsContainer store={ToastsStore} />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BlockUi>
      );
    }
  }
}
