import React, { Component } from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { DemographicsForQuestion } from "./DemographicsForQuestion";
//import data from '../../../data';

Font.registerHyphenationCallback((word) => {
  const middle = Math.floor(word.length);
  const parts =
    word.length === 1 ? [word] : [word.substr(0, middle), word.substr(middle)];

  // Check console to see words parts
  console.log(word, parts);

  return parts;
});

export default class MyDocument extends Component {
  constructor() {
    super();
    this.state = {
      columns: [],
    };
  }

  render() {
    const data = JSON.parse(localStorage.getItem("data"));
    let title;
    // const date = new Date();
    // const day = date.getDate();
    // const month = date.getMonth();
    // const year = date.getFullYear();
    const array = [];
    var widthDimN = 0;
    var widthDimS = "";
    const date = data ? data.end.split("T")[0] : null;
    const dateSplit = date ? date.split("-") : null;
    const formatDate = dateSplit
      ? dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0]
      : null;
    if (this.props.type == "demographicsForQuestion") {
      if (data == null) {
      } else {
        data.digest.dimensions[0].subdimensions[0].questions[0].demographics[0].values.map(
          (value) => {
            array.push(value.name);
          }
        );
        widthDimN = array.length * 13 + 20;
        widthDimS = widthDimN + "%";

    

        console.log(widthDimS);
      }
    }

    if (this.props.type == "dimensions") {
      title = "Reporte: Resultado general por dimensión";
    }
    if (this.props.type == "demographics") {
      title = "Reporte: Resultado general por dato demográfico";
    }
    if (this.props.type == "demographicsForQuestion") {
      title = "Reporte: Pregunta por dato demográfico";
    }

    // const formatDate = day + "/" + year

    const styles = StyleSheet.create({
      body: {
        paddingTop: 35,
        paddingBottom: 30,
        paddingHorizontal: 20,
      },
      page: {
        flexDirection: 'column', 
      },
      section: {
        margin: 15,
      },
      table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: "#FFFFFF",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        margin: 10,
      },
      tableDim: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: "#FFFFFF",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginBottom: 20,
        marginLeft: 10,
        marginRight: 10,
        marginTop: 16,
      },
      table3: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        margin: "auto",
      },
      tableNps: {
        display: "table",
        width: "325px",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        margin: 10,
        marginTop: 21,
      },
      tableBaremo: {
        display: "table",
        width: "300px",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        margin: 10,
      },
      tableInfo: {
        display: "table",
        width: "300px",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 3,
        borderRightWidth: 3,
        borderBottomWidth: 3,
        margin: 10,
      },
      tableRow: {
        margin: "auto",
        flexDirection: "row",
      },
      tableColHeader: {
        width: "25%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0,
      },
      tableColHeader1: {
        width: "64%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0,
      },
      tableColHeader2: {
        width: "12%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0,
      },
      tableColHeaderBaremo: {
        width: "25%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0,
      },
      tableColHeaderTwo: {
        width: "20%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0,
      },
      tableColHeaderThree: {
        width: "13%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0,
      },
      tableColHeaderFour: {
        width: "13%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0,
      },
      tableColHeaderFive: {
        width: "13%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0,
      },
      tableCellTwo: {
        margin: "auto",
        margin: 5,
        fontSize: 8,
        fontWeight: 500,
        textAlign: "center",
      },
      tableCellTwoDim: {
        fontFamily: "Helvetica-Bold",
        margin: "auto",
        margin: 5,
        fontSize: 8,
        fontWeight: 700,
      },
      tableCellThree: {
        margin: "auto",
        margin: 5,
        fontSize: 7,
        fontWeight: 500,
        textAlign: "center",
      },
      tableCellFour: {
        margin: "auto",
        margin: 5,
        fontSize: 7,
        fontWeight: 500,
        textAlign: "center",
      },
      tableCellFive: {
        margin: "auto",
        margin: 5,
        fontSize: 7,
        fontWeight: 500,
        textAlign: "center",
      },
      tableColHeaderInfo: {
        width: "50%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0,
      },
      tableColHeaderInfo2: {
        width: "25%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0,
      },
      tableColHeaderNPS: {
        width: "33%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0,
      },
      tableCol: {
        width: "25%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0,
      },

      tableCol1: {
        width: "64%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 1,
      },

      tableCol2: {
        width: "12%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 1,
      },

      tableColInfo: {
        width: "50%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0,
      },
      tableColInfo2: {
        width: "25%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0,
      },
      tableColNps: {
        width: "33%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0,
      },

      tableColNps: {
        width: "33%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0,
      },
      tableColTwo: {
        width: "20%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0,
      },
      tableColTwoDim: {
        width: "20%",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 1,
      },
      tableColTwoDim2: {
        width: "13%",
        borderColor: "#FFFFFF",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0,
      },
      tableColFive: {
        width: "13%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0,
      },
      tableCellHeader: {
        margin: "auto",
        margin: 5,
        fontSize: 10,
        fontWeight: 500,
      },
      tableCellHeaderCent: {
        margin: "auto",
        margin: 5,
        fontSize: 9,
        fontWeight: 500,
        textAlign: "center",
      },
      tableCellHeaderBaremo: {
        margin: "auto",
        margin: 5,
        fontSize: 9,
        fontWeight: 500,
        textAlign: "center",
      },
      tableCellInfo: {
        margin: "auto",
        margin: 5,
        fontSize: 10,
        fontWeight: 500,
        textAlign: "center",
      },
      tableCellNps: {
        margin: "auto",
        margin: 5,
        fontSize: 9,
        fontWeight: 500,
        textAlign: "center",
      },
      tableCell: {
        margin: "auto",
        margin: 5,
        fontSize: 9,
      },
      tableCellCent: {
        margin: "auto",
        margin: 5,
        fontSize: 9,
        textAlign: "center",
      },
      tableCellBold: {
        margin: "auto",
        margin: 5,
        fontSize: 9,
        fontWeight: 700,
      },
      tableCellSub: {
        margin: "auto",
        margin: 5,
        fontSize: 9,
        fontWeight: 700,
      },
      breakable: { width: "100%", height: 400 },
      emphasisDimension: {
        fontFamily: "Helvetica-Bold",
        margin: "auto",
        margin: 5,
        fontSize: 10,
        fontWeight: 700,
      },
      emphasisSubdimension: {
        fontFamily: "Helvetica-Bold",
        margin: "auto",
        margin: 5,
        fontSize: 9,
        fontWeight: 700,
      },
      emphasisSubdimensionCent: {
        fontFamily: "Helvetica-Bold",
        margin: "auto",
        margin: 5,
        fontSize: 9,
        fontWeight: 700,
        textAlign: "center",
      },
      emphasisDimensionCent: {
        fontFamily: "Helvetica-Bold",
        margin: "auto",
        margin: 5,
        fontSize: 9,
        fontWeight: 700,
        textAlign: "center",
      },
      emphasisDimensionCent3: {
        fontFamily: "Helvetica-Bold",
        margin: "auto",
        margin: 5,
        fontSize: 8,
        fontWeight: 700,
        textAlign: "center",
      },
      emphasisSubdimensionCent3: {
        fontFamily: "Helvetica-Bold",
        margin: "auto",
        margin: 5,
        fontSize: 9,
        fontWeight: 700,
        textAlign: "center",
      },
      pageNumber: {
        position: "relative",
        fontSize: 9,
        top: 5,
        left: 0,
        right: 0,
        textAlign: "right",
      },
      tableCellFiveBold: {
        fontFamily: "Helvetica-Bold",
        margin: "auto",
        margin: 5,
        fontSize: 7,
        fontWeight: 500,
        textAlign: "center",
      },
    });

    const orientation = this.props.type === 'demographicsForQuestion'? 'landscape': 'portrait';

    return (
      <Document>
        <Page size="A4" style={styles.body} orientation={ orientation } >
          <View style={styles.section}>
            <Text>{data ? data.organization : null}</Text>
            <Text>{data ? (data.company ? data.company : null) : null}</Text>
            <Text>{data ? data.name : null}</Text>
            <Text></Text>
            <Text></Text>
            <Text>{title}</Text>
            <Text>{formatDate ? formatDate : null}</Text>
          </View>
          { this.props.type !== 'demographicsForQuestion'?
           <View style={styles.tableInfo}>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableColHeaderInfo,
                  { backgroundColor: "#ffffff" },
                ]}
              >
                <Text style={styles.emphasisDimensionCent}>
                  Número de Colaboradores
                </Text>
              </View>
              <View
                style={[
                  styles.tableColHeaderInfo2,
                  { backgroundColor: "#ffffff" },
                ]}
              >
                <Text style={styles.emphasisDimensionCent}>Participación</Text>
              </View>
              <View
                style={[
                  styles.tableColHeaderInfo2,
                  { backgroundColor: "#ffffff" },
                ]}
              >
                <Text style={styles.emphasisDimensionCent}>%</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColInfo}>
                <Text style={styles.tableCellCent}>
                  {data ? data.collaborators : null}
                </Text>
              </View>
              <View style={styles.tableColInfo2}>
                <Text style={styles.tableCellCent}>
                  {data ? data.results : null}
                </Text>
              </View>
              <View style={styles.tableColInfo2}>
                <Text style={styles.tableCellCent}>
                  {data && data.rate != null ? data.rate.toFixed(2) : null}
                </Text>
              </View>
            </View>
          </View>
          : null}
          {this.props.type == "dimensions" ||
          this.props.type == "demographics" ? (
            <View style={styles.tableBaremo}>
              <View style={styles.tableRow}>
                <View
                  style={[
                    styles.tableColHeader,
                    { backgroundColor: "#DADBDD" },
                  ]}
                >
                  <Text style={styles.tableCellHeaderBaremo}>Nombre</Text>
                </View>
                <View
                  style={[
                    styles.tableColHeader,
                    { backgroundColor: "#DADBDD" },
                  ]}
                >
                  <Text style={styles.tableCellHeaderBaremo}>Rango Minimo</Text>
                </View>
                <View
                  style={[
                    styles.tableColHeader,
                    { backgroundColor: "#DADBDD" },
                  ]}
                >
                  <Text style={styles.tableCellHeaderBaremo}>Rango Máximo</Text>
                </View>
                <View
                  style={[
                    styles.tableColHeader,
                    { backgroundColor: "#DADBDD" },
                  ]}
                >
                  <Text style={styles.tableCellHeaderBaremo}>Color</Text>
                </View>
              </View>
              {data
                ? data.digest.scale.scales.map((scales, index) => {
                    console.log(scales, "*******************");
                    return (
                      <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {data.digest.scale.name}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCellCent}>
                            {scales.min.toFixed(2)}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCellCent}>
                            {index !== data.digest.scale.scales.length - 1
                              ? "< " + scales.max.toFixed(2)
                              : scales.max.toFixed(2)}
                          </Text>
                        </View>
                        <View
                          style={[
                            styles.tableCol,
                            { backgroundColor: scales.color },
                          ]}
                        >
                          <Text style={styles.tableCellCent}>{scales.tag}</Text>
                        </View>
                      </View>
                    );
                  })
                : null}
            </View>
          ) : null}
          {this.props.type == "dimensions" ||
          this.props.type == "demographics" ? (
            <View style={styles.section}>
              <Text></Text>
              <Text></Text>
            </View>
          ) : null}
          {this.props.type == "dimensions" ||
          this.props.type == "demographics" ? (
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View
                  style={[
                    styles.tableColHeader1,
                    { backgroundColor: "#DADBDD" },
                  ]}
                >
                  <Text style={styles.emphasisDimension}>
                    Resultado General
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableColHeader2,
                    { backgroundColor: "#DADBDD" },
                  ]}
                >
                  <Text style={styles.emphasisDimensionCent}>Puntaje</Text>
                </View>
                <View
                  style={[
                    styles.tableColHeader2,
                    { backgroundColor: "#DADBDD" },
                  ]}
                >
                  <Text style={styles.emphasisDimensionCent}>Pentil</Text>
                </View>
                <View
                  style={[
                    styles.tableColHeader2,
                    { backgroundColor: "#DADBDD" },
                  ]}
                >
                  <Text style={styles.emphasisDimensionCent}>Categoria</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>
                    {data ? data.name : null}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCellCent}>
                    {data ? data.digest.general.average.toFixed(2) : null}
                  </Text>
                </View>
                {data
                  ? data.digest.scale.scales.map((scale, index) => {
                      var averageGen = null;
                      if (data.digest.general.average.toFixed(2)) {
                        averageGen = data.digest.general.average.toFixed(2);
                      }
                      if (averageGen > scale.min && averageGen <= scale.max) {
                        return (
                          <View style={styles.tableCol2}>
                            <Text style={styles.tableCellCent}>
                              {index + 1}
                            </Text>
                          </View>
                        );
                      }
                    })
                  : null}
                <View
                  style={[
                    styles.tableCol2,
                    {
                      backgroundColor: data ? data.digest.general.color : null,
                    },
                  ]}
                >
                  <Text style={styles.tableCellCent}>
                    {data ? data.digest.general.tag : null}
                  </Text>
                </View>
              </View>
            </View>
          ) : null}
          {this.props.type == "dimensions" || this.props.type == "demographics"
            ? null
            : // <View style={styles.section}>
              //     <Text></Text>
              //     <Text></Text>
              // </View>
              null}
          {this.props.type == "dimensions" ? (
            <View style={styles.tableDim}>
              <View style={styles.tableRow}>
                <View
                  style={[
                    styles.tableColHeader1,
                    { backgroundColor: "#DADBDD" },
                  ]}
                >
                  <Text style={styles.emphasisDimension}>
                    Resumen por Dimensión
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableColHeader2,
                    { backgroundColor: "#DADBDD" },
                  ]}
                >
                  <Text style={styles.emphasisDimensionCent}>Puntaje</Text>
                </View>
                <View
                  style={[
                    styles.tableColHeader2,
                    { backgroundColor: "#DADBDD" },
                  ]}
                >
                  <Text style={styles.emphasisDimensionCent}>Pentil</Text>
                </View>
                <View
                  style={[
                    styles.tableColHeader2,
                    { backgroundColor: "#DADBDD" },
                  ]}
                >
                  <Text style={styles.emphasisDimensionCent}>Categoria</Text>
                </View>
              </View>
              {data
                ? data.digest.dimensions.map((dimension) => {
                    return (
                      <View style={styles.tableRow} wrap={false}>
                        <View style={styles.tableCol1}>
                          <Text style={styles.tableCell}>{dimension.name}</Text>
                        </View>
                        <View style={styles.tableCol2}>
                          <Text style={styles.tableCellCent}>
                            {dimension.average.toFixed(2)}
                          </Text>
                        </View>
                        {data.digest.scale.scales.map((scale, index) => {
                          var averageDim = dimension.average.toFixed(2);
                          if (
                            averageDim > scale.min &&
                            averageDim <= scale.max
                          ) {
                            return (
                              <View style={styles.tableCol2}>
                                <Text style={styles.tableCellCent}>
                                  {index + 1}
                                </Text>
                              </View>
                            );
                          }
                        })}
                        <View
                          style={[
                            styles.tableCol2,
                            { backgroundColor: dimension.color },
                          ]}
                        >
                          <Text style={styles.tableCellCent}>
                            {dimension.tag}
                          </Text>
                        </View>
                      </View>
                    );
                  })
                : null}

              {/* <View style={styles.section}>
                                <Text></Text>
                                <Text></Text>
                            </View> */}
            </View>
          ) : null}
          {this.props.type == "dimensions" ? (
            <View>
              {data
                ? data.digest.dimensions.map((dimension) => {
                    return (
                      <View>
                        <View style={styles.tableDim}>
                          <View style={styles.tableRow} wrap={false}>
                            <View
                              style={[
                                styles.tableColHeader1,
                                { backgroundColor: "#DADBDD" },
                              ]}
                            >
                              <Text style={styles.emphasisDimension}>
                                Resultado: {dimension.name}
                              </Text>
                            </View>
                            <View
                              style={[
                                styles.tableColHeader2,
                                { backgroundColor: "#DADBDD" },
                              ]}
                            >
                              <Text style={styles.emphasisDimensionCent}>
                                Puntaje
                              </Text>
                            </View>
                            <View
                              style={[
                                styles.tableColHeader2,
                                { backgroundColor: "#DADBDD" },
                              ]}
                            >
                              <Text style={styles.emphasisDimensionCent}>
                                Pentil
                              </Text>
                            </View>
                            <View
                              style={[
                                styles.tableColHeader2,
                                { backgroundColor: "#DADBDD" },
                              ]}
                            >
                              <Text style={styles.emphasisDimensionCent}>
                                Categoria
                              </Text>
                            </View>
                          </View>
                          <View style={styles.tableRow} wrap={false}>
                            <View style={styles.tableCol1}>
                              <Text style={[styles.emphasisDimension]}>
                                {dimension.name}
                              </Text>
                            </View>
                            <View style={styles.tableCol2}>
                              <Text style={styles.emphasisDimensionCent}>
                                {dimension.average.toFixed(2)}
                              </Text>
                            </View>
                            {data.digest.scale.scales.map((scale, index) => {
                              var averageDim = dimension.average.toFixed(2);
                              if (
                                averageDim > scale.min &&
                                averageDim <= scale.max
                              ) {
                                return (
                                  <View style={styles.tableCol2}>
                                    <Text style={styles.emphasisDimensionCent}>
                                      {index + 1}
                                    </Text>
                                  </View>
                                );
                              }
                            })}
                            <View
                              style={[
                                styles.tableCol2,
                                { backgroundColor: dimension.color },
                              ]}
                            >
                              <Text style={styles.emphasisDimensionCent}>
                                {dimension.tag}
                              </Text>
                            </View>
                          </View>
                          {dimension.subdimensions.map((subdimension) => {
                            return (
                              <View>
                                <View style={styles.tableRow} wrap={false}>
                                  <View style={styles.tableCol1}>
                                    <Text style={styles.emphasisSubdimension}>
                                      {subdimension.name}
                                    </Text>
                                  </View>
                                  <View style={styles.tableCol2}>
                                    <Text
                                      style={styles.emphasisSubdimensionCent}
                                    >
                                      {subdimension.average.toFixed(2)}
                                    </Text>
                                  </View>
                                  {data.digest.scale.scales.map(
                                    (scale, index) => {
                                      var averageSub = subdimension.average.toFixed(
                                        2
                                      );
                                      if (
                                        averageSub > scale.min &&
                                        averageSub <= scale.max
                                      ) {
                                        return (
                                          <View style={styles.tableCol2}>
                                            <Text
                                              style={
                                                styles.emphasisSubdimensionCent
                                              }
                                            >
                                              {index + 1}
                                            </Text>
                                          </View>
                                        );
                                      }
                                    }
                                  )}
                                  <View
                                    style={[
                                      styles.tableCol2,
                                      { backgroundColor: subdimension.color },
                                    ]}
                                  >
                                    <Text
                                      style={styles.emphasisSubdimensionCent}
                                    >
                                      {subdimension.tag}
                                    </Text>
                                  </View>
                                </View>
                                {subdimension.questions.map((question) => {
                                  return (
                                    <View style={styles.tableRow} wrap={false}>
                                      <View style={styles.tableCol1}>
                                        <Text style={styles.tableCell}>
                                          {question.text}
                                        </Text>
                                      </View>
                                      <View style={styles.tableCol2}>
                                        <Text style={styles.tableCellCent}>
                                          {question.average.toFixed(2)}
                                        </Text>
                                      </View>
                                      {data.digest.scale.scales.map(
                                        (scale, index) => {
                                          var averageQues = question.average.toFixed(
                                            2
                                          );
                                          if (
                                            averageQues > scale.min &&
                                            averageQues <= scale.max
                                          ) {
                                            return (
                                              <View style={styles.tableCol2}>
                                                <Text
                                                  style={styles.tableCellCent}
                                                >
                                                  {index + 1}
                                                </Text>
                                              </View>
                                            );
                                          }
                                        }
                                      )}

                                      <View
                                        style={[
                                          styles.tableCol2,
                                          { backgroundColor: question.color },
                                        ]}
                                      >
                                        <Text style={styles.tableCellCent}>
                                          {question.tag}
                                        </Text>
                                      </View>
                                    </View>
                                  );
                                })}
                              </View>
                            );
                          })}
                        </View>
                      </View>
                    );
                  })
                : null}

              <View style={styles.section}>
                <Text></Text>
                <Text></Text>
              </View>
            </View>
          ) : null}
          {this.props.type == "demographics"
            ? data
              ? data.digest.demographics.map((demographic) => {
                  return (
                    <View style={styles.table}>
                      <View style={styles.tableRow} wrap={false}>
                        <View
                          style={[
                            styles.tableColHeader1,
                            { backgroundColor: "#DADBDD" },
                          ]}
                        >
                          <Text style={styles.emphasisDimension}>
                            Resultados: {demographic.data}
                          </Text>
                        </View>
                        <View
                          style={[
                            styles.tableColHeader2,
                            { backgroundColor: "#DADBDD" },
                          ]}
                        >
                          <Text style={styles.emphasisDimensionCent}>
                            Puntaje
                          </Text>
                        </View>
                        <View
                          style={[
                            styles.tableColHeader2,
                            { backgroundColor: "#DADBDD" },
                          ]}
                        >
                          <Text style={styles.emphasisDimensionCent}>
                            Pentil
                          </Text>
                        </View>
                        <View
                          style={[
                            styles.tableColHeader2,
                            { backgroundColor: "#DADBDD" },
                          ]}
                        >
                          <Text style={styles.emphasisDimensionCent}>
                            Categoria
                          </Text>
                        </View>
                      </View>
                      {demographic.values.map((value) => {
                        return (
                          <View style={styles.tableRow} wrap={false}>
                            <View style={styles.tableCol1}>
                              <Text style={styles.tableCell}>
                                {value.value}
                              </Text>
                            </View>
                            <View style={styles.tableCol2}>
                              <Text style={styles.tableCellCent}>
                                {value.average.toFixed(2)}
                              </Text>
                            </View>
                            {data.digest.scale.scales.map((scale, index) => {
                              var averageDem = value.average.toFixed(2);
                              if (
                                averageDem > scale.min &&
                                averageDem <= scale.max
                              ) {
                                return (
                                  <View style={styles.tableCol2}>
                                    <Text style={styles.tableCellCent}>
                                      {index + 1}
                                    </Text>
                                  </View>
                                );
                              }
                            })}
                            <View
                              style={[
                                styles.tableCol2,
                                { backgroundColor: value.color },
                              ]}
                            >
                              <Text
                                style={[
                                  styles.tableCellCent,
                                  { backgroundColor: value.color },
                                ]}
                              >
                                {value.tag}
                              </Text>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  );
                })
              : null
            : null}
          <View style={styles.section}>
            <Text></Text>
            <Text></Text>
          </View>
          {this.props.type == "dimensions" ? (
            <View style={styles.tableNps}>
              <View style={styles.tableRow}>
                <View
                  style={[
                    styles.tableColHeaderNPS,
                    { backgroundColor: "#DADBDD" },
                  ]}
                >
                  <Text style={styles.tableCellHeaderCent}>
                    Net Promoter Score
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableColHeaderNPS,
                    { backgroundColor: "#085795" },
                  ]}
                >
                  <Text style={styles.tableCellHeaderCent}>% Promotores</Text>
                </View>
                <View
                  style={[
                    styles.tableColHeaderNPS,
                    { backgroundColor: "#ff0000" },
                  ]}
                >
                  <Text style={styles.tableCellHeaderCent}>% Detractores</Text>
                </View>
                <View
                  style={[
                    styles.tableColHeaderNPS,
                    { backgroundColor: "#e5be01 " },
                  ]}
                >
                  <Text style={styles.tableCellHeaderCent}>
                    Net Promoter Score
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableColHeaderNPS,
                    { backgroundColor: "#DADBDD" },
                  ]}
                >
                  <Text style={styles.tableCellHeaderCent}>Categoria</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableColNps}>
                  <Text style={styles.tableCellNps}>
                    {data ? data.name : null}
                  </Text>
                </View>
                <View style={styles.tableColNps}>
                  <Text style={styles.tableCellNps}>
                    {data ? data.nps.promoters.toFixed(2) + "%" : null}
                  </Text>
                </View>
                <View style={styles.tableColNps}>
                  <Text style={styles.tableCellNps}>
                    {data ? data.nps.detractors.toFixed(2) + "%" : null}
                  </Text>
                </View>
                <View style={styles.tableColNps}>
                  <Text style={styles.tableCellNps}>
                    {data ? data.nps.value.toFixed(2) : null}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableColNps,
                    { backgroundColor: data ? data.nps.color : null },
                  ]}
                >
                  <Text style={styles.tableCellNps}>
                    {data ? data.nps.tag : null}
                  </Text>
                </View>
              </View>
            </View>
          ) : null}

          {this.props.type == "demographicsForQuestion"
            ? data
              ? data.digest.dimensions.map((dimension, index) => {
                  return (
                    <DemographicsForQuestion
                      key={ index }
                      dimension={ dimension }
                      styles={ styles }
                      widthDimS={ widthDimS }
                      array={ array }
                    >
                    </DemographicsForQuestion>
                  );
                })
              : null
            : null}

          {/* {this.props.type == "demographicsForQuestion"
                        ?
                        data ? data.digest.dimensions.map(dimension => {
                            return (
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={[styles.tableColHeaderTwo, { backgroundColor: '#DADBDD' }]}>
                                            <Text style={styles.tableCellTwo}>Resumen: {dimension.name}</Text>
                                        </View>
                                        {array.map(value => {
                                            return (
                                                <View style={[styles.tableColHeaderFive, { backgroundColor: '#DADBDD' }]}>
                                                    <Text style={styles.tableCellFive}>{value}</Text>
                                                </View>
                                            )
                                        })}
                                    </View>
                                </View>
                            )
                        }) : null
                        : null} */}

          {/* {data ? data.digest.dimensions.map(dimension => {
                                return (
                                    <View>
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableColTwo}>
                                                <Text style={styles.tableCellTwo}>{dimension.name}</Text>
                                            </View>

                                            {array.map(columns => {
                                                return (
                                                    <View style={[styles.tableColFive, { backgroundColor: '#DADBDD' }]}>
                                                        <Text style={styles.tableCellFive} >{""}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                        {dimension.subdimensions.map(subdimension => {
                                            return (
                                                <View>
                                                    <View style={styles.tableRow}>
                                                        <View style={styles.tableColTwo}>
                                                            <Text style={styles.tableCellTwo}>{subdimension.name}</Text>
                                                        </View>

                                                        {array.map(column => {
                                                            return (
                                                                <View style={[styles.tableColFive, { backgroundColor: '#DADBDD' }]}>
                                                                    <Text style={styles.tableCellFive} >{""}</Text>
                                                                </View>
                                                            )
                                                        })}
                                                    </View>
                                                    {subdimension.questions.map(question => {
                                                        return (
                                                            <View>
                                                                <View style={styles.tableRow}>
                                                                    <View style={styles.tableColTwo}>
                                                                        <Text style={styles.tableCellTwo}>{question.text}</Text>
                                                                    </View>

                                                                    {question.demographics[0].values.map(value => {
                                                                        return (
                                                                            <View style={[styles.tableColFive, { backgroundColor: value.color }]}>
                                                                                <Text style={styles.tableCellFive}>{value.average.toFixed(2)}</Text>
                                                                                <Text style={styles.tableCellFive}>{value.tag}</Text>
                                                                            </View>
                                                                        )
                                                                    })}
                                                                </View>
                                                            </View>
                                                        )
                                                    })}
                                                </View>
                                            )
                                        })}
                                    </View>
                                )
                            }) : null}
                        : null} */}

          <View style={styles.section}>
            <Text></Text>
            <Text></Text>
          </View>

          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
      </Document>
    );
  }
}
