import React, { Component } from "react";
import Modal from "react-bootstrap4-modal";
import { ToastsContainer, ToastsStore } from "react-toasts";

import axios from "axios";

import "./ModalAddParticipants.css";

export default class ModalAddParticipans extends Component {
  constructor() {
    super();
    this.state = {
      coll: [],
    };
  }
  columns = ["#", "Nombre", "Correo", "Cedula"];
  repeat = false;
  repeatDNI = false;
  render() {
    console.log(this.props.noRepeatColl);
    return (
      <div className="Modal">
        <Modal visible={this.props.show} onClickBackdrop={this.props.onCancel}>
          <div className="modal-header">
            <h5 className="modal-title">Anexar colaboradores a instrumento</h5>
          </div>
          <div className="modal-body">
            <div className="name-instrument">
              <div
                style={{
                  marginRight: "5px",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Nombre del instrumento:{" "}
              </div>
              <div
                style={{
                  fontSize: "12px",
                }}
              >
                {" "}
                {this.props.name}
              </div>
            </div>
            <div className="total-collaborator">
              <div
                style={{
                  marginRight: "5px",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Total colaboradores a analizar
              </div>
              <div
                style={{
                  fontSize: "12px",
                }}
              >
                {this.props.noRepeatColl.length +
                  this.props.repeatEmailsColl.length}
              </div>
            </div>
            <div className="total-collaborator-repeat">
              <div
                style={{
                  marginRight: "5px",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Total colaboradores repetidos
              </div>
              <div
                style={{
                  fontSize: "12px",
                }}
              >
                {this.props.repeatEmailsColl.length}
              </div>
            </div>
            <div className="col-md-12 table-scroll">
              <table class="table sticky">
                <thead className="table-head">
                  <tr>
                    {this.columns.map((column) => {
                      return (
                        <>
                          <th
                            key={column}
                            className="table-head-text"
                            style={{ position: "sticky" }}
                          >
                            <span
                              style={{ float: "left", paddingLeft: "10px" }}
                            >
                              {" "}
                              {column}
                            </span>
                          </th>
                        </>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {this.props.repeatEmailsColl.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td width="15%" className="table-row-text">
                          <span style={{ float: "left" }}>{index + 1}</span>
                        </td>
                        <td width="35%" className="table-row-text">
                          <span style={{ float: "left" }}> {row.name}</span>
                        </td>
                        <td width="35%" className="table-row-text">
                          <span style={{ float: "left" }}>
                            {" "}
                            {row.email ? row.email : "N/A"}
                          </span>
                        </td>
                        <td width="15%" className="table-row-text">
                          <span style={{ float: "left" }}> {row.dni}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <hr />
            <div className="total-collaborator-repeat-2">
              <div
                style={{
                  marginRight: "5px",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Total colaboradores para agregar
              </div>
              <div
                style={{
                  fontSize: "12px",
                }}
              >
                {this.props.noRepeatColl.length}
              </div>
            </div>
            <div className="col-md-12 table-scroll">
              <table class="table sticky">
                <thead className="table-head">
                  <tr>
                    {this.columns.map((column) => {
                      return (
                        <>
                          <th
                            key={column}
                            className="table-head-text"
                            style={{ position: "sticky" }}
                          >
                            <span
                              style={{ float: "left", paddingLeft: "10px" }}
                            >
                              {" "}
                              {column}
                            </span>
                          </th>
                        </>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {this.props.noRepeatColl.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td width="15%" className="table-row-text">
                          <span style={{ float: "left" }}>{index + 1}</span>
                        </td>
                        <td width="35%" className="table-row-text">
                          <span style={{ float: "left" }}> {row.name}</span>
                        </td>
                        <td width="35%" className="table-row-text">
                          {row.email ? (
                            this.props?.repeatEmailsForValidation.length > 0 ? (
                              this.props?.repeatEmailsForValidation.map(
                                (repeat) => {
                                  if (row.email === repeat) {
                                    this.repeat = true;
                                    return (
                                      <span
                                        style={{
                                          float: "left",
                                          color: "red",
                                        }}
                                      >
                                        {" "}
                                        {row.email}
                                      </span>
                                    );
                                  } else {
                                    return (
                                      <span
                                        style={{
                                          float: "left",
                                        }}
                                      >
                                        {" "}
                                        {row.email}
                                      </span>
                                    );
                                  }
                                }
                              )
                            ) : (
                              <span
                                style={{
                                  float: "left",
                                }}
                              >
                                {" "}
                                {row.email}
                              </span>
                            )
                          ) : (
                            <span
                              style={{
                                float: "left",
                              }}
                            >
                              {" "}
                              N/A
                            </span>
                          )}
                        </td>
                        <td width="15%" className="table-row-text">
                          {this.props?.repeatDNIForValidation.length > 0 ? (
                            this.props?.repeatDNIForValidation.map((repeat) => {
                              if (row.dni === repeat) {
                                this.repeatDNI = true;
                                return (
                                  <span
                                    style={{
                                      float: "left",
                                      color: "red",
                                    }}
                                  >
                                    {" "}
                                    {row.dni}
                                  </span>
                                );
                              } else {
                                return (
                                  <span
                                    style={{
                                      float: "left",
                                    }}
                                  >
                                    {" "}
                                    {row.dni}
                                  </span>
                                );
                              }
                            })
                          ) : (
                            <span style={{ float: "left" }}> {row.dni}</span>
                          )}

                          {/* <span style={{ float: "left" }}> {row.dni}</span> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <div
              style={{
                marginRight: "15px",
                display: "flex",
                textAlign: "center",
              }}
            >
              <div style={{ display: "flex", paddingTop: "12px" }}>
                {this.props?.noRepeatColl.length > 0 ? null : (
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "red",
                      marginRight: "20px",
                    }}
                  >
                    No hay colaboradores por agregar
                  </p>
                )}
              </div>
              <div style={{ display: "flex", paddingTop: "12px" }}>
                {this.repeat ? (
                  <p style={{ fontWeight: "bold", color: "red" }}>
                    Hay correos duplicados
                  </p>
                ) : null}
              </div>
              <div
                style={{
                  display: "flex",
                  paddingTop: "12px",
                  marginLeft: "10px",
                }}
              >
                {this.repeatDNI ? (
                  <p style={{ fontWeight: "bold", color: "red" }}>
                    Hay cedulas duplicadas
                  </p>
                ) : null}
              </div>
            </div>
            <button
              type="button"
              className="btn btn-accept"
              onClick={this.props.addParticipants}
              disabled={
                this.props.noRepeatColl.length === 0 ||
                this.repeat ||
                this.repeatDNI
                  ? true
                  : false
              }
            >
              Anexar
            </button>
            <button
              type="button"
              className="btn "
              onClick={this.props.onCancel}
            >
              Cancelar
            </button>
          </div>
          <ToastsContainer store={ToastsStore} />
        </Modal>
      </div>
    );
  }
}
