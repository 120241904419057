import React, { Component } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom'
import { ToastsContainer, ToastsStore } from 'react-toasts';
import ReactTooltip from 'react-tooltip';
import logo from '../../LogoBe&co.png';

import TimeLoader from '../TimeLoader/TimeLoader.jsx';
import ModalDelete from '../ModalDelete/ModalDelete.jsx'
import UploadImage from '../UpdateImage/Update.Image.jsx';
import msgObj from '../../const.js'
import versionTooltip from '../../version.js'
import token from '../../token.js';
import Table from '../Table/Table.jsx';
import Sidebar from '../Sidebar/Sidebar.jsx';
import Modale from '../ModalForm/ModalForm.jsx'

import '../UserManagement/UserManagement.css';

export default class UserManagement extends Component {

    constructor() {
        super();
        this.state = {
            _idPrivileges: '',
            _id: '',
            _idDelete: '',
            organization: "Organización",
            brand: 'Marca',
            company: 'Unidad Organizativa / Marca',
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            role: "Rol",
            roles: [],
            companies: [],
            organizations: [],
            users: [],
            data: [],
            show: false,
            showDelete: false,
            sidebarCollapse: true,
            validateFirstName: true,
            validateLastName: true,
            validatePassword: true,
            validateEmail: true,
            status: false,
            image: '',
            organizationsLogo: [],
            adminGroups: []
        }
    }

    componentDidMount() {
        $("#menu-toggle").click(function (e) {
            e.preventDefault();
            $("#wrapper").toggleClass("toggled");
        });

        this.getRoles();
        this.getCompanies();
        this.getUsers();
        this.getOrganizations();
        this.getLogo();
        this.getAdminGroups();
    }

    getLogo = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        axios
            .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/organizations`, { headers: { 'x-auth-token': token } })
            .then(res => {
                this.setState({
                    organizationsLogo: res.data.organizations
                });
                this.state.organizationsLogo.map(organization => {
                    if (organization._id == user.company.organization._id) {
                        this.setState({
                            image: organization.logo.imagecode
                        });
                    }
                });
            });
    };

    isChecked = () => {
        this.setState({
            status: !this.state.status
        })
    }

    collapseSidebar = () => {
        this.setState({
            sidebarCollapse: !this.state.sidebarCollapse
        })
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        })
    }

    handleChangeFirstName = (event) => {
        const { name, value } = event.target;
        const rules = /^[a-zA-ZñÑáéíóúüÁÉÍÓÚÜ\s]*$/g
        const firstName = this.refs.firstName.value;
        const validateFirstName = rules.test(firstName);
        this.setState({
            [name]: value,
            validateFirstName
        })
    }

    handleChangePassword = (event) => {
        const { name, value } = event.target;
        const rules = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{6,12}$/
        const password = this.refs.password.value;
        const validatePassword = rules.test(password);
        this.setState({
            [name]: value,
            validatePassword
        })
    }

    handleChangeEmail = (event) => {
        const { name, value } = event.target;
        const rules = /^^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/g
        const email = this.refs.email.value;
        const validateEmail = rules.test(email);
        this.setState({
            [name]: value,
            validateEmail
        })
    }

    logOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem("image");
    }

    handleChangeLastName = (event) => {
        const { name, value } = event.target;
        const rules = /^[a-zA-ZñÑáéíóúüÁÉÍÓÚÜ\s]*$/g
        const lastName = this.refs.lastName.value;
        const validateLastName = rules.test(lastName);
        this.setState({
            [name]: value,
            validateLastName
        })
    }

    clearForm = () => {
        this.setState({
            _id: '',
            adminGroup: '',
            organization: "Organización",
            brand: 'Marca',
            company: 'Unidad Organizativa / Marca',
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            role: "Rol",
            status: false
        })
    }

    ShowAndHide = (id) => {
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/users/${id}`, { headers: { 'x-auth-token': token } })
            .then(res => {
                this.setState({
                    user: res.data.user
                })
            })
        this.setState({
            show: !this.state.show
        })
    }

    showModal = (id) => {
        this.setState({
            _idDelete: id,
            showDelete: !this.state.showDelete,
        })
    }

    getAdminGroups = () => {
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/admingroups`, { headers: { 'x-auth-token': token } })
            .then(res => this.setState({
                adminGroups: res.data.adminGroups
            }))
    }

    addUser = (event) => {
        event.preventDefault();
        if (this.state._id) {
            const objRequest = {
                company: {
                    "_id": this.state.company
                },
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                password: this.state.password,
                role: {
                    "_id": this.state.role
                },
                status: this.state.status
            }
            axios.put(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/users/${this.state._id}`, objRequest, { headers: { 'x-auth-token': token } })
                .then(res => {
                    this.setState({
                        organization: "Organización",
                        brand: 'Marca',
                        company: 'Unidad Organizativa / Marca',
                        firstName: "",
                        lastName: "",
                        email: "",
                        password: "",
                        role: "Rol",
                        status: false
                    })
                    this.getUsers();
                    ToastsStore.success(res.data.msg);
                })
                .catch(err => {
                    ToastsStore.error(err.response.data.msg)
                });
        }
        else {
            const objRequest = {
                company: {
                    "_id": this.state.company
                },
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                password: this.state.password,
                role: {
                    "_id": this.state.role
                },
                status: this.state.status
            }
            axios.post(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/users`, objRequest, { headers: { 'x-auth-token': token } })
                .then(res => {
                    this.setState({
                        organization: "Organización",
                        brand: 'Marca',
                        company: 'Unidad Organizativa / Marca',
                        firstName: "",
                        lastName: "",
                        email: "",
                        password: "",
                        role: "Rol",
                        status: false,
                    });
                    this.getUsers();
                    ToastsStore.success(res.data.msg);
                })
                .catch(err => {
                    ToastsStore.error(err.response.data.msg);
                });
        }


    }

    getRoles = () => {
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/roles`, { headers: { 'x-auth-token': token } })
            .then(res => this.setState({
                roles: res.data.roles
            }))
    }

    getOrganizations = () => {
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/organizations`, { headers: { 'x-auth-token': token } })
            .then(res => this.setState({
                organizations: res.data.organizations
            }))
    }

    getCompanies = () => {
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/companies`, { headers: { 'x-auth-token': token } })
            .then(res => this.setState({
                companies: res.data.companies
            }))
    }

    getUsers = () => {
        this.setState({
            data: []
        })
        const data = Object.assign([], this.state.data)
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/users`, { headers: { 'x-auth-token': token } })
            .then(res => {
                this.setState({
                    users: res.data.users
                })

                this.state.users.map(user => {
                    console.log(user.status);
                    data.push({
                        "_id": user._id,
                        "company": user.company.organization.name + " - " + user.company.name,
                        "firstName": user.firstName + " " + user.lastName,
                        "role": user.role.name,
                        "status": user.status
                    })
                })

                this.setState({
                    data
                })
            })
            .catch(err => {
                ToastsStore.error(err.response.data.msg);
            })
    }

    deleteUser = (id) => {
        axios.delete(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/users/${this.state._idDelete}`, { headers: { 'x-auth-token': token } })
            .then(res => {
                this.getUsers();
                ToastsStore.success(res.data.msg);
                this.setState({
                    _idDelete: '',
                    showDelete: !this.state.showDelete
                })
            })
            .catch(err => {
                ToastsStore.error(err.response.data.msg)
            });
    }

    editUser = (id) => {
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/users/${id}`, { headers: { 'x-auth-token': token } })
            .then(res => this.setState({
                _id: id,
                adminGroup: res.data.user.company.organization.admingroup._id,
                organization: res.data.user.company.organization._id,
                brand: !res.data.user.company.brand ? null : res.data.user.company.brand._id,
                company: res.data.user.company._id,
                firstName: res.data.user.firstName,
                lastName: res.data.user.lastName,
                email: res.data.user.email,
                password: res.data.user.password,
                role: res.data.user.role._id,
                status: res.data.user.status

            }))

    }


    render() {
        const user = JSON.parse(localStorage.getItem('user'));
        const token = localStorage.getItem('token');
        const formatWithIcon = (cell, row) => {
            return (
                <div>
                    <i
                        data-tip={msgObj.update}
                        data-for="update"
                        className="fas fa-edit"
                        aria-hidden="true"
                        onClick={() => this.editUser(row._id)}></i>
                    <ReactTooltip
                        place="top"
                        id="update" />
                    &nbsp;&nbsp;
                    {user.role.name == 'Administrador Empresas'
                        ? null
                        : <i
                            className="fa fa-trash"
                            data-tip={msgObj.delete}
                            data-for="delete"
                            aria-hidden="true"
                            onClick={() => this.showModal(row._id)}></i>}
                    <ReactTooltip
                        place="top"
                        id="delete" />
                </div>
            )
        }

        const formatWithStatus = (cell, row) => {
            return (
                <div>
                    {row.status
                        ? <i
                            className="fas fa-circle status-active"
                            aria-hidden="true"
                            data-tip={msgObj.enable}
                            data-for="enable"
                        >
                        </i>
                        : <i
                            className="fas fa-circle status-inactive"
                            aria-hidden="true"
                            data-tip={msgObj.disable}
                            data-for="disable"></i>
                    }
                    <ReactTooltip
                        place="top"
                        id={row.status ? "enable" : "disable"} />
                </div>
            )
        }

        const columns = [
            {
                dataField: 'status',
                text: '',
                formatter: formatWithStatus
            },
            {
                dataField: 'company',
                text: 'Compañía',
                sort: true
            },
            {
                dataField: 'firstName',
                text: 'Nombre',
                sort: true
            },
            {
                dataField: 'role',
                text: 'Rol',
                sort: true
            },
            {
                dataField: 'deleteAction',
                text: '',
                formatter: formatWithIcon
            }]

        if (!token) {
            return (
                <Redirect to='/'>

                </Redirect>
            )
        } else {
            return (
                <div className="UserManagement">
                    <TimeLoader redirect={this.props.history} />
                    <div className="wrapper" id="wrapper">
                        <Sidebar
                            page="gestion"
                            role={user.role}
                            image={this.state.image}
                        />
                        <div id="page-content-wrapper">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                                            <button
                                                className="navbar-toggler"
                                                type="button"
                                                data-toggle="collapse"
                                                data-target="#navbarNav"
                                                aria-controls="navbarNav"
                                                aria-expanded="false"
                                                aria-label="Toggle navigation">
                                                <span className="navbar-toggler-icon"></span>
                                            </button>
                                            <div
                                                id="navbarNav"
                                                className="collapse navbar-collapse">
                                                <ul className="nav navbar-nav mr-auto">
                                                    <li className="nav-item">
                                                        <a className="nav-link"
                                                            data-tip={versionTooltip}
                                                            data-for="version"
                                                            href="#menu-toggle"
                                                            id="menu-toggle"
                                                            onClick={this.collapseSidebar}>
                                                            <i className="fa fa-bars"></i></a>
                                                        <ReactTooltip place="top"
                                                            id="version" />
                                                    </li>
                                                    <li
                                                        className="nav-item"
                                                        data-tip={msgObj.home}
                                                        data-for="home">
                                                        {!this.state.sidebarCollapse ?
                                                            <Link to="Home">
                                                                <img className="logoNavbar" src={this.state.image}></img>
                                                            </Link>

                                                            :
                                                            <Link to="/Home"><i className='fas fa-home'></i></Link>
                                                        }
                                                    </li>
                                                    <ReactTooltip
                                                        place="top"
                                                        id="home" />
                                                </ul>
                                                <ul className="nav navbar-nav mx-auto">


                                                </ul>
                                                <ul className="nav navbar-nav ml-auto">
                                                    <li className="nav-item">
                                                        <span>{user.firstName + " " + user.lastName}</span>
                                                        &nbsp;
                                            </li>
                                                    {
                                                        user.role.name == 'Administrador Empresas'
                                                            ?
                                                            <li className="nav-item">
                                                                <UploadImage />
                                                            </li>
                                                            :
                                                            null
                                                    }
                                                    &nbsp;&nbsp;
                                             <li className="nav-item">
                                                        <Link
                                                            data-tip="Cerrar sesión"
                                                            to="/"><i
                                                                className="fas fa-sign-out-alt"
                                                                onClick={this.logOut}></i>
                                                        </Link>
                                                        <ReactTooltip />
                                                    </li>
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        <br />
                                        <h1>Gestión de Usuario</h1>
                                    </div>
                                </div>
                                <hr></hr>
                                <div className="row">
                                    <div className="col-md-3">
                                        <form onSubmit={this.addUser} autocomplete="off">
                                            <div className="row form-group">
                                                <div className="col-md-12">
                                                    <select
                                                        name="adminGroup"
                                                        ref="adminGroup"
                                                        className="form-control"
                                                        onChange={this.handleChange}
                                                        value={this.state.adminGroup}
                                                        required>
                                                        <option value="" selected disabled>Grupo Administrador</option>
                                                        {this.state.adminGroups.map(group => {
                                                            return (
                                                                <option key={group._id}
                                                                    value={group._id}
                                                                >{group.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row form-group">
                                                <div className="col-md-12">
                                                    <select
                                                        name="organization"
                                                        ref="organization"
                                                        className="form-control"
                                                        onChange={this.handleChange}
                                                        value={this.state.organization}
                                                        required>
                                                        <option value="Organización" disabled>Organización</option>
                                                        {this.state.organizations.map(organization => {
                                                            if (this.state.adminGroup == organization.admingroup._id) {
                                                                return (
                                                                    <option key={organization._id}
                                                                        value={organization._id}
                                                                    >{organization.name}</option>
                                                                )
                                                            }
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            {/* <div className="row form-group">
                                                 <div className="col-md-12">
                                                     <select
                                                     name = "brand"
                                                     ref = "brand"
                                                     className = "form-control"
                                                     onChange = {this.handleChange}
                                                     value={this.state.brand}
                                                     required>
                                                         <option value="Marca" disabled>Marca</option>
                                                         {!this.state.companies.brand ? null : this.state.companies.map(company => {
                                                             return(
                                                                 <option key={company.brand._id}
                                                                 value={company.brand._id}>{company.brand.name}</option>
                                                             )
                                                         })}
                                                     </select>
                                                 </div>
                                                        </div> */}
                                            <div className="row form-group">
                                                <div className="col-md-12">
                                                    <select
                                                        name="company"
                                                        ref="company"
                                                        className="form-control"
                                                        onChange={this.handleChange}
                                                        value={this.state.company}
                                                        required>
                                                        <option value="Unidad Organizativa / Marca" disabled>Compañía</option>
                                                        {this.state.companies.map(company => {
                                                            if (company.organization._id == this.state.organization) {
                                                                return (
                                                                    <option key={company._id}
                                                                        value={company._id}>{company.name}</option>
                                                                )
                                                            }

                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row form-group">
                                                {this.state.validateFirstName ? '' : <label>{msgObj.letterField}</label>}
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        name="firstName"
                                                        placeholder="Nombre"
                                                        ref="firstName"
                                                        className={this.state.validateFirstName ? "form-control" : " form-control invalid"}
                                                        value={this.state.firstName}
                                                        onChange={this.handleChangeFirstName}
                                                        maxLength="30"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="row form-group">
                                                {this.state.validateLastName ? '' : <label>{msgObj.letterField}</label>}
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        name="lastName"
                                                        placeholder="Apellido"
                                                        ref="lastName"
                                                        className={this.state.validateLastName ? "form-control" : " form-control invalid"}
                                                        value={this.state.lastName}
                                                        onChange={this.handleChangeLastName}
                                                        maxLength="30"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="row form-group">
                                                {this.state.validateEmail ? '' : <label>{msgObj.emailField}</label>}
                                                <div className="col-md-12">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        placeholder="Correo"
                                                        ref="email"
                                                        className={this.state.validateEmail ? "form-control" : " form-control invalid"}
                                                        value={this.state.email}
                                                        onChange={this.handleChangeEmail}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="row form-group">
                                                {this.state.validatePassword ? '' : <label>{msgObj.passwordField}</label>}
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        name="password"
                                                        placeholder="Contraseña"
                                                        ref="password"
                                                        className={this.state.validatePassword ? "form-control" : " form-control invalid"}
                                                        value={this.state.password}
                                                        onChange={this.handleChangePassword}
                                                        required
                                                        maxLength="12"
                                                        minLength="6"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row form-group">
                                                <div className="col-md-12">
                                                    <select
                                                        name="role"
                                                        ref="role"
                                                        className="form-control"
                                                        onChange={this.handleChange}
                                                        value={this.state.role}
                                                        required>
                                                        <option value="Rol" disabled>Rol</option>
                                                        {this.state.roles.map(rol => {
                                                            if (rol.level >= user.role.level) {
                                                                return (
                                                                    <option key={rol._id}
                                                                        value={rol._id}>
                                                                        {rol.name}
                                                                    </option>
                                                                )
                                                            }
                                                        })}
                                                     </select>
                                                </div>
                                            </div>
                                            <div className="row form-group">
                                                <div className="col-md-12">
                                                    <div className="checkbox">
                                                        <label>
                                                            Activar
                                                    <input
                                                                type="checkbox"
                                                                name="switch"
                                                                onClick={this.isChecked}
                                                                checked={this.state.status}
                                                                ref="switch"
                                                                className="switch"
                                                            />
                                                            <div>
                                                                <div></div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="col-md-12">
                                                <div className="text-center">
                                                    {this.state._id != '' ?
                                                        <button
                                                            type="button"
                                                            className="btn btn-form btn-light"
                                                            onClick={this.clearForm}
                                                        >Limpiar</button> : null}
                                                    &nbsp;
                                                    {(this.state.validateFirstName &&
                                                        this.state.validateLastName &&
                                                        this.state.validateEmail &&
                                                        this.state.validatePassword ?
                                                        <button
                                                            type="submit"
                                                            className="btn btn-form btn-light"
                                                        >Guardar</button> :
                                                        <button
                                                            type="button"
                                                            className="btn btn-form btn-light"
                                                            disabled
                                                        >Guardar</button>)}
                                                </div>
                                            </div>
                                            <ToastsContainer store={ToastsStore} />
                                        </form>
                                    </div>
                                    <div className="col-md-9">
                                        <Table
                                            data={this.state.data}
                                            columns={columns} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Modale
                                            show={this.state.show}
                                            onCancel={this.ShowAndHide}
                                            user={this.state.user}
                                        />
                                    </div>
                                    <ModalDelete show={this.state.showDelete} onCancel={this.showModal} delete={this.deleteUser} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}