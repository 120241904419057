import React, { Component } from 'react';
import $ from 'jquery';
import { Redirect, Link } from 'react-router-dom'
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import logo from '../../LogoBe&co.png';

import msgObj from '../../const.js';
import UploadImage from '../UpdateImage/Update.Image.jsx';
import Sidebar from '../Sidebar/Sidebar.jsx';
import versionTooltip from '../../version.js'
import TimeLoader from '../TimeLoader/TimeLoader.jsx';
import ResponseType from './ResponseType/responseType.jsx';
import MeasumerentScale from './MeasurementScale/MeasumerentScale.jsx';
import DemographicData from './DemographicData/DemographicData.jsx';
import Dimension from './Dimension/Dimension.jsx';

import './Parametrization.css'

export default class Parametrization extends Component {

    constructor() {
        super();
        this.state = {
            page: 'responseType',
            sidebarCollapse: true,
            image: '',
            organizations: [],
            inactive: false
        }
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem("user"));
        $("#menu-toggle").click(function (e) {
            e.preventDefault();
            $("#wrapper").toggleClass("toggled");
        });
        this.getLogo();
        if (user.role.name == 'Administrador Empresas') {
            this.setState({
                page: 'measurementScale'
            })
        }

    }

    getLogo = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        const token = localStorage.getItem("token");
        axios
            .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/organizations`, { headers: { 'x-auth-token': token } })
            .then(res => {
                this.setState({
                    organizations: res.data.organizations
                });
                this.state.organizations.map(organization => {
                    if (organization._id == user.company.organization._id) {
                        this.setState({
                            image: organization.logo.imagecode
                        });
                    }
                });
            });
    };

    changedPage = (event) => {
        this.setState({
            page: event.target.name
        });
    }

    logOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem("image");
    }


    collapseSidebar = () => {
        this.setState({
            sidebarCollapse: !this.state.sidebarCollapse
        })
    }

    render() {

        const user = JSON.parse(localStorage.getItem('user'));
        const token = localStorage.getItem('token');
        if (!token) {
            return (
                <Redirect to='/'>

                </Redirect>
            )
        } else {
            return (
                <div className="Parametrizacion">
                    <TimeLoader redirect={this.props.history} />
                    <div className="wrapper" id="wrapper">
                        <Sidebar
                            page="params"
                            role={user.role}
                        />
                        <div id="page-content-wrapper">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                                            <button
                                                className="navbar-toggler"
                                                type="button"
                                                data-toggle="collapse"
                                                data-target="#navbarNav"
                                                aria-controls="navbarNav"
                                                aria-expanded="false"
                                                aria-label="Toggle navigation">
                                                <span className="navbar-toggler-icon"></span>
                                            </button>
                                            <div
                                                id="navbarNav"
                                                className="collapse navbar-collapse">
                                                <ul className="nav navbar-nav mr-auto">
                                                    <li className="nav-item">
                                                        <a className="nav-link"
                                                            data-tip={versionTooltip}
                                                            data-for="version"
                                                            href="#menu-toggle"
                                                            id="menu-toggle"
                                                            onClick={this.collapseSidebar}>
                                                            <i className="fa fa-bars"></i></a>
                                                        <ReactTooltip place="top"
                                                            id="version" />
                                                    </li>
                                                    <li
                                                        className="nav-item"
                                                        data-tip={msgObj.home}
                                                        data-for="home">
                                                        {!this.state.sidebarCollapse ?
                                                            <Link to="Home">
                                                                <img className="logoNavbar" src={this.state.image}></img>
                                                            </Link>

                                                            :
                                                            <Link to="/Home"><i className='fas fa-home'></i></Link>
                                                        }
                                                    </li>
                                                    <ReactTooltip
                                                        place="top"
                                                        id="home" />
                                                </ul>
                                                <ul className="nav navbar-nav mx-auto">
                                                    {user.role.name == 'Administrador Empresas'
                                                        ? null
                                                        :
                                                        <li className="nav-item item">
                                                            <a
                                                                href="#"
                                                                name='responseType'
                                                                onClick={this.changedPage}
                                                                className={this.state.page === 'responseType' ? 'active nav-link' : 'nav-link'}
                                                            >Niveles de Respuesta</a>
                                                        </li>}
                                                    <li className="nav-item item">
                                                        <a
                                                            href="#"
                                                            name='measurementScale'
                                                            onClick={this.changedPage}
                                                            className={this.state.page === 'measurementScale' ? 'active nav-link' : 'nav-link'}
                                                        >Escalas de Medición</a>
                                                    </li>
                                                    <li className="nav-item item">
                                                        <a
                                                            href="#"
                                                            name='demographicData'
                                                            onClick={this.changedPage}
                                                            className={this.state.page === 'demographicData' ? 'active nav-link' : 'nav-link'}
                                                        >Datos Demograficos</a>
                                                    </li>
                                                    <li className="nav-item item">
                                                        <a
                                                            href="#"
                                                            name='dimension'
                                                            onClick={this.changedPage}
                                                            className={this.state.page === 'dimension' ? 'active nav-link' : 'nav-link'}
                                                        >Dimensiones y Subdimensiones</a>
                                                    </li>
                                                </ul>
                                                <ul className="nav navbar-nav ml-auto">
                                                    <li className="nav-item">
                                                        <span>{user.firstName + " " + user.lastName}</span>
                                                        &nbsp;
                                            </li>
                                                    {
                                                        user.role.name == 'Administrador Empresas'
                                                            ?
                                                            <li className="nav-item">
                                                                <UploadImage />
                                                            </li>
                                                            :
                                                            null
                                                    }
                                                    &nbsp;&nbsp;
                                             <li className="nav-item">
                                                        <Link
                                                            data-tip="Cerrar sesión"
                                                            to="/"><i
                                                                className="fas fa-sign-out-alt"
                                                                onClick={this.logOut}></i>
                                                        </Link>
                                                        <ReactTooltip />
                                                    </li>
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <br />
                                        <h1>Parametrización</h1>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-12">
                                        {this.state.page === 'responseType' ? <ResponseType user={user} /> : ''}
                                        {this.state.page === 'measurementScale' ? <MeasumerentScale user={user} /> : ''}
                                        {this.state.page === 'demographicData' ? <DemographicData user={user} /> : ''}
                                        {this.state.page === 'dimension' ? <Dimension user={user} /> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

    }
}