import React, { Component } from 'react';
import axios from 'axios';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import ReactTooltip from 'react-tooltip';

import ModalDelete from '../../ModalDelete/ModalDelete.jsx'
import msgObj from '../../../const.js';
import token from '../../../token.js'
import Table from '../../Table/Table';


export default class DemographicData extends Component {

    constructor() {
        super();
        this.state = {
            _id: '',
            _idDelete: '',
            renderInput: 'Seleccione Dato o Valor Demográfico . . .',
            name: '',
            nameValue: '',
            description: '',
            DData: '',
            status: false,
            demographicData: [],
            demographicDataValues: [],
            data: [],
            dataL: [],
            dataDemographicData: [],
            columns: [{}],
            validate: true,
            show: false,
            dataByDemographicData: [],
            orderValue: ''
        }
    }

    componentDidMount() {
        this.getDemographicData();
        this.getDemographicDataValue();
    }

    isChecked = () => {
        this.setState({
            status: !this.state.status
        })
    }

    clearForm = () => {
        this.setState({
            _id: '',
            name: '',
            orderValue: '',
            nameValue: '',
            description: '',
            DData: '',
            status: false,
        })
    }

    showModal = (id) => {
        this.setState({
            _idDelete: id,
            show: !this.state.show,
        })
    }

    handleChange = (event) => {
        const rules = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ_@./#&+,\s]*$/g;
        const demographicDataName = this.state.name;
        const validate = rules.test(demographicDataName);
        const { name, value } = event.target;
        this.setState({
            [name]: value,
            validate
        })
    }

    onDemographicData = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.getDemographicDataValueByDemographicData();
        })
    }

    handleChangeValue = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        })
    }

    getDemographicData = () => {
        this.setState({
            dataDemographicData: [],
            dataL: []
        })
        const dataDemographicData = Object.assign([], this.state.dataDemographicData)
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/demographicData`, { headers: { 'x-auth-token': token } })
            .then(res => {
                this.setState({
                    demographicData: res.data.demographicData
                })
                this.state.demographicData.map(data => {
                    dataDemographicData.push({
                        "_id": data._id,
                        "name": data.name,
                        "status": data.status
                    })
                })
                this.setState({
                    dataDemographicData
                })
            });
        const dataL = Object.assign([], this.state.dataL)
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/demographicData/active`, { headers: { 'x-auth-token': token } })
            .then(res => {
                this.setState({
                    dataL: res.data.demographicData
                })
                this.state.dataL.map(data => {
                    if (data.status) {
                        dataL.push({
                            "_id": data._id,
                            "name": data.name,
                            "status": data.status
                        })
                    }
                })
                this.setState({
                    dataL
                })
            });
    }

    getDemographicDataValue = () => {
        this.setState({
            data: []
        })
        const data = Object.assign([], this.state.data)
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/demographicDataValues`, { headers: { 'x-auth-token': token } })
            .then(res => this.setState({
                demographicDataValues: res.data.demographicDataValues
            }))
            .then(res => this.state.demographicDataValues.map(demographicDataValue => {
                data.push({
                    "_id": demographicDataValue._id,
                    "demographicData": demographicDataValue.demographicData.name,
                    "order": demographicDataValue.order,
                    "name": demographicDataValue.name,
                    "status": demographicDataValue.status,
                })
            })
            )
            .then(res => this.setState({
                data
            }))
    }

    getDemographicDataValueByDemographicData = () => {
        this.setState({
            dataByDemographicData: []
        }, () => {
            const dataByDemographicData = Object.assign([], this.state.dataByDemographicData)
            axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/demographicDataValues`, { headers: { 'x-auth-token': token } })
                .then(res => {
                    this.setState({
                        demographicDataValues: res.data.demographicDataValues
                    })

                    this.state.demographicDataValues.map(demographicDataValue => {
                        if (demographicDataValue.demographicData._id == this.state.DData) {
                            dataByDemographicData.push({
                                "_id": demographicDataValue._id,
                                "order": demographicDataValue.order,
                                "demographicData": demographicDataValue.demographicData.name,
                                "name": demographicDataValue.name,
                                "status": demographicDataValue.status,
                            })
                        }
                    })

                    this.setState({
                        dataByDemographicData
                    })

                })
        })

    }

    addDemographicData = (event) => {
        event.preventDefault();
        if (this.state._id) {
            axios.put(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/demographicData/${this.state._id}`, {
                name: this.state.name,
                description: this.state.description,
                status: this.state.status,
            }, { headers: { 'x-auth-token': token } })
                .then(res => {
                    this.setState({
                        _id: '',
                        name: '',
                        description: '',
                        status: false
                    })
                    this.getDemographicData();
                    ToastsStore.success(res.data.msg);
                })
        } else {
            axios.post(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/demographicData`, {
                name: this.state.name,
                description: this.state.description,
                status: this.state.status,
            }, { headers: { 'x-auth-token': token } })
                .then(res => {
                    this.setState({
                        name: '',
                        description: '',
                        status: false
                    })
                    this.getDemographicData();
                    ToastsStore.success(res.data.msg);
                })
                .catch(err => {
                    ToastsStore.error(err.response.data.msg)
                });
        }
    }

    addDemographicDataValue = (event) => {
        event.preventDefault();
        if (this.state._id) {
            const objRequest = {
                demographicData: {
                    "_id": this.state.DData
                },
                order: this.state.orderValue,
                name: this.state.nameValue,
                description: this.state.description,
                status: this.state.status,
            }
            axios.put(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/demographicDataValues/${this.state._id}`, objRequest, { headers: { 'x-auth-token': token } })
                .then(res => {
                    this.setState({
                        _id: '',
                        orderValue: '',
                        nameValue: '',
                        description: '',
                        status: false
                    })
                    this.getDemographicDataValueByDemographicData();
                    this.getDemographicDataValue();
                    ToastsStore.success(res.data.msg);
                })
                .catch(err => ToastsStore.error(err.response.data.msg))
        } else {
            const objRequest = {
                demographicData: {
                    "_id": this.state.DData
                },
                order: this.state.orderValue,
                name: this.state.nameValue,
                description: this.state.description,
                status: this.state.status,
            }
            axios.post(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/demographicDataValues`, objRequest, { headers: { 'x-auth-token': token } })
                .then(res => {
                    this.setState({
                        orderValue: '',
                        nameValue: '',
                        description: '',
                        status: false
                    })
                    this.getDemographicDataValueByDemographicData();
                    this.getDemographicDataValue();
                    ToastsStore.success(res.data.msg);
                })
                .catch(err => {
                    ToastsStore.error(err.response.data.msg)
                });
        }

    }

    editDemographicData = (id) => {
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/demographicData/${id}`, { headers: { 'x-auth-token': token } })
            .then(res => this.setState({
                _id: res.data.demographicData._id,
                name: res.data.demographicData.name,
                description: res.data.demographicData.description,
                status: res.data.demographicData.status
            }))
    }

    deleteDemographicData = (id) => {
        axios.delete(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/demographicData/${this.state._idDelete}`, { headers: { 'x-auth-token': token } })
            .then(res => {
                this.getDemographicDataValueByDemographicData();
                this.getDemographicDataValue();
                this.getDemographicData();
                ToastsStore.success(res.data.msg);
                this.setState({
                    _idDelete: '',
                    show: !this.state.show
                })
            })
            .catch(err => {
                ToastsStore.error(err.response.data.msg)
            });
    }

    editDemographicDataValue = (id) => {
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/demographicDataValues/${id}`, { headers: { 'x-auth-token': token } })
            .then(res => this.setState({
                _id: res.data.demographicDataValue._id,
                DData: res.data.demographicDataValue.demographicData._id,
                orderValue: res.data.demographicDataValue.order,
                nameValue: res.data.demographicDataValue.name,
                description: res.data.demographicDataValue.description,
                status: res.data.demographicDataValue.status
            }))
    }

    deleteDemographicDataValue = (id) => {
        axios.delete(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/demographicDataValues/${this.state._idDelete}`, { headers: { 'x-auth-token': token } })
            .then(res => {
                this.getDemographicDataValueByDemographicData();
                this.getDemographicDataValue();
                ToastsStore.success(res.data.msg);
                this.setState({
                    _idDelete: '',
                    show: !this.state.show
                })
            })
            .catch(err => {
                ToastsStore.error(err.response.data.msg)
            });
    }

    render() {
        const { user } = this.props;
        const formatWithIconDemographicData = (cell, row) => {
            return (
                <div>
                    <i
                        data-tip={msgObj.update}
                        data-for="update"
                        className="fas fa-edit"
                        aria-hidden="true"
                        onClick={() => this.editDemographicData(row._id)}></i>
                    <ReactTooltip
                        place="top"
                        id="update" />
                    &nbsp;&nbsp;
                        {user.role.name == 'Administrador Empresas'
                        ? null
                        :
                        <i
                            className="fa fa-trash"
                            data-tip={msgObj.delete}
                            data-for="delete"
                            aria-hidden="true"
                            onClick={() => this.showModal(row._id)}></i>}
                    <ReactTooltip
                        place="top"
                        id="delete" />
                </div>
            )
        }

        const formatWithIconDemographicDataValue = (cell, row) => {
            return (
                <div>
                    <i
                        data-tip={msgObj.update}
                        data-for="update"
                        className="fas fa-edit"
                        aria-hidden="true"
                        onClick={() => this.editDemographicDataValue(row._id)}></i>
                    <ReactTooltip
                        place="top"
                        id="update" />
                    &nbsp;&nbsp;
                        {user.role.name == 'Administrador Empresas'
                        ? null
                        :
                        <i
                            className="fa fa-trash"
                            data-tip={msgObj.delete}
                            data-for="delete"
                            aria-hidden="true"
                            onClick={() => this.showModal(row._id)}></i>}
                    <ReactTooltip
                        place="top"
                        id="delete" />
                </div>
            )
        }

        const formatWithStatus = (cell, row) => {
            return (
                <div>
                    {row.status
                        ? <i
                            className="fas fa-circle status-active"
                            aria-hidden="true"
                            data-tip={msgObj.enable}
                            data-for="enable"
                        >
                        </i>
                        : <i
                            className="fas fa-circle status-inactive"
                            aria-hidden="true"
                            data-tip={msgObj.disable}
                            data-for="disable"></i>
                    }
                    <ReactTooltip
                        place="top"
                        id={row.status ? "enable" : "disable"} />
                </div>
            )
        }

        const columns1 = [
            {
                dataField: 'status',
                text: '',
                formatter: formatWithStatus
            },
            {
                dataField: 'name',
                text: 'Dato Demografico',
                sort: true
            },
            {
                dataField: 'deleteAction',
                text: '',
                formatter: formatWithIconDemographicData
            }]

        const columns2 = [
            {
                dataField: 'status',
                text: '',
                formatter: formatWithStatus
            },
            {
                dataField: 'demographicData',
                text: 'Dato Demografico',
                sort: true
            },
            {
                dataField: 'order',
                text: 'Orden',
                sort: true
            },
            {
                dataField: 'name',
                text: 'Valor',
                sort: true
            },
            {
                dataField: 'deleteAction',
                text: '',
                formatter: formatWithIconDemographicDataValue
            }]

        return (
            <div className="row">
                <div className="col-md-5">
                    <form onSubmit={this.state.renderInput == 1 ? this.addDemographicData : this.addDemographicDataValue} autocomplete="off">
                        <div className="row form-group">
                            <div className="col-md-12">
                                <select
                                    name="renderInput"
                                    ref="renderInput"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.renderInput}
                                    required>
                                    <option value="Seleccione Dato o Valor Demográfico . . ."
                                        disabled>Nombre del Dato Demográfico</option>
                                    <option value="1">Dato Demográfico</option>
                                    <option value="2">Valor de Dato Demográfico</option>
                                </select>
                            </div>
                        </div>
                        {this.state.renderInput == 2 ?
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <select
                                        name="DData"
                                        ref="DData"
                                        className="form-control"
                                        onChange={this.onDemographicData}
                                        value={this.state.DData}
                                        required
                                        selected>
                                        <option value=""
                                            disabled>Dato Demográfico</option>
                                        {this.state.dataL.map(data => {
                                            return (
                                                <option value={data._id}>
                                                    {data.name}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            : ''}
                        {this.state.renderInput == 1 ? <div className="row form-group">
                            {this.state.validate ? '' : <label className="errmsg">{msgObj.letterField}</label>}
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Dato Demográfico"
                                    ref="name"
                                    className={this.state.validate ? "form-control" : " form-control invalid"}
                                    onChange={this.handleChange}
                                    value={this.state.name}
                                    required
                                />
                            </div>
                        </div>
                            : ''}

                        {this.state.renderInput == 2 ? <div className="row form-group">
                            <div className="col-md-12">
                                <input
                                    type='number'
                                    name="orderValue"
                                    placeholder="Orden del valor demografico"
                                    ref="orderValue"
                                    className="form-control"
                                    value={this.state.orderValue}
                                    onChange={this.handleChange}
                                    required
                                />
                            </div>
                        </div>
                            : ''}

                        {this.state.renderInput == 2 ? <div className="row form-group">
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    name="nameValue"
                                    placeholder="Valor Demográfico"
                                    ref="nameValue"
                                    className="form-control"
                                    onChange={this.handleChangeValue}
                                    value={this.state.nameValue}
                                    required
                                />
                            </div>
                        </div>
                            : ''}

                        {this.state.renderInput == 1 ? <div className="row form-group">
                            <div className="col-md-12">
                                <textarea
                                    name="description"
                                    placeholder="Descripción"
                                    ref="description"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.description}
                                />
                            </div>
                        </div>
                            : ''}

                        {this.state.renderInput == 2 ? <div className="row form-group">
                            <div className="col-md-12">
                                <textarea
                                    name="description"
                                    placeholder="Descripción"
                                    ref="description"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.description}
                                />
                            </div>
                        </div>
                            : ''}
                        {user.role.name == 'Administrador Empresas'
                            ? null
                            :
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <div className="checkbox">
                                        <label>
                                            Activar
                                        <input
                                                type="checkbox"
                                                name="switch"
                                                onClick={this.isChecked}
                                                checked={this.state.status}
                                                ref="switch"
                                                className="switch"
                                            />
                                            <div>
                                                <div></div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>}
                        <br />
                        {this.state.renderInput != 'Seleccione Dato o Valor Demográfico . . .' ? <div className="col-md-12">
                            <div className="text-center">
                                {this.state._id != '' ?
                                    <button
                                        type="button"
                                        className="btn btn-form btn-light"
                                        onClick={this.clearForm}
                                    >Limpiar</button> : null}
                                &nbsp;
                                    {user.role.name == 'Administrador Empresas'
                                    ? null
                                    :
                                    this.state.validate ? (
                                        <button type="submit" className="btn btn-form btn-light">
                                            Guardar
                                        </button>
                                    ) : (
                                            <button
                                                type="button"
                                                className="btn btn-form btn-light"
                                                disabled
                                            >
                                                Guardar
                                        </button>
                                        )}
                            </div>
                        </div> : null}
                        <ToastsContainer store={ToastsStore} />
                    </form>
                    <ModalDelete
                        show={this.state.show}
                        onCancel={this.showModal}
                        delete={this.state.renderInput == 1 ? this.deleteDemographicData : this.deleteDemographicDataValue}
                        msgDeleteAll={this.state.renderInput == 1 ?
                            <p>De continuar, se eliminarán todos los registros asociados.</p>
                            : null} />
                </div>
                {this.state.renderInput == 1 ?
                    <div className="col-md-7">
                        <Table
                            data={this.state.dataDemographicData}
                            columns={columns1} />
                    </div> : ''}
                {this.state.renderInput == 2 ?
                    <div className="col-md-7">
                        <Table
                            data={this.state.DData == '' ? this.state.data : this.state.dataByDemographicData}
                            columns={columns2} />
                    </div> : ''}
            </div>
        )
    }
}