import React, { Component } from "react";
import axios from "axios";
import { ToastsContainer, ToastsStore } from "react-toasts";
import ReactTooltip from 'react-tooltip';

import Table from "../../Table/Table.jsx";
import msgObj from '../../../const.js';
// import BASEPATH from '../../../const.js'
import token from '../../../token.js'
import Form from "../../Form/Form.jsx";

export default class AssignBrands extends Component {
  constructor() {
    super();
    this.state = {
      _id: "",
      _idCompany: "",
      adminGroup: "",
      organization: "",
      brand: "",
      company: "",
      companies: [],
      companiesBadge: [],
      _idBadges: [],
      brands: [],
      organizations: [],
      adminGroups: [],
      data: []
    };
  }
  componentDidMount() {
    this.getBrands();
    this.getOrganizations();
    this.getAdminGroups();
    this.getCompanies();
  }

  clearForm = () => {
    this.setState({
      _id: "",
      _idCompany: "",
      adminGroup: "",
      organization: "",
      brand: "",
      company: "",
      companiesBadge: [],
      _idBadges: []
    });
  };

  getCompanies = () => {
    this.setState({
      data: []
    });
    const data = Object.assign([], this.state.data);
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/companies`, { headers: { 'x-auth-token': token } })
      .then(res => {
        this.setState({
          companies: res.data.companies
        });

        this.state.brands.map(brand => {
          const arrayBrands = [];
          this.state.companies.map(company => {
            if (company.brand != null) {
              if (brand.name == company.brand.name) {
                arrayBrands.push(brand.name);
              }
            }
          });
          data.push({
            _id: brand._id,
            name: brand.name,
            company: arrayBrands.length
          });
        });

        this.setState({
          data
        });
      });
  };

  getBrands = () => {
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/brands/active`, { headers: { 'x-auth-token': token } })
      .then(res => {
        this.setState({
          brands: res.data.brands
        });
      });
  };

  addCompanies = () => {
    if (this.state.company != "Compañia") {
      const companiesBadge = Object.assign([], this.state.companiesBadge);
      const _idBadges = Object.assign([], this.state._idBadges);
      const validate = companiesBadge.indexOf(this.state.company);
      if (validate != -1) {
        ToastsStore.info("No puede asignar más de una compañia.");
      } else {
        companiesBadge.push(this.state.company);
        _idBadges.push(this.state._idCompany);
      }

      this.setState({
        companiesBadge,
        _idBadges
      });
    } else {
      ToastsStore.info("Por favor seleccione una acción");
    }
  };

  removeCompanies = event => {
    const companiesBadge = Object.assign([], this.state.companiesBadge);
    const _idBadges = Object.assign([], this.state._idBadges);
    const index = companiesBadge.indexOf(event.target.id);
    const indexId = _idBadges.indexOf(event.target.id);
    companiesBadge.splice(index, 1);
    _idBadges.splice(index, 1);
    this.setState({
      companiesBadge,
      _idBadges
    });
  };

  getOrganizations = () => {
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/organizations`, { headers: { 'x-auth-token': token } })
      .then(res =>
        this.setState({
          organizations: res.data.organizations
        })
      );
  };

  getAdminGroups = () => {
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/admingroups`, { headers: { 'x-auth-token': token } })
      .then(res =>
        this.setState({
          adminGroups: res.data.adminGroups
        })
      );
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  handleBadge = event => {
    const selectedIndex = event.target.options.selectedIndex;
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value,
        _idCompany: event.target.options[selectedIndex].getAttribute("data-key")
      },
      () => {
        this.addCompanies();
      }
    );
  };

  assignBrand = event => {
    event.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/brands/${this.state.brand}/assing`,
        {
          idCompanies: this.state._idBadges
        },
        { headers: { 'x-auth-token': token } }
      )
      .then(res => {
        console.log(this.state._idBadges);
        ToastsStore.success(res.data.msg);
        this.setState({
          _id: "",
          adminGroup: "",
          organization: "",
          brand: "",
          company: "",
          companiesBadge: [],
          _idBadges: []
        });
        this.getCompanies();
      })
      .catch(err => {
        console.log(err.response.data.msg)
        ToastsStore.error(err.response.data.msg)
      });
  };

  editCompanies = id => {
    this.setState({
      companiesBadge: [],
      _idBadges: []
    });
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/brands/${id}`, { headers: { 'x-auth-token': token } })
      .then(res => {
        console.log(res.data);
        const companiesBadge = Object.assign([], this.state.companiesBadge);
        const _idBadges = Object.assign([], this.state._idBadges);

        this.state.companies.map(company => {
          if (company.brand != null) {
            if (company.brand._id == id) {
              companiesBadge.push(company.name);
              _idBadges.push(company._id);
            }
          }
        });
        this.setState({
          _id: res.data.brand._id,
          adminGroup: res.data.brand.organization.admingroup._id,
          organization: res.data.brand.organization._id,
          brand: res.data.brand._id,
          companiesBadge: companiesBadge,
          _idBadges: _idBadges
        });
      });
  };

  render() {
    const formatWithIcon = (cell, row) => {
      return (
        <div>
          <i
            data-tip={msgObj.update}
            data-for="update"
            className="fas fa-edit"
            aria-hidden="true"
            onClick={() => this.editCompanies(row._id)}></i>
          <ReactTooltip
            place="top"
            id="update" />
          &nbsp;&nbsp;
        </div>
      );
    };
    console.log(process.env.REACT_APP_BASEPATH);

    const columns = [
      {
        dataField: "name",
        text: "Marca",
        sort: true
      },
      {
        dataField: "company",
        text: "Compañía",
        sort: true
      },
      {
        dataField: "deleteAction",
        text: "",
        formatter: formatWithIcon
      }
    ];

    return (
      <div className="row">
        <div className="col-md-4">
          <form onSubmit={this.assignBrand} autocomplete="off">
            <div className="row form-group">
              <div className="col-md-12">
                <select
                  name="adminGroup"
                  ref="adminGroup"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.adminGroup}
                  required
                >
                  <option value="" selected disabled>
                    Grupo Administrador
                  </option>
                  {this.state.adminGroups.map(group => {
                    return (
                      <option key={group._id} value={group._id}>
                        {group.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-12">
                <select
                  name="organization"
                  ref="organization"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.organization}
                  required
                >
                  <option value="" disabled selected>
                    Organización
                  </option>
                  {this.state.organizations.map(organization => {
                    if (this.state.adminGroup == organization.admingroup._id) {
                      return (
                        <option key={organization._id} value={organization._id}>
                          {organization.name}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-12">
                <select
                  name="brand"
                  ref="brand"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.brand}
                  required
                >
                  <option value="" disabled selected>
                    Marca
                  </option>
                  {this.state.brands.map(brand => {
                    if (this.state.organization == brand.organization._id) {
                      return (
                        <option key={brand._id} value={brand._id}>
                          {brand.name}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-12">
                <select
                  name="company"
                  ref="company"
                  className="form-control"
                  onChange={this.handleBadge}
                  value={this.state.company}
                >
                  <option value="" disabled selected>
                    Compañia
                  </option>
                  {this.state.companies.map(company => {
                    if (this.state.organization == company.organization._id) {
                      return (
                        <option
                          key={company._id}
                          value={company.name}
                          data-key={company._id}
                        >
                          {company.name}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
              <br />
              <br />

              <div className="row from-group">
                <div className="col-md-12">
                  {this.state.companiesBadge.map(company => {
                    return (
                      <div>
                        <span
                          className="badge badge-pill badge-secondary badge-outlined badge-company"
                          onClick={this.removeCompanies}
                          key={company}
                          id={company}
                        >
                          {company} X
                        </span>
                      </div>
                    );
                  })}
                </div>
                <br />
              </div>
            </div>
            <div className="col-md-12">
              <div className="text-center">
                {this.state._id != "" ? (
                  <button
                    type="button"
                    className="btn btn-form btn-light"
                    onClick={this.clearForm}
                  >
                    Limpiar
                  </button>
                ) : null}
                &nbsp;
                <button type="submit" className="btn btn-form btn-light">
                  Guardar
                </button>
              </div>
            </div>
            <ToastsContainer store={ToastsStore} />
          </form>
        </div>
        <div className="col-md-8">
          <Table data={this.state.data} columns={columns} />
        </div>
      </div>
    );
  }
}
