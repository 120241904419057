import React, {Component} from 'react';
import IdleTimer from 'react-idle-timer'

export default class TimeLoader extends Component {

    onAction = (e) => {
        
      }
     
    onActive = (e) => {
       console.log('user is active', e)
       console.log(this.idleTimer);
       console.log('time remaining', this.idleTimer.getRemainingTime())
      }
     
    onIdle = (e) => {
        alert('Sesión caducada');
        localStorage.clear();
         this.props.redirect.push('/')
      }
    
      render() {
        return (
          <div>
            <IdleTimer
              ref={ref => { this.idleTimer = ref }}
              element={document}
              onActive={this.onActive}
              onIdle={this.onIdle}
              onAction={this.onAction}
              debounce={250}
              timeout={720000} />
            {/* your app here */}
          </div>
        )
      }

}