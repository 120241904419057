import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import ReactFileReader from 'react-file-reader';

import './Update.Image.css';

export default class UploadImage extends Component {

  constructor() {
    super();
    this.state = {
      file: '',
    }
  }

  onChange = (event) => {
    this.setState({
      file: event.target.files[0]
    })
    console.log(this.state.file)
  }

  handleFiles = (files) => {

    var user = JSON.parse(localStorage.getItem('user'));

    console.log(files.base64);
    console.log(files.fileList[0].name);
    axios.post(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/uploadImage`, {
      name: files.fileList[0].name,
      imagecode: files.base64,
      _id: user.company.organization._id
    })
      .then(res => {
        ToastsStore.success(res.data.msg);
        localStorage.setItem('image', files.base64)
      })

  }

  render() {

    return (
      <div className="UploadImage">
        <ReactFileReader handleFiles={this.handleFiles} fileTypes={[".png", ".jpeg"]} base64={true}>
          <label htmlFor="file-input">
            <i
              className="fas fa-arrow-up"
              data-tip="Carga tu logo!"
              data-for="upload-img"></i>
            <ReactTooltip place="top"
              id="upload-img" />
          </label>
        </ReactFileReader>


      </div>
    )
  }
}