import React, { Component } from "react";
import Modal from "react-bootstrap4-modal";
import { ToastsContainer, ToastsStore } from "react-toasts";

// import BASEPATH from '../../const.js'
import "./ModalAlert.css";
import axios from "axios";

export default class ModalAlert extends Component {
  render() {
    const date = this.props.date
      ? this.props.date.substring(0, 10).split("-")
      : [];
    return (
      <div className="Modal">
        <Modal visible={this.props.show} onClickBackdrop={this.props.onCancel}>
          <div className="modal-header">
            <h5 className="modal-title">Confirmación</h5>
          </div>
          <div className="modal-body">
            <p>
              <strong>¿Desea activar el instrumento?</strong>
            </p>
            <p>
              Al activar el instrumento los tokens serán generados y se enviará
              automáticamente la encuesta a los colaboradores correspondientes
              según su selección previa.
            </p>
            <p>
              Actualmente la encuesta será enviada a{" "}
              <strong>{this.props.collaboratorsLength}</strong> colaboradores
            </p>
            <p>
              Al correo principal se enviarán{" "}
              <strong>{this.props.collaboratorsLengthPrincipal}</strong> y al
              correo del administrador se le enviarán{" "}
              <strong>{this.props.collaboratorsLengthAdmin}</strong>{" "}
              Tokens/Contraseñas.
            </p>
            <p>
              <strong>Fecha de la última importación de datos:</strong>{" "}
              {date[2] + "/" + date[1] + "/" + date[0]}
            </p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn" onClick={this.props.activate}>
              Aceptar
            </button>
            <button type="button" className="btn" onClick={this.props.onCancel}>
              Cancelar
            </button>
          </div>
          <ToastsContainer store={ToastsStore} />
        </Modal>
      </div>
    );
  }
}
