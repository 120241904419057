
import React, { Component } from "react";
import $ from "jquery";
import { Redirect, Link } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { ToastsContainer, ToastsStore } from "react-toasts";
import ReactTooltip from "react-tooltip";
import axios from 'axios';

import logo from "../../LogoBe&co.png";
import UploadImage from "../UpdateImage/Update.Image.jsx";
// import BASEPATH from "../../const.js";
import msgObj from '../../const.js'
import Table from "../Table/Table.jsx";
import Results from "./Results/Results";
import Baremo from "./Baremo/Baremo";
import DataExport from "./DataExport/DataExport";
import Report from './Report/Report';
import TimeLoader from '../TimeLoader/TimeLoader.jsx';

import "./Reporteador.css";

export default class Reporteador extends Component {
  constructor() {
    super();
    this.state = {
      page: "report",
      sidebarCollapse: true,
      image: '',
    };
  }

  changedPage = event => {
    this.setState({
      page: event.target.name
    });
  };

  getLogo = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/organizations`, { headers: { 'x-auth-token': token } })
      .then(res => {
        this.setState({
          organizations: res.data.organizations
        });
        if (this.state.organization != null) {
          this.state.organizations.map(organization => {
            if (organization._id == user.company.organization._id) {
              this.setState({
                image: organization.logo.imagecode
              });
            }
          });
        }
      });
  };

  collapseSidebar = () => {
    this.setState({
      sidebarCollapse: !this.state.sidebarCollapse
    });
  };

  logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("image");
  };

  componentDidMount() {
    $("#menu-toggle").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });
    this.getLogo();
  }

  render() {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    if (!token) {
      return <Redirect to="/"></Redirect>;
    } else {
      return (
        <div className="Reporteador">
          <TimeLoader redirect={this.props.history} />
          <div className="wrapper" id="wrapper">
            <Sidebar page="reporter" role={user.role} />
            <div id="page-content-wrapper">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <div id="navbarNav" className="collapse navbar-collapse">
                        <ul className="nav navbar-nav mr-auto">
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-tip="v1.0.1_DS"
                              data-for="version"
                              href="#menu-toggle"
                              id="menu-toggle"
                              onClick={this.collapseSidebar}
                            >
                              <i className="fa fa-bars"></i>
                            </a>
                            <ReactTooltip place="top" id="version" />
                          </li>
                          <li
                            className="nav-item"
                            data-tip={msgObj.home}
                            data-for="home">
                            {!this.state.sidebarCollapse ?
                              <Link to="Home">
                                <img className="logoNavbar" src={this.state.image}></img>
                              </Link>
                              :
                              <Link to="/Home">
                                <i className='fas fa-home'>
                                </i>
                              </Link>
                            }
                          </li>
                          <ReactTooltip
                            place="top"
                            id="home" />
                        </ul>
                        <ul className="nav navbar-nav mx-auto">
                          <li className="nav-item item">
                            <a
                              href="#"
                              name="report"
                              onClick={this.changedPage}
                              className={
                                this.state.page === "report"
                                  ? "active nav-link"
                                  : "nav-link"
                              }
                            >
                              Reportes
                            </a>
                          </li>
                          <li className="nav-item item">
                            <a
                              href="#"
                              name="results"
                              onClick={this.changedPage}
                              className={
                                this.state.page === "results"
                                  ? "active nav-link"
                                  : "nav-link"
                              }
                            >
                              Resultados
                            </a>
                          </li>
                          <li className="nav-item item">
                            <a
                              href="#"
                              name="dataExport"
                              onClick={this.changedPage}
                              className={
                                this.state.page === "dataExport"
                                  ? "active nav-link"
                                  : "nav-link"
                              }
                            >
                              Exportación de Datos
                            </a>
                          </li>
                          {user.role.name == "Administrador" || user.role.name == "Sistemas"
                            ?
                            <li className="nav-item item">
                              <a
                                href="#"
                                name="baremo"
                                onClick={this.changedPage}
                                className={
                                  this.state.page === "baremo"
                                    ? "active nav-link"
                                    : "nav-link"
                                }
                              >
                                Baremo
                            </a>
                            </li>
                            : null}

                        </ul>
                        <ul className="nav navbar-nav ml-auto">
                          <li className="nav-item">
                            <span>{user.firstName + " " + user.lastName}</span>
                            &nbsp;
                        </li>
                          {
                            user.role.name != 'Reporte'
                              ?
                              <li className="nav-item">
                                <UploadImage />
                              </li>
                              :
                              null
                          }
                          &nbsp;&nbsp;
                          <li className="nav-item">
                            <Link data-tip="Cerrar sesión" to="/">
                              <i
                                className="fas fa-sign-out-alt"
                                onClick={this.logOut}
                              ></i>
                            </Link>
                            <ReactTooltip />
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <br />
                    <h1>Reporteador</h1>
                    <hr></hr>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {this.state.page === "results" ? <Results /> : ""}
                    {this.state.page === "baremo" ? <Baremo user={user} /> : ""}
                    {this.state.page === "dataExport" ? <DataExport user={user} /> : ""}
                    {this.state.page === "report" ? <Report user={user} /> : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}