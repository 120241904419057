import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import $ from 'jquery';
import axios from 'axios';
import TimeLoader from '../TimeLoader/TimeLoader.jsx';
import ReactFileReader from 'react-file-reader';
import logoSMART from "../../SMART.png";
import Navbar from '../Navbar/Navbar.jsx';
import Sidebar from '../Sidebar/Sidebar.jsx';

import './Home.css';

export default class Home extends Component {
    constructor() {
        super();
        this.state = {
            image: '',
            organizationsLogo: [],
            file: null
        }
    }

    componentDidMount() {
        $("#menu-toggle").click(function (e) {
            e.preventDefault();
            $("#wrapper").toggleClass("toggled");
        });
        this.reloadWindow();
        this.getLogo();
    }

    reloadWindow = () => {
        if (localStorage.getItem("reload")) {
            localStorage.removeItem("reload");
            window.location.reload();
        }
    }

    getLogo = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        const token = localStorage.getItem("token");
        axios
            .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/organizations`, { headers: { 'x-auth-token': token } })
            .then(res => {
                this.setState({
                    organizationsLogo: res.data.organizations
                });
                this.state.organizationsLogo.map(organization => {
                    if (organization._id == user.company.organization._id) {
                        this.setState({
                            image: organization.logo.imagecode
                        });
                    }
                });
            });
    };

    handleFiles = files => {
        console.log(files.fileList[0]);


    }

    render() {
        const user = JSON.parse(localStorage.getItem('user'));
        const token = localStorage.getItem('token');
        if (!token) {
            return (
                <Redirect to='/'>

                </Redirect>
            )
        } else {
            return (
                <div className="Home">
                    <TimeLoader redirect={this.props.history} />
                    <div className="wrapper" id="wrapper">
                        <Sidebar
                            role={user.role} />
                        <div id="page-content-wrapper">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Navbar
                                            page='home'
                                            image={this.state.image}
                                            role={user.role.name} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <br />
                                        <hr className="bg-info"></hr>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1">

                                    </div>
                                    <div className="col-md-10">
                                        <p className="h1Hi">Hola {user.firstName},</p>
                                        <p className="h1Msg text-justify">Bienvenid@ a <text className="pSmart font-weight-bold">S</text>
                                            <text className="pSmart2 font-weight-bold">M</text><text className="pSmart font-weight-bold">ART</text>,
                                            aplicación diseñada para medir algunos aspectos de los colaboradores, tales
                                            como percepciones, preferencias, opiniones, conductas, entre otros, que le
                                            permitirá a la empresa tomar decisiones en materia de gestión del personal.</p>
                                        <p className="h1Msg text-justify">A la izquierda de esta pantalla, encontrarás el menú principal que contiene
                                            los módulos necesarios para cumplir con los objetivos de la medición.
                                            Cualquier dificultad, duda o comentario que tengas respecto a la aplicación,
                                            por favor escribir a alguno de los siguientes correos: </p>
                                        <p className="h1Msg2 text-center"> fpineda@beconsult.com o fmunoz@beconsult.com</p>
                                    </div>
                                    <div className="col-md-1">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            )
        }

    }
}