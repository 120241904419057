import React, { Component } from 'react';
import axios from 'axios';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import ReactTooltip from 'react-tooltip';

import ModalDelete from '../../ModalDelete/ModalDelete.jsx'
import msgObj from '../../../const.js';
import token from '../../../token.js'
import Table from '../../Table/Table.jsx';

export default class Brands extends Component {

    constructor() {
        super();

        this.state = {
            _id: '',
            _idDelete: '',
            adminGroup: '',
            organization: 'Organización',
            name: '',
            description: '',
            brands: [],
            data: [],
            organizations: [],
            adminGroups: [],
            validate: true,
            show: false,
            status: false
        }
    }

    componentDidMount() {
        this.getBrands();
        this.getOrganization();
        this.getAdminGroups();
    }

    isChecked = () => {
        this.setState({
            status: !this.state.status
        })
    }


    getBrands = () => {
        this.setState({
            data: []
        })
        const data = Object.assign([], this.state.data)
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/brands`, { headers: { 'x-auth-token': token } })
            .then(res => {
                this.setState({
                    brands: res.data.brands
                })
            })
            .then(res => {
                this.state.brands.map(brand => {
                    data.push({
                        "_id": brand._id,
                        "name": brand.name,
                        "organization": brand.organization.name,
                        "status": brand.status
                    })
                })
            })
            .then(res => {
                this.setState({
                    data
                })
            })
    }

    getOrganization = () => {
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/organizations`, { headers: { 'x-auth-token': token } })
            .then(res => {
                this.setState({
                    organizations: res.data.organizations
                })
            })
    }


    getAdminGroups = () => {
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/admingroups`, { headers: { 'x-auth-token': token } })
            .then(res => this.setState({
                adminGroups: res.data.adminGroups
            }))
    }

    handleChange = (event) => {
        const rules = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ_@./#&+-,\s]*$/g
        const brandName = this.refs.name.value;
        const validate = rules.test(brandName);
        const { name, value } = event.target;
        this.setState({
            [name]: value,
            validate
        })
        console.log(this.state.validate);
    }

    clearForm = () => {
        this.setState({
            _id: '',
            organization: 'Organización',
            name: '',
            description: '',
            adminGroup: '',
            status: false
        })
    }

    addBrand = (event) => {
        event.preventDefault();
        if (this.state._id) {
            axios.put(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/brands/${this.state._id}`, {
                organization: {
                    "_id": this.state.organization,
                },
                name: this.state.name,
                description: this.state.description,
                status: this.state.status
            }, { headers: { 'x-auth-token': token } })
                .then(this.setState({
                    _id: '',
                    organization: 'Organización',
                    name: '',
                    description: '',
                    adminGroup: '',
                    status: false
                }))
                .then(res => {
                    this.getBrands();
                    ToastsStore.success(res.data.msg);
                })
        } else {
            axios.post(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/brands`, {
                organization: {
                    "_id": this.state.organization,
                },
                name: this.state.name,
                description: this.state.description,
                status: this.state.status
            }, { headers: { 'x-auth-token': token } })
                .then(res => {
                    this.setState({
                        organization: 'Organización',
                        name: '',
                        description: '',
                        adminGroup: '',
                        status: false,
                    })
                    this.getBrands();
                    ToastsStore.success(res.data.msg);
                }

                )
                .catch(err => {
                    ToastsStore.error(err.response.data.msg);
                })
        }
    }

    showModal = (id) => {
        this.setState({
            _idDelete: id,
            show: !this.state.show,
        })
    }

    deleteBrand = (id) => {

        axios.delete(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/brands/${this.state._idDelete}`, { headers: { 'x-auth-token': token } })
            .then(res => {
                this.getBrands();
                ToastsStore.success(res.data.msg);
                this.setState({
                    _idDelete: '',
                    show: !this.state.show
                })
            })
            .catch(err => {
                ToastsStore.error(err.response.data.msg)
            });
    }

    editBrand = (id) => {
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/brands/${id}`, { headers: { 'x-auth-token': token } })
            .then(res => this.setState({
                _id: res.data.brand._id,
                organization: res.data.brand.organization._id,
                name: res.data.brand.name,
                description: res.data.brand.description,
                adminGroup: res.data.brand.organization.admingroup._id,
                status: res.data.brand.status
            }))
    }

    render() {
        const formatWithIcon = (cell, row) => {
            return (
                <div>
                    <i
                        data-tip={msgObj.update}
                        data-for="update"
                        className="fas fa-edit"
                        aria-hidden="true"
                        onClick={() => this.editBrand(row._id)}></i>
                    <ReactTooltip
                        place="top"
                        id="update" />
                    &nbsp;&nbsp;
                    <i
                        className="fa fa-trash"
                        data-tip={msgObj.delete}
                        data-for="delete"
                        aria-hidden="true"
                        onClick={() => this.showModal(row._id)}></i>
                    <ReactTooltip
                        place="top"
                        id="delete" />
                </div>
            )
        }

        const formatWithStatus = (cell, row) => {
            return (
                <div>
                    {row.status
                        ? <i
                            className="fas fa-circle status-active"
                            aria-hidden="true"
                            data-tip={msgObj.enable}
                            data-for="enable"
                        >
                        </i>
                        : <i
                            className="fas fa-circle status-inactive"
                            aria-hidden="true"
                            data-tip={msgObj.disable}
                            data-for="disable"></i>
                    }
                    <ReactTooltip
                        place="top"
                        id={row.status ? "enable" : "disable"} />
                </div>
            )
        }

        const columns = [
            {
                dataField: 'status',
                text: '',
                formatter: formatWithStatus
            },
            {
                dataField: 'name',
                text: 'Marca',
                sort: true
            },
            {
                dataField: 'organization',
                text: 'Organización',
                sort: true
            },
            {
                dataField: 'deleteAction',
                text: '',
                formatter: formatWithIcon
            }]
        console.log(process.env.REACT_APP_BASEPATH);
        return (
            <div className="row">
                <div className="col-md-4">
                    <form onSubmit={this.addBrand} autocomplete="off">
                        <div className="row form-group">
                            <div className="col-md-12">
                                <select
                                    name="adminGroup"
                                    ref="adminGroup"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.adminGroup}
                                    required>
                                    <option value="" selected disabled>Grupo Administrador</option>
                                    {this.state.adminGroups.map(group => {
                                        return (
                                            <option key={group._id}
                                                value={group._id}
                                            >{group.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-12">
                                <select
                                    name="organization"
                                    ref="organization"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.organization}
                                    required>
                                    <option value="Organización" disabled>Organización</option>
                                    {this.state.organizations.map(organization => {
                                        if (this.state.adminGroup == organization.admingroup._id) {
                                            return (
                                                <option key={organization._id}
                                                    value={organization._id}
                                                >{organization.name}</option>
                                            )
                                        }
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="row form-group">
                            {this.state.validate ? '' : <label>{msgObj.letterField}</label>}
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Marca"
                                    ref="name"
                                    className={this.state.validate ? "form-control" : " form-control invalid"}
                                    onChange={this.handleChange}
                                    value={this.state.name}
                                    maxLength="20"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-12">
                                <textarea
                                    name="description"
                                    placeholder="Descripción"
                                    ref="description"
                                    className="form-control"
                                    maxLength="120"
                                    onChange={this.handleChange}
                                    value={this.state.description}
                                />
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-12">
                                <div className="checkbox">
                                    <label>
                                        Activar
                                        <input
                                            type="checkbox"
                                            name="switch"
                                            onClick={this.isChecked}
                                            checked={this.state.status}
                                            ref="switch"
                                            className="switch"
                                        />
                                        <div>
                                            <div></div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="col-md-12">
                            <div className="text-center">
                                {this.state._id != '' ?
                                    <button
                                        type="button"
                                        className="btn btn-form btn-light"
                                        onClick={this.clearForm}
                                    >Limpiar</button> : null}
                                &nbsp;
                                {this.state.validate ?
                                    <button
                                        type="submit"
                                        className="btn btn-form btn-light"
                                    >Guardar</button> :
                                    <button
                                        type="button"
                                        className="btn btn-form btn-light"
                                        disabled
                                    >Guardar</button>}

                            </div>
                        </div>
                        <ToastsContainer store={ToastsStore} />
                    </form>
                    <ModalDelete
                        show={this.state.show}
                        onCancel={this.showModal}
                        delete={this.deleteBrand}
                        msgDeleteAll={<p>De continuar, se eliminarán todos los registros asociados.</p>} />
                </div>
                <div className="col-md-8">
                    <Table
                        data={this.state.data}
                        columns={columns} />
                </div>

            </div>
        )
    }
}