import React, { Component } from "react";
import axios from "axios";
import BlockUi from 'react-block-ui';
import MyDocument from '../Document/Document';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ToastsContainer, ToastsStore } from "react-toasts";

import token from "../../../token.js";
import Instrument from "../../Instrument/Instrument";


export default class Report extends Component {
    constructor() {
        super();
        this.state = {
            organizations: [],
            results: [],
            companies: [],
            organization: '',
            company: '',
            instrument: 'instrument',
            demographic: '',
            demographics: [],
            year: '',
            type: '',
            data: [],
            notCompany: 'not',
            loading: false,
            event: false,
            instruments: []
        }
    }

    handleChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        console.log(this.state.type)
    };

    handleChangeOnInstrument = (event) => {
        const { name, value } = event.target;

        let instruments = null;

        /*console.log("array instruments: ", this.state.instruments);
        instruments = this.state.instruments.find(i => i._id == value );

        console.log("Instrumento: ", instruments);
        console.log("handleChangeOnInstrument: ", name, value);*/

        /*this.setState({
            demographics: instruments.demographicData,
        });*/

        this.setState({
            [name]: value
        }, () => {
            this.setState({
                loading: true
            })
            if (this.state.type == 'demographicsForQuestion') {
                axios.get(`
                ${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/reports/demographics/${this.state.instrument}/${this.state.organization}/${this.state.company != '' ? this.state.company : this.state.notCompany}/${this.state.demographic}`, {
                    headers: { "x-auth-token": token }
                })
                    .then(res => {
                        this.setState({
                            data: res.data
                        })
                        console.log(this.state.data);
                        localStorage.setItem('data', JSON.stringify(this.state.data));
                        this.setState({
                            event: !this.state.event,
                            loading: false
                        })
                    })
                    .catch(err => {
                        this.setState({
                            loading: false
                        })
                        ToastsStore.error("El Dato Demográfico no está asociado a este instrumento");
                        console.log(err);
                    })
            } else {
                axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/reports/${this.state.instrument}/${this.state.organization}/${this.state.company != '' ? this.state.company : this.state.notCompany}`, {
                    headers: { "x-auth-token": token }
                })
                    .then(res => {
                        this.setState({
                            data: res.data
                        })
                        localStorage.setItem('data', JSON.stringify(this.state.data));
                        this.setState({
                            event: !this.state.event,
                            loading: false
                        })
                    })
                    .catch(err => {
                        this.setState({
                            loading: false
                        })
                        console.log(err);
                    })
            }
        });

    }

    handleOrganizarion = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.setState({
                company: '',
                instrument: 'instrument'
            })
        })

    }

    componentDidMount() {
        this.getOrganizations();
        this.getCompanies();
        this.getDemographicData();
        this.getInstruments();
        localStorage.removeItem('data');
        console.log(this.state.instruments);
    }

    getInstruments = () => {
        axios
            .get(
                `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/instruments`,
                { headers: { "x-auth-token": token } }
            )
            .then(res => {
                //console.log(" ", res);
                this.setState({
                    instruments: res.data.instruments
                });
            })
            .catch(err => console.log(err))
    };

    getOrganizations = () => {
        axios
            .get(
                `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/organizations`,
                { headers: { "x-auth-token": token } }
            )
            .then(res => {
                this.setState({
                    organizations: res.data.organizations
                });
            });
    };

    getDemographicData = () => {
        axios.get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/demographicData`, { headers: { 'x-auth-token': token } })
            .then(res => {
                this.setState({
                    demographics: res.data.demographicData
                });

                console.log("demographics: ", res.data.demographicData);
            });
    };

    getCompanies = () => {
        axios
            .get(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASEPATH}/api/v1/companies`, {
                headers: { "x-auth-token": token }
            })
            .then(res => {
                this.setState({
                    companies: res.data.companies
                });
            });
    };



    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading}>
                <div className="row">
                    <div className="col-md-5">
                        <form>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <select
                                        name="type"
                                        ref="type"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        required>
                                        <option value="" selected disabled>Tipo de reporte</option>
                                        <option value="dimensions">Por dimensión y subdimensión</option>
                                        <option value="demographics">Por datos demograficos</option>
                                        <option value="demographicsForQuestion">Por datos demograficos y preguntas</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <select
                                        name="organization"
                                        ref="organization"
                                        className="form-control"
                                        onChange={this.handleOrganizarion}
                                        required>
                                        <option value="" selected disabled>Organización</option>
                                        {this.state.organizations.map(organization => {
                                            return (
                                                <option key={organization._id} value={organization._id}>
                                                    {organization.name}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <select
                                        name="company"
                                        ref="company"
                                        className="form-control"
                                        onChange={this.handleChange}
                                    >
                                        <option value="" selected disabled>Compañía</option>
					{this.state.companies.map(company => {
                                            if (company.organization._id == this.state.organization) {
                                                return (
                                                    <option key={company._id} value={company._id}>
                                                        {company.name}
                                                    </option>
                                                )
                                            }
                                        })}
                                    </select>
                                </div>
                            </div>
                            {this.state.type == 'demographicsForQuestion' ?
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <select
                                            name="demographic"
                                            ref="demographic"
                                            className="form-control"
                                            onChange={this.handleChange}
                                            required>
                                            <option value="" selected disabled>Dato demografico</option>
                                            {this.state.demographics.map(demographic => {
                                                return (
                                                    <option key={demographic._id} value={demographic._id}>
                                                        {demographic.name}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div> : null}
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <select
                                        name="instrument"
                                        ref="instrument"
                                        className="form-control"
                                        onChange={this.handleChangeOnInstrument}
                                        value={this.state.instrument}
                                        required>
                                        <option value="instrument" defaultValue disabled>Instrumento</option>
                                        {
                                            this.state.instruments.map(instrument => {
                                                //console.log(instrument);
                                                if (instrument.result && instrument.organization._id == this.state.organization) {

                                                    return (
                                                        <option key={instrument._id} value={instrument._id}>
                                                            {instrument.name}
                                                        </option>
                                                    )
                                                    // localStorage.setItem('id', result._id)
                                                    // localStorage.setItem('instrumentId', instrument._id)
                                                    // localStorage.setItem('instrumentName', instrument.name)
                                                }
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            {/* <div className="row form-group">
                            <div className="col-md-12">
                                <select
                                    name="adminGroup"
                                    ref="adminGroup"
                                    className="form-control"
                                    required>
                                    <option value="" selected disabled>Dato demografico</option>
                                </select>
                            </div>
                        </div> */}
                            <br />
                            <div className="col-md-12">
                                <div className="text-center">
                                    <button type="btn" className="btn btn-form btn-light" onClick={() => {
                                        this.setState({
                                            organization: '',
                                            company: '',
                                            instrument: 'instrument',
                                            type: ''
                                        })
                                        window.location.reload();

                                    }}>
                                        <PDFDownloadLink document={<MyDocument event={this.state.event} type={this.state.type} />} fileName="reporte.pdf">
                                            {({ blob, url, loading, error }) => (loading ? 'Cargando...' : 'Generar')}
                                        </PDFDownloadLink>
                                    </button>

                                </div>
                            </div>
                            <ToastsContainer store={ToastsStore} />
                        </form>
                    </div>

                </div>
            </BlockUi>

        )
    }
}
